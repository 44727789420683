<template>
  <div id="cve-secondary-page-main-container">
    <div class="columns is-centered">
      <div class="column is-8-desktop cve-main-column-content-width is-12-tablet">
        <main id="cve-main-page-content" role="main">
          <div class="content">
            <h1 :id="cvenavs['Program Organization']['submenu']['Board']['id']" class="title">
              {{cvenavs['Program Organization']['submenu']['Board']['label']}}</h1>
            <ExternalLinkMessage/>
            <p>
              Through open and collaborative discussions, the CVE Board ensures that the CVE Program meets the vulnerability identification needs of
              the global cybersecurity and technology communities.
            </p>
            <p>
              Board members represent numerous cybersecurity-related organizations, including commercial security tool vendors, academia, research
              institutions, government departments and agencies, and other prominent security experts, as well as end-users of vulnerability
              information.
            </p>
            <p>
              All Board Meetings and Board Email List Discussions are archived for the community.
            </p>
            <h2 :id="cvenavs['Program Organization']['submenu']['Board']['items']['Resources']['anchorId']" class="title">
              Resources
            </h2>
            <div class="tile is-ancestor cve-task-tiles-container">
              <div class="tile is-parent cve-task-left-tile">
                <article class="tile is-child cve-border-dark-blue">
                  <h3 class="title cve-task-tile-header">
                    Archives
                  </h3>
                  <ul class="tile-body cve-task-tile-list">
                    <li class="cve-task-tile-list-item">
                      <a href="https://cve.mitre.org/community/board/archive.html#meeting_summaries" target="_blank">
                        Board Meeting Summaries
                      </a>
                    </li>
                    <li class="cve-task-tile-list-item">
                      <a href="https://www.mail-archive.com/cve-editorial-board-list@mitre.org/" target="_blank">
                        Board Discussion List &ndash; Mail-Archive.com
                      </a>
                    </li>
                    <li class="cve-task-tile-list-item">
                      <a href="https://marc.info/?l=cve-editorial-board" target="_blank">
                        Board Discussion List &ndash; Mailing list ARChives (MARC)
                      </a>
                    </li>
                  </ul>
                </article>
              </div>
              <div class="tile is-parent cve-task-learn-tile">
                <article class="tile is-child cve-border-dark-blue">
                  <h3 class="title cve-task-tile-header">
                    Documents
                  </h3>
                  <ul class="tile-body cve-task-tile-list">
                    <li class="cve-task-tile-list-item">
                      <a target='_blank' href="/Resources/Roles/Board/General/Board-Charter.pdf">Board Charter</a>
                    </li>
                    <li>
                      <a target='_blank' href="/Resources/Roles/Board/General/Board-Nomination-Form.docx">
                        CVE Board Nomination Form
                      </a>
                    </li>
                  </ul>
                </article>
              </div>
            </div>
            <h2 :id="cvenavs['Program Organization']['submenu']['Board']['items']['Members']['anchorId']" class="title">
              Members
            </h2>

            <section class="cve-accordian mb-6">
              <div class="message has-text-centered">
                <div class="message-header cve-base-light-gray-borders-bg">
                  <h3 class="title mb-0">Current Members</h3>
                  <button class="button" @click="toggleAccordianItem('current-members')" aria-label="expandCollapseCurrentMembers">
                    <span class="icon is-small">
                      <font-awesome-icon :icon="accordian['current-members'] ? 'plus' : 'minus'"/>
                    </span>
                  </button>
                </div>
                <div class="message-body" :class="{'is-hidden': accordian['current-members']}">
                  <div class="block">
                    <div class="hero-body" v-for="(chuckedBoardMembersList, index) in
                      preprocessedBoardMembersList"
                      :key="index">
                      <div class="container">
                        <div class="tile is-ancestor">
                          <div class="tile is-parent cve-board-tile-container" v-for="(member, index) in chuckedBoardMembersList" :key="index">
                            <div class="tile is-child box cve-board-tile">
                              <div class="cve-board-tile-content has-text-centered">
                                <figure v-if="member.imageURL.length > 0" class="image is-128x128 cve-board-photo mb-0">
                                  <img :src="`/images/cveboard/${member.imageURL}`" alt="">
                                </figure>
                                <span v-else class="" style="width: 128px; height: 128px;">
                                  <font-awesome-icon size="7x" icon="user" />
                                </span>
                                <p class="title is-5 mt-4">{{member.firstName}} {{member.familyName}}</p>
                                <p class="subtitle is-6"><a :href="$sanitize(member.organizationURL)" target="_blank">{{member.organization}}</a></p>
                                <p class="subtitle is-6" v-if="member.role != 'Board'">{{member.role}}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div class="block">
              <h3 class="title">Emeritus</h3>
              <p>
                The individuals listed below are former members of the CVE Board. We thank them for contributions, especially those with
                “Emeritus” status who have made particularly significant contributions to the CVE effort.
              </p>
              <div class="columns">
                <div class="column">
                  <ul>
                    <li>Andy Balinsky</li>
                    <li>Scott Blake</li>
                    <li>Harold Booth</li>
                    <li>Steve Christey Coley</li>
                  </ul>
                </div>
                <div class="column">
                  <ul>
                    <li>Andre Frech</li>
                    <li>Elias Levy</li>
                    <li>Peter Mell</li>
                    <li>Pascal Meunier</li>
                  </ul>
                </div>
                <div class="column">
                  <ul>
                    <li>Steve Northcutt</li>
                    <li>Kurt Seifried</li>
                    <li>Adam Shostack</li>
                    <li>Gene Spafford</li>
                  </ul>
                </div>
              </div>
              <h3 class="title">Other Past Contributors</h3>
              <div class="columns">
                <div class="column">
                  <ul>
                    <li>Jimmy Alderson</li>
                    <li>Beverly Alvarez</li>
                    <li>David Baker</li>
                    <li>David Balenson</li>
                    <li>Matt Bishop</li>
                    <li>Troy Bollinger</li>
                    <li>Natalie Brader</li>
                    <li>Rob Clyde</li>
                    <li>Tim Collins</li>
                    <li>Eric Cole</li>
                    <li>Jessica Colvin</li>
                    <li>Kelly Cooper</li>
                    <li>Russ Cooper</li>
                    <li>Marc Dacier</li>
                    <li>Renaud Deraison</li>
                    <li>Casper Dik</li>
                  </ul>
                </div>
                <div class="column">
                  <ul>
                    <li>Carsten Eiram</li>
                    <li>Bill Fithen</li>
                    <li>John Flowers</li>
                    <li>Dana Foat</li>
                    <li>Stu Green</li>
                    <li>Patrick Heim</li>
                    <li>Shawn Hernan</li>
                    <li>Bill Hill</li>
                    <li>Al Huger</li>
                    <li>Jim Jones</li>
                    <li>Panos Kampanakis</li>
                    <li>David LeBlanc</li>
                    <li>Jim Magdych</li>
                    <li>David Mann</li>
                    <li>Brian Martin</li>
                    <li>Scott Moore</li>
                  </ul>
                </div>
                <div class="column">
                  <ul>
                    <li>Ken Munro</li>
                    <li>Ron Nguyen</li>
                    <li>Larry Oliver</li>
                    <li>Craig Ozancin</li>
                    <li>Alan Paller</li>
                    <li>Paul Proctor</li>
                    <li>Mike Prosser</li>
                    <li>Damir Rajnovic</li>
                    <li>Marcus Ranum</li>
                    <li>Elizabeth Scott</li>
                    <li>Steve Snapp</li>
                    <li>Stuart Staniford</li>
                    <li>Tom Stracener</li>
                    <li>Bill Wall</li>
                    <li>Kevin Ziese</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </main>
          <SurveyLinkComponent :surveyLink="cvenavs['Program Organization']['submenu']['Board']['surveyLink']" />
      </div>
      <div class="column is-3 is-hidden-touch">
        <NavigationSidebar :nav="cvenavs['Program Organization']" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import NavigationSidebar from '@/components/NavigationSidebar.vue';
import SurveyLinkComponent from '@/components/SurveyLinkComponent.vue';
import CurrentBoardMembersData from '../../assets/data/currentBoardMembersList.json';

export default {
  name: 'Board',
  components: {
    NavigationSidebar,
    SurveyLinkComponent,
  },
  props: {
    cvenavs: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pastMembersTab: 0,
      accordian: { 'current-members': false },
    };
  },
  computed: {
    preprocessedBoardMembersList() {
      function compareFamilyName(member1, member2) {
        const fName1 = member1.familyName.toLowerCase();
        const fName2 = member2.familyName.toLowerCase();
        if (fName1 < fName2) {
          return -1;
        } if (fName1 > fName2) {
          return 1;
        }
        return 0;
      }

      const boardMembersByAlphabeticalOrder = CurrentBoardMembersData.sort((member1, member2) => compareFamilyName(member1, member2));
      let i; let j; let k; const preprocessedBoardMembersList = []; const chunk = 3;
      for (i = 0, k = 0, j = boardMembersByAlphabeticalOrder.length; i < j; i += chunk, k += 1) {
        preprocessedBoardMembersList[k] = boardMembersByAlphabeticalOrder.slice(i, i + chunk);
      }

      return preprocessedBoardMembersList;
    },
  },
  methods: {
    toggleAccordianItem(id) {
      Vue.set(this.accordian, id, !this.accordian[id]);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../assets/style/globals.scss';

/* Board page specific */
.card {
  max-width: 200px;
}

.card .media {
  margin-bottom: unset;
}

.card-content {
    padding: 1rem;
}

.help-text-icon {
  display: inline-flex !important;
}

/* Tile */

.icon, .persona-icon {
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 15px;
  color: $theme-color-primary-darker;
}

.tile.is-child.cve-board-tile {
  border-style: solid;
  border-width: 1px;
  border-color: $theme-color-base;
  border-radius: unset;
  font-weight: bold;
  padding: 1rem;
}

.cve-task-tile-title {
  margin-left:15px;
}

.cve-task-tile-icon {
  color: white;
  margin-left: unset;
  margin-right: unset;
}

.cve-task-report-tile {
  padding-right: 0px !important;
}

.tile.is-child.task-tile {
    padding: unset;
}

.icon {
  margin-bottom: unset;
}

.cve-b-tab-tem-general {
  text-align: left;
}
.cve-board-photo {
  margin: 0 auto 8px auto;
}

.hero-body {
    padding-top: unset;
    padding-bottom: unset;
}
</style>

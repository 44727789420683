<template>
  <div id="cve-secondary-page-main-container" class="container">
    <div class="columns is-centered">
      <div class="column is-8-desktop cve-main-column-content-width is-12-tablet">
        <main id="cve-main-page-content" role="main">
          <div class="content">
            <h1 :id="cvenavs['About']['submenu']['Overview']['id']" class="title">
              {{cvenavs['About']['submenu']['Overview']['label']}}</h1>
            <h2 :id="cvenavs['About']['submenu']['Overview']['items']['About the CVE Program']['anchorId']"
              class="title">
              {{cvenavs['About']['submenu']['Overview']['items']['About the CVE Program']['label']}}
            </h2>
            <p>
              The mission of the CVE<sup>®</sup> Program is to identify,
              define, and catalog publicly disclosed cybersecurity
              <router-link to="/ResourcesSupport/Glossary?activeTerm=glossaryVulnerability">vulnerabilities</router-link>. There is one
              <router-link to="/ResourcesSupport/Glossary?activeTerm=glossaryRecord">CVE Record</router-link> for each
              vulnerability in the catalog. The vulnerabilities are discovered then assigned and published
              by organizations from around the world that have partnered with the CVE Program.
              <router-link to="/PartnerInformation/ListofPartners">Partners</router-link> publish CVE Records to communicate
              consistent descriptions of vulnerabilities. Information technology and cybersecurity professionals use
              CVE Records to ensure they are discussing the same issue, and to coordinate their efforts to prioritize and
              address the vulnerabilities.
            </p>
            <div class="cve-white-bg-gray-border-container">
              <h3 class="title mt-4">CVE Program Overview</h3>
              <figure class ="image is-16by9">  <!-- youtube video -->
                <iframe class="has-ratio" width="560" height="315" src="https://www.youtube.com/embed/rrNYEUNsXOY" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                </iframe>
              </figure>
              <p>
                Introduction to the CVE<sup>®</sup> Program, including what is CVE, goals of the program, who operates the
                program, and program organization.
              </p>
            </div>
            <div class="cve-white-bg-gray-border-container">
              <h3 class="title mt-4">Podcast - How CVE, CISA, and NIST work together to manage vulnerabilities</h3>
              <figure class ="image is-16by9">  <!-- podcast -->
                <iframe class="has-ratio" width="560" height="315" src="https://www.youtube.com/embed/MIoV_X18DvE" frameborder="0" allowfullscreen>
                </iframe>
              </figure>
              <p>
                Tod Beardsley of <a href='https://www.rapid7.com/' target='_blank'>Rapid7</a>, Tom Millar of
                <a href='https://www.cisa.gov/' target='_blank'>CISA</a>, Chris Levendis of the <a href='/'>CVE Program</a>, and
                Dave Waltermire of <a href='https://www.nist.gov/' target='_blank'>NIST</a>’s
                <a href='https://nvd.nist.gov/' target='_blank'>NVD</a> discuss how their organizations and the community all
                work together to advance the CVE Program’s mission to identify, define, and catalog publicly disclosed cybersecurity
                vulnerabilities.
              </p>
            </div>
            <div class="cve-white-bg-gray-border-container">
              <h3 class="title mt-4">Podcast - The Value of Assigning CVEs</h3>
              <figure class ="image is-16by9">  <!-- podcast -->
                <iframe class="has-ratio" width="560" height="315" src="https://www.youtube.com/embed/aT6BjbZS22w" frameborder="0" allowfullscreen>
                </iframe>
              </figure>
              <p>
                Shannon Sabens of <a href='https://www.crowdstrike.com/' target='_blank'>CrowdStrike</a> chats with Madison Oliver of
                <a href='https://securitylab.github.com/' target='_blank'>GitHub Security Lab</a> about how and why CVEs are assigned, the value
                 of CVEs in vulnerability management, responsible coordination of vulnerability disclosures, the importance of comprehensiveness
                 in security advisories, and why there is no stigma in a CVE. In addition, <a href='/ProgramOrganization/CNAs'>CVE Numbering
                 Authority (CNA)</a> scopes, disclosure policies, turnaround times, and more are discussed in general, as are GitHub’s specific
                 CNA processes and how it helps open-source projects hosted on GitHub with their CVEs and advisories.
              </p>
            </div>
            <div class="cve-white-bg-gray-border-container">
              <h3 class="title mt-4">Podcast - CVE Myths versus Facts</h3>
              <figure class ="image is-16by9">  <!-- podcast -->
                <iframe class="has-ratio" width="560" height="315" src="https://www.youtube.com/embed/OQB2w71JmLE" frameborder="0" allowfullscreen>
                </iframe>
              </figure>
              <p>
                Tod Beardsley of <a href='https://www.rapid7.com/' target='_blank'>Rapid7</a>, Shannon Sabens of
                <a href='https://www.crowdstrike.com/' target='_blank'>CrowdStrike</a>, and Kent Landfield of
                <a href='https://www.trellix.com/' target='_blank'>Trellix</a> (formerly McAfee) provide the truth and facts about the following
                myths about the CVE Program:
                <ul>
                  <li class="cve-list-no-bullet">Myth #1: The CVE Program is run entirely by the MITRE Corporation.</li>
                  <li class="cve-list-no-bullet"> Myth #2: The CVE Program is controlled by software vendors.</li>
                  <li class="cve-list-no-bullet">Myth #3: The CVE Program doesn’t cover enough types of vulnerabilities.</li>
                  <li class="cve-list-no-bullet">Myth #4: The CVE Program is responsible for assigning vulnerability severity scores.</li>
                </ul>
              </p>
            </div>
            <div class="cve-white-bg-gray-border-container">
              <h3 class="title mt-4">Podcast - Becoming A CNA Myths versus Facts</h3>
              <figure class ="image is-16by9">  <!-- podcast -->
                <iframe class="has-ratio" width="560" height="315" src="https://www.youtube.com/embed/0FENpUbBJcI" frameborder="0" allowfullscreen>
                </iframe>
              </figure>
              <p>
                Shannon Sabens of
                <a href='https://www.crowdstrike.com/' target='_blank'>CrowdStrike</a>
                chats with Julia Turkevich of the
                <a href='https://www.cisa.gov/about/divisions-offices/cybersecurity-division' target='_blank'>
                U.S. Cybersecurity and Infrastructure Security Agency (CISA)</a>
                about the myths and facts of partnering with the CVE Program as a
                <router-link to="/ProgramOrganization/CNAs">CVE Numbering Authority</router-link> (CNA):
                <ul>
                  <li class="cve-list-no-bullet">Myth #1: Only a specific category of software vendors can become CNAs.</li>
                  <li class="cve-list-no-bullet">
                    Myth #2: Organizations cannot leverage their existing vulnerability management and disclosure processes when they become a CNA.
                  </li>
                  <li class="cve-list-no-bullet">Myth #3: The requirements for becoming a CNA are overwhelming and extensive.</li>
                  <li class="cve-list-no-bullet">Myth #4: A fee is required to become a CNA.</li>
                  <li class="cve-list-no-bullet">Myth #5: The CNA onboarding process is too complicated and time-consuming.</li>
                  <li class="cve-list-no-bullet">Myth #6: Organizations cannot choose the Top-Level Root or Root they want to work with.</li>
                </ul>
              </p>
            </div>
            <div class="cve-white-bg-gray-border-container">
              <h3 class="title mt-4">Podcast - CVE Working Groups, What They Are and How They Improve CVE</h3>
              <figure class ="image is-16by9">  <!-- podcast -->
                <iframe class="has-ratio" width="560" height="315" src="https://www.youtube.com/embed/IT9HlBOeKKc" frameborder="0" allowfullscreen>
                </iframe>
              </figure>
              <p>
                The chairs and co-chairs of each of the six <a href='/ProgramOrganization/WorkingGroups'>CVE Working Groups (WGs)</a> &mdash; each
                of whom is an active member of the CVE community &mdash; chat about their WG’s overall mission, current work, and future plans. WGs
                help improve quality, automation, processes, and other aspects of the CVE Program as it continues to grow and expand. How and why to
                participate, and the impact individuals can make on the program, are also discussed.
              </p>
            </div>
            <div class="cve-white-bg-gray-border-container">
              <h3 class="title mt-4">Podcast - The Council of Roots</h3>
              <figure class ="image is-16by9">  <!-- podcast -->
                <iframe class="has-ratio" width="560" height="315" src="https://www.youtube.com/embed/QIfU4mlPx_A" frameborder="0" allowfullscreen>
                </iframe>
              </figure>
              <p>
                Learn how
                <a href='/ProgramOrganization/CNAs'>CVE Numbering Authority (CNA)</a>
                partners &mdash; ranging from large to small organizations, proprietary and open-source products or projects, disparate business
                sectors, and different geographic locations &mdash; are overseen and supported within the
                <a href='/'>CVE Program</a>
                by “<a href='/ResourcesSupport/Glossary?activeTerm=glossaryTLRoot'>Top-Level Roots</a>”
                and “<a href='/ResourcesSupport/Glossary?activeTerm=glossaryRoot'>Roots</a>.”
                Topics include the roles and responsibilities of the two different types of Roots; how their work benefits the CNAs
                under their care; how they recruit new CNA partners, including suggestions for addressing upper management concerns if a CNA
                prospect organization is hesitant to partner as a CNA; how they work with and support their CNAs over time; how the
                “Council of Roots” works together to enhance and help improve the program overall; and much more. All current CVE Program
                Top-Level Roots and Roots are represented in this podcast.
              </p>
            </div>
            <SurveyLinkComponent :surveyLink="cvenavs['About']['submenu']['Overview']['surveyLink']" />
          </div>
        </main>
      </div>
      <div class="column is-3 is-hidden-touch">
        <NavigationSidebar :nav="cvenavs['About']" />
      </div>
    </div>
  </div>
</template>

<script>
import NavigationSidebar from '@/components/NavigationSidebar.vue';
import SurveyLinkComponent from '@/components/SurveyLinkComponent.vue';

export default {
  name: 'Overview',
  components: {
    NavigationSidebar,
    SurveyLinkComponent,
  },
  props: {
    cvenavs: {
      type: Object,
      required: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../assets/style/globals.scss';

</style>

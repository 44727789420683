<template>
  <footer class="footer" id="footer">
    <div class="columns">
      <div class="column">
        <h4 class="title is-5">Policies & Cookies</h4>
        <ul>
          <li><router-link to="/Legal/TermsOfUse">Terms of Use</router-link></li>
          <li><a href="https://github.com/CVEProject/cve-website/security/policy" target="_blank">Website Security Policy</a></li>
          <li><a href="/Legal/PrivacyPolicy">Privacy Policy</a></li>
          <li><router-link to="/Legal/CookieNotice">Cookie Notice</router-link></li>
        </ul>
      </div>
      <div class="column">
        <h4 class="title is-5">Media</h4>
        <ul>
          <li><router-link to="/Media/News/AllNews">News</router-link></li>
          <li><router-link to="/Media/News/Blogs">Blogs</router-link></li>
          <li><router-link to="/Media/News/Podcasts">Podcasts</router-link></li>
          <li><router-link to="/Media/News/NewsletterSignup">Email newsletter sign up</router-link></li>
        </ul>
      </div>
      <div class="column">
        <h5 class="title is-5">Social Media</h5>
          <a class="cve-social-media-icon" href="https://github.com/CVEProject" target="_blank">
          <span class="icon">
            <p id="githubIcon" class="is-hidden">github</p>
            <font-awesome-icon :icon="['fab', 'github']" aria-labelledby="githubIcon" aria-hidden="false" focusable="false"/>
          </span></a>
          <a class="cve-social-media-icon" href="https://www.linkedin.com/company/cve-program" target="_blank">
          <span class="icon">
            <p id="linkedinIcon" class="is-hidden">linkedin</p>
            <font-awesome-icon :icon="['fab', 'linkedin']" aria-labelledby="linkedinIcon" aria-hidden="false" focusable="false"/>
          </span></a>
          <a class="cve-social-media-icon" href="https://mastodon.social/@CVE_Program" target="_blank">
          <span class="icon">
            <p id="mastodonIcon" class="is-hidden">mastodon</p>
            <font-awesome-icon :icon="['fab', 'mastodon']" aria-labelledby="mastodonIcon" aria-hidden="false" focusable="false"/>
          </span></a>
          <a class="cve-social-media-icon" href="https://www.youtube.com/channel/UCUHd2XFDsKH8kjMZQaSKpDQ/" target="_blank">
          <span class="icon">
            <p id="youtubeIcon" class="is-hidden">youtube</p>
            <font-awesome-icon :icon="['fab', 'youtube']" aria-labelledby="youtubeIcon" aria-hidden="false" focusable="false"/>
          </span></a>
          <a class="cve-social-media-icon" href="https://medium.com/@CVE_Program" target="_blank">
          <span class="icon">
            <p id="mediumIcon" class="is-hidden">medium</p>
            <font-awesome-icon :icon="['fab', 'medium']" aria-labelledby="mediumIcon" aria-hidden="false" focusable="false"/>
          </span></a>
          <div>
            <a class="cve-social-media-icon-with-text" href="https://twitter.com/CVEnew/" target="_blank">
            <span class="icon">
              <p id="twitterNewIcon" class="is-hidden">twitter for CVE New</p>
              <font-awesome-icon :icon="['fab', 'twitter']" aria-labelledby="twitterNewIcon" aria-hidden="false" focusable="false"/>
            </span>New CVE Records</a>
            <a class="cve-social-media-icon-with-text" href="https://twitter.com/CVEannounce/" target="_blank">
            <span class="icon">
              <p id="twitterAnnounceIcon" class="is-hidden">twitter for CVE announce</p>
              <font-awesome-icon :icon="['fab', 'twitter']" aria-labelledby="twitterAnnounceIcon" aria-hidden="false" focusable="false"/>
              </span> CVE Announce</a>
          </div>
      </div>
      <div class="column">
        <h4 class="title is-5">Contact</h4>
        <ul>
          <li>
            <span class="icon-text">
            <a href="https://cveform.mitre.org/" target="_blank">CVE Program Support
            <span class="icon cve-icon-xxs">
              <p id="programSupport" class="is-hidden">external site</p>
              <font-awesome-icon icon="external-link-alt" aria-labelledby="programSupport">
              </font-awesome-icon>
            </span>
            </a>
            </span>
          </li>
          <li><router-link to="/PartnerInformation/ListofPartners">CNA Partners</router-link></li>
          <li>
            <span class="icon-text">
            <a href="https://cveform.mitre.org/" target="_blank">CVE Website Support
            <span class="icon cve-icon-xxs">
              <p id="websiteSupport" class="is-hidden">external site</p>
              <font-awesome-icon icon="external-link-alt" aria-labelledby="websiteSupport">
              </font-awesome-icon>
            </span>
            </a>
            </span>
          </li>
          <li>
            <span class="icon-text">
            <a href="https://github.com/CVEProject/Ideas" target="_blank">CVE Program Idea Tracker
            <span class="icon cve-icon-xxs">
              <p id="programIdeaTracker" class="is-hidden">external site</p>
              <font-awesome-icon icon="external-link-alt" aria-labelledby="programIdeaTracker">
              </font-awesome-icon>
            </span>
            </a>
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div class="content">
      <p>
        Use of the CVE<sup>®</sup> List and the associated references from this website are subject to the
        <router-link to="/Legal/TermsOfUse">terms of use</router-link>. CVE is sponsored by the
        <a href="https://www.dhs.gov/" target="_blank">U.S. Department of Homeland Security (DHS)
          <span class="icon cve-icon-xxs">
            <p id="extenalLink5" class="is-hidden">external link</p>
            <font-awesome-icon icon="external-link-alt" aria-labelledby="extenalLink5"/>
          </span>
        </a>
        <a href="https://www.dhs.gov/cisa/cybersecurity-division/" target="_blank"> Cybersecurity and Infrastructure Security Agency (CISA)
          <span class="icon cve-icon-xxs">
            <p id="extenalLink3" class="is-hidden">external link</p>
            <font-awesome-icon icon="external-link-alt" aria-labelledby="extenalLink3"/>
          </span>
        </a>. Copyright © 1999-{{currentYear}},
        <a href="https://www.mitre.org/" target="_blank">The MITRE Corporation
          <span class="icon cve-icon-xxs">
            <p id="extenalLink4" class="is-hidden">external link</p>
            <font-awesome-icon icon="external-link-alt" aria-labelledby="extenalLink4"/>
          </span>
        </a>. CVE and the CVE logo are registered trademarks of The MITRE Corporation.
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../assets/style/globals.scss';

.cve-social-media-icon {
  margin: 0 10px 0 10px;
}

.cve-social-media-icon-with-text {
  display: block;
}

.footer {
  text-align: center;
}
</style>

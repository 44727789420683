<template>
  <div>
    <div v-if="['NotFound'].indexOf($route.name) > -1">
      <NotFound/>
    </div>
    <div v-else id="app">
      <a href="#cve-main-page-content" id="cve-skip-link">Skip to main content</a>
      <PrimaryNavigation :cvenavs="NavigationItems"/>
      <NotificationBannerModule/>
      <router-view :cvenavs="NavigationItems"/>
      <FooterModule/>
    </div>
  </div>
</template>

<script>
import NotificationBannerModule from '@/components/NotificationBannerModule.vue';
import FooterModule from '@/components/FooterModule.vue';
import PrimaryNavigation from '@/components/PrimaryNavigation.vue';
import NavigationItems from '@/assets/data/navigation.json';
import NotFound from '@/views/NotFound.vue';

export default {
  name: 'App',
  components: {
    NotificationBannerModule,
    PrimaryNavigation,
    FooterModule,
    NotFound,
  },
  data() {
    return {
      NavigationItems,
    };
  },
};
</script>

<style lang="scss">
@import './assets/style/globals.scss';
@import 'bulma';

#cve-skip-link {
  background-color: white;
  text-decoration: underline;
  left: 0;
  padding: .5rem 1rem;
  position: absolute;
  top: -3rem;
  z-index: 50;
  opacity: 0;
}

#cve-skip-link:focus {
  opacity: 1;
  top: 0;
  outline: none;
  transition: .2s ease-in-out;
  box-shadow: unset;
  border-bottom-width: 6px;
  border-bottom-style: solid;
  border-bottom-color: $theme-color-accent-warm;
  border-right-width: 6px;
  border-right-style: solid;
  border-right-color: $theme-color-accent-warm;
}
</style>

<template>
  <div id="cve-secondary-page-main-container">
    <div class="columns is-centered">
      <div class="column is-12-desktop is-11-tablet cve-main-column-content-width">
        <main id="cve-main-page-content" role="main">
          <h1 class="title">Events</h1>
          <div class="content" v-for="category in [{label: 'upcoming'},{label: 'past'}]" :key="category.label">
            <h2 class="title is-capitalized">{{category.label}}</h2>
            <div v-if="category.label == 'upcoming'">
              <div class="content"  v-for="(eventType, index) in sortEvents[category.label]"  :key="index">
                <h3 class="subtitle is-size-4">{{eventType.length > 0 && eventType[0].date.repeat ? 'Recurring' : ''}}</h3>
                <div class="cve-scrollx-table-container" v-if="eventType.length > 0">
                  <table class="table is-striped is-hoverable cve-border-dark-blue">
                    <thead>
                      <th style="width: 20%">Date</th>
                      <th style="width: 30%">Event</th>
                      <th style="width: 40%">Description</th>
                      <th>Access</th>
                    </thead>
                    <tbody>
                      <tr v-for="event in eventType"  :key="event.id" :id="event.title.replace(/[^a-z0-9]/gi, '')">
                        <td style="width: 20%" class="is-capitalized">
                          <span v-if="event.date.repeat">
                            <span v-if="event.date.repeat.recurrence == 'monthly'">Monthly on {{event.date.repeat.day}}</span>
                            <span v-else-if="event.date.repeat.recurrence == 'biweekly'">Every other {{event.date.repeat.day}}</span>
                            <span v-else>Every {{event.date.repeat.day}}</span>
                          </span>
                          <span v-else>
                            {{formatDateRange(event.date.start, event.date.end)}}
                          </span>
                        </td>
                        <td style="width: 30%">
                          <span v-if="event.url != '' ">
                            <a :href="event.url" target="_blank">{{event.title}}</a>
                          </span>
                          <span v-else>
                            {{event.title}}
                          </span>
                        </td>
                        <td style="width: 40%"><span v-html="event.description"></span></td>
                        <td class="is-capitalized">{{event.permission}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="content cve-scrollx-table-container">
                <table class="table is-striped is-hoverable cve-border-dark-blue">
                  <thead>
                    <th style="width: 20%">Date</th>
                    <th style="width: 30%">Event</th>
                    <th style="width: 40%">Description</th>
                    <th>Access</th>
                  </thead>
                  <tbody>
                    <tr v-for="event in sortEvents[category.label]"  :key="event.id" :id="event.title.replace(/[^a-z0-9]/gi, '')">
                      <td  class="is-capitalized" style="width: 20%">
                        <span v-if="event.date.repeat">
                          {{event.date.repeat.recurrence == 'biweekly' ? 'Every other ' : 'Every'}} {{event.date.repeat.day}}
                        </span>
                        <span v-else>
                          {{formatDateRange(event.date.start, event.date.end)}}
                        </span>
                      </td>
                      <td style="width: 30%">
                        <span v-if="event.url != '' ">
                          <a :href="event.url" target="_blank">{{event.title}}</a>
                        </span>
                        <span v-else>
                          {{event.title}}
                        </span>
                      </td>
                      <td style="width: 40%"><span v-html="event.description"></span></td>
                      <td class="is-capitalized">{{event.permission}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="content" v-if="archivedEventsList.length>0">
                <h3 class="subtitle is-size-4">Archives</h3>
                <p>
                  Calendars of Events from 2020 and earlier are archived below. There is one PDF for each calendar year.
                </p>
                <ul>
                  <li v-for="archive in archivedEventsList" :key="archive.id">
                    <router-link :to="archive.url" target="_blank">View {{archive.year}} Events</router-link>
                  </li>
                </ul>
              </div>
            </div> <!-- end v-else -->
          </div>
        </main>
        <SurveyLinkComponent :surveyLink=surveyLink />
      </div>
    </div>
  </div>
</template>

<script>
import SurveyLinkComponent from '@/components/SurveyLinkComponent.vue';
import dateMixins from '@/mixins/dateMixins';
import eventsData from '../../assets/data/events.json';

export default {
  name: 'Events',
  components: {
    SurveyLinkComponent,
  },
  mixins: [dateMixins],
  data() {
    return {
      currentEventsList: eventsData.currentEvents,
      archivedEventsList: eventsData.archiveEvents,
    };
  },
  computed: {
    sortEvents() {
      const sortedEventsList = {
        upcoming: {
          other: [],
          recurring: [],
        },
        past: [],
      };
      const recurringEvents = [];
      const otherEvents = [];

      // make sure hours are set to 0 so an event today is in upcoming
      const today = new Date(new Date().setHours(0, 0, 0, 0));

      this.currentEventsList.forEach((event) => {
        if (this.isUpcomingEvent(event.date, today)) {
          if (typeof event.date.repeat === 'object') {
            recurringEvents.push(event);
          } else {
            otherEvents.push(event);
          }
        } else if (event.date.start === 'TBA' || event.date.start.length === 0) {
          otherEvents.push(event);
        } else {
          sortedEventsList.past.push(event);
        }
      });

      sortedEventsList.upcoming.recurring = this.sortReccuringEvents(recurringEvents);
      sortedEventsList.upcoming.other = this.sortDate(otherEvents);
      return sortedEventsList;
    },
  },
  methods: {
    isUpcomingEvent(eventDate, today) {
      let isUpcoming = false;
      const eventStart = eventDate.start.length > 0 ? eventDate.start.split('-') : today;
      const eventEnd = eventDate.end.length > 0 ? eventDate.end.split('-') : today;
      const eventStartDate = new Date(eventStart[0], eventStart[1] - 1, eventStart[2]);
      const eventEndDate = new Date(eventEnd[0], eventEnd[1] - 1, eventEnd[2]);
      if ((typeof eventDate.repeat === 'object') || (eventStartDate >= today) || (eventEndDate >= today)) isUpcoming = true;
      return isUpcoming;
    },
    formatDate(newsDate) {
      const [year, month, day] = newsDate.split('-');
      const formattedDate = new Date();
      formattedDate.setFullYear(parseInt(year, 10));
      formattedDate.setMonth(parseInt(month, 10) - 1, parseInt(day, 10));
      return formattedDate;
    },
    formatDateRange(start, end) {
      const dateFormat = new Intl.DateTimeFormat('en', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });

      if (start === 'TBA') {
        return 'TBA';
      } if (start === '') {
        return 'Date not specified';
      }
      return dateFormat.formatRange(this.formatDate(start), this.formatDate(end));
    },
    sortDate(dates) {
      const sortedEvents = dates.sort((a, b) => {
        const date1 = new Date(a.date.start);
        const date2 = new Date(b.date.start);
        return date1 - date2;
      });

      return sortedEvents;
    },
    sortReccuringEvents(dates) {
      const days = {
        sunday: 0,
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6,
      };

      const today = new Date();
      const todayOrAfter = [];
      const beforeToday = [];

      dates.forEach((event) => {
        if (days[event.date.repeat.day.toLowerCase()] > today.getDay()) {
          todayOrAfter.push(event);
        } else {
          beforeToday.push(event);
        }
      });

      todayOrAfter.sort((a, b) => days[a.date.repeat.day.toLowerCase()] - days[[b.date.repeat.day.toLowerCase()]]);

      beforeToday.sort((a, b) => days[a.date.repeat.day.toLowerCase()] - days[[b.date.repeat.day.toLowerCase()]]);

      return todayOrAfter.concat(beforeToday);
    },
  },
  props: {
    surveyLink: {
      type: String,
      default: 'https://forms.office.com/Pages/ResponsePage.aspx?id='
      + 'SNwgxlAdUkmLOd9NVNdNgksLeUGJ_vlDujipnPAHzqlUMUlEQVEzNURFNEc5Q0MzM0ZYQ0dZR1BYRyQlQCN0PWcu',
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../assets/style/globals.scss';

.content table td, .content table th {
  border-right-width: 1px;
  border-right-color: $theme-color-primary-darker
}
</style>

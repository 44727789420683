<template>
  <aside class="menu cve-sidebar-menu mt-3" :class="isNavSidebarHiddenTouch ? 'is-hidden-touch' : ''">
    <p class="menu-label cve-capitalize-first-letter">On this page</p>
    <ul class="menu-list cve-capitalize-first-letter">
      <li v-for="(submenuObj, submenuLabel) in nav.submenu" :key="submenuObj.key">
        <span class="is-flex" style="justify-content: space-between; width: inherit">
          <router-link :to="`${$sanitize(submenuObj.path)}#${submenuObj.anchorId}`">
            {{ submenuLabel }}
          </router-link>
        </span>
        <ul>
          <li v-for="(submenuItem, label) in submenuObj.items" :key="submenuObj.id + label">
            <router-link :to="$sanitize(submenuObj.path) + '#' + submenuItem.anchorId" >{{ submenuItem.label }}</router-link>
          </li>
        </ul>
    </li>
    </ul>
  </aside>
</template>

<script>
export default {
  name: 'OnePageSidebar',
  props: {
    nav: {
      type: Object,
      required: true,
    },
    isNavSidebarHiddenTouch: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      selectedSubmenu: [this.$router.currentRoute.path],
    };
  },
  methods: {
    toggleSubmenuItem(path) {
      if (this.selectedSubmenu.includes(path)) {
        const index = this.selectedSubmenu.indexOf(path);
        this.selectedSubmenu.splice(index, 1);
      } else {
        this.selectedSubmenu.push(path);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/assets/style/globals.scss';

.cve-menu-shift {
  font-weight: bold;
  padding-left: 12px;
}

a.router-link-exact-active{
  background: $theme-color-accent-cool-light;
}

</style>

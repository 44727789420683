<template>
  <div id="cve-tertiary-page-main-container" class="container">
    <div class="columns is-centered">
      <div class="column is-8">
        <main id="cve-main-page-content" role="main">
          <div class="content">
            <h1 class="title">CVE<sup>®</sup> Program Cookie Notice</h1>
            <ExternalLinkMessage/>
            <div class="content">
              <p>Effective Date: <time>November 7, 2023</time></p>
              <p>
                This Notice describes how the CVE<sup>®</sup> Program (“the Program,” “we,” “our,” “us”) uses cookies and tracking
                technologies on its websites (the “Site,” “the Sites”). Like many sites, the Program uses “cookies,” which are small
                data files that can be sent to your web browser and stored on your computer. Cookies that the Program sets are first-party
                cookies. Cookies that we allow others to set are called third-party cookies. The Program uses cookies to distinguish you
                from other users of our website. Cookies also help enable us to improve website performance.
              </p>
            </div>
            <h2 class="title" id="TypesCookiesUsedOnSites">Types of Cookies Used on the Sites</h2>
              <h4 class="title">Strictly Necessary Cookies</h4>
                <p>
                  These cookies are required to provide content that allows the website to function. We process this information to identify
                  visitors to our site, to authenticate site administrators, to set visitor preferences, to help complete forms, and for
                  security purposes.
                </p>
              <h4 class="title">Analytics Cookies</h4>
                <p>
                  These cookies allow us to analyze traffic on the site so that we can measure and improve website performance. These cookies
                  may require your permission before they are activated, depending on your jurisdiction.
                </p>
                <p>
                  We use Google Analytics to measure how users interact with website content. As users navigate between web pages,
                  Google Analytics provides us data, such as applicable URLs, time of visit, time spent on each webpage, type of browser, and
                  type of operating system. With this information, the Program creates, designs, and updates content based on our user interests.
                  In addition, Google Analytics collects indirectly identifiable information, such as users’ IP addresses, unique IDs, and client
                  IDs. Users who do not want their indirectly identifiable information collected during their visit to our website may opt-out of
                  the Google Analytics tracking service at
                  <a href="https://tools.google.com/dlpage/gaoptout/" target="_blank">
                    https://tools.google.com/dlpage/gaoptout/
                  </a>
                  or disable analytics cookies through our
                  link.
                </p>
              <h4 class="title">Other Cookie Types</h4>
                <p>
                  The Program does not use personalization or targeted advertising cookies. These types of cookies are described at the
                  link. Please note that because these cookies are not used, no cookies will be displayed when selecting the “View Cookies” button.
                </p>
            <h2 class="title" id="YourConsent">Your Consent</h2>
                <p>
                  We request your consent, as required, to use analytics cookies before placing them on your device. You have the right to
                  withdraw your consent at any time. To enable or disable our use of analytics cookies, please access the
                  link.
                </p>
                <p>
                  You also may be able to block cookies by activating browser settings that allow you to automatically refuse the placement
                  of cookies. If you use your browser settings to block all cookies (including our strictly necessary cookies), you may not
                  be able to access all or parts of our website.
                </p>
                <p>
                  Please note that we may set strictly necessary cookies without your consent.
                </p>
            <h2 class="title" id="RecipientsYourInformation">Recipients of Your Information</h2>
                <p>
                  We share information about your use of our website with our analytics partners and analysts who may combine this
                  information with other data that you provide to them or that they have otherwise collected through your use of their
                  services elsewhere.
                </p>
                <p>
                  You may refer to our
                    <router-link to="/Legal/PrivacyPolicy">
                      Privacy Policy
                    </router-link>
                  for additional information about our use of third-party services.
                </p>
            <h2 class="title" id="Retention">Retention</h2>
                <p>
                  Cookies and similar technologies exist for different periods of time, depending on their purpose. For example, a session
                  cookie that enables our website to operate, will only exist on your device while you browse our website, but other cookies
                  will remain on your device for longer, such as some analytic cookies, which need to stay on your device so that the website
                  can recognize you when you return.
                </p>
                <p>
                  For more information about the specific cookies we use and how long a cookie remains on your device, please select the
                  link.
                </p>
            <h2 class="title" id="Contact">Contact</h2>
                <p>
                  We update this Notice from time to time for accuracy as our use of cookies may change. The last updated date at the top
                  of this Notice will tell you when we last made changes.
                </p>
                <p>
                  For more information about how we process your personal data, including personal data collected from cookies, please
                  refer to our website
                    <router-link to="/Legal/PrivacyPolicy">Privacy Policy</router-link>.
                </p>
                <p>
                  For further information about this Notice or our use of cookies, you may contact us at
                    <a href="mailto:privacy@mitre.org">privacy@mitre.org</a>.
                </p>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CookieNotice',
  data() {
    return {
      siteName: this.$store.state.API_BASE,
    };
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/style/globals.scss';
</style>

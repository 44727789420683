<template>
  <div id="cve-secondary-page-main-container">
    <div class="columns is-centered">
      <div class="column is-8-desktop cve-main-column-content-width is-12-tablet">
        <main id="cve-main-page-content" role="main">
          <div class="content">
            <h1 :id="cvenavs['Downloads']['id']" class="title">{{cvenavs['Downloads']['label']}}</h1>
            <p class="cve-help-text">
              Links that redirect to external sites
              <span class="icon cve-icon-xxs">
                <p id="externalSiteLinks" class="is-hidden">external website</p>
                <font-awesome-icon icon="external-link-alt" aria-labelledby="extenalSiteLinks"/>
              </span> will open in a new window or tab depending on the browsers used.
            </p>
            <p>
              The <router-link to='/ResourcesSupport/Glossary?activeTerm=glossaryCVEList'>CVE List</router-link> is available for download in the
              formats below, per the <router-link to="/Legal/TermsOfUse">terms of use</router-link>.<br>
              To save compressed fles, you may need to right-click and choose a “Save Link As” or “Save Target As” option.
            </p>
            <div role="information" class="notification is-info is-light">
              <div class="is-flex" style="justify-content: center;">
                <p id="infoIconVariousFormts" class="is-hidden">information</p>
                <font-awesome-icon style="flex: 0 0 40px;" size="1x"  icon="info-circle" role="alert"
                  aria-labelledby="alertIconVariousFormts" aria-hidden="false" />
                <div>
                  <p class="has-text-weight-bold">New Download File Format!</p>
                  <p>
                    <router-link to="ResourcesSupport/Glossary?activeTerm=glossaryRecord">CVE Records</router-link> in
                    <router-link to="AllResources/CveServices#cve-json-5">CVE JSON 5.0 format</router-link> are now available for bulk download in the
                    “<router-link :to="`#${cvenavs['Downloads']['submenu']['Downloads']['submenu']['Current Format']['anchorId']}`">Current Format”
                    </router-link> section of this page. Learn more
                    <router-link to="/Media/News/item/blog/2024/01/09/Deprecation-of-Legacy-Downloads-Underway">here</router-link>.
                  </p>
                </div>
              </div>
            </div>
            <div role="alert" class="notification is-warning">
              <div class="is-flex" style="justify-content: center;">
                <p id="alertIconVariousFormts" class="is-hidden">alert</p>
                <font-awesome-icon style="flex: 0 0 40px;" size="1x"  icon="exclamation-triangle" role="alert"
                  aria-labelledby="alertIconVariousFormts" aria-hidden="false" />
                <p>
                <div>
                  <p class="has-text-weight-bold">Legacy Downloads Available Limited Time Only</p>
                  <p>
                    Legacy format CVE List downloads are available from the
                    “<router-link
                      :to="`#${cvenavs['Downloads']['submenu']['Downloads']['submenu']['Legacy Format']['anchorId']}`">
                      Legacy Format
                    </router-link>”
                    section below. These legacy formats will be
                    <router-link to="/Media/News/item/blog/2024/01/09/Deprecation-of-Legacy-Downloads-Underway">
                      phased out in the first half of 2024
                    </router-link>.
                  </p>
                  <p>Learn more
                    <router-link to="/Media/News/item/blog/2024/01/09/Deprecation-of-Legacy-Downloads-Underway">
                      here
                    </router-link>.
                  </p>
                </div>
              </div>
            </div>
            <h2 :id="cvenavs['Downloads']['submenu']['Downloads']['submenu']['Current Format']['anchorId']" class="title">
              {{cvenavs['Downloads']['submenu']['Downloads']['submenu']['Current Format']['label']}}
            </h2>
            <p>
              Bulk download files of <router-link to="ResourcesSupport/Glossary?activeTerm=glossaryRecord">CVE Records</router-link> in
              <router-link to="AllResources/CveServices#cve-json-5">CVE JSON 5.0</router-link> format are hosted in the
              <a href="https://github.com/CVEProject/cvelistV5" target="_blank">cvelistV5 repository</a> on GitHub.com. View the repository
              <a href="https://github.com/CVEProject/cvelistV5" target="_blank">ReadMe</a> for additional information and known issues.
            </p>
            <p>
              The most-current download file, which includes all CVE Records and updates, may be downloaded from the table below or directly from the
              repository on GitHub. Other download capabilities, including
              <a href="https://github.com/CVEProject/cvelistV5/releases" target="_blank">daily release versions</a> and
              <a href="https://github.com/CVEProject/cvelistV5/releases" target="_blank">hourly updates</a>, are available on GitHub.
            </p>
            <div class="table-container" id="downloads-table">
              <table class="table is-striped is-hoverable cve-border-dark-blue cve-border-bottom-unset">
                <thead>
                  <tr>
                    <th style="width: 50%">Format</th>
                    <th style="width: 50%">ZIP</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-label="Format" style="width: 20%">
                      <router-link to="AllResources/CveServices#cve-json-5">CVE JSON 5.0 format</router-link>
                    </td>
                    <td data-label="Unix Compressed" style="width: 80%">
                      <a href="https://github.com/CVEProject/cvelistV5/archive/refs/heads/main.zip">main.zip</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h2 :id="cvenavs['Downloads']['submenu']['Downloads']['submenu']['Legacy Format']['anchorId']" class="title">
              {{cvenavs['Downloads']['submenu']['Downloads']['submenu']['Legacy Format']['label']}}
            </h2>
            <p>The legacy download formats below, which are derived from CVE JSON 4.0, are hosted on the cve.mitre.org website.</p>
            <p>
              All files below are approximately between 15 and 35 MB each. Download of large files causes many browsers to crash.
              Raw format files may open in a browser rather than downloading.
            </p>
            <div role="alert" class="notification is-warning">
              <div class="is-flex" style="justify-content: center;">
                <p id="alertIconVariousFormts" class="is-hidden">alert</p>
                <font-awesome-icon style="flex: 0 0 40px;" size="1x"  icon="exclamation-triangle" role="alert"
                  aria-labelledby="alertIconVariousFormts" aria-hidden="false" />
                <p>
                  <span class="has-text-weight-bold">
                    Warning! All legacy download formats and files listed below will be phased out in the first half of 2024.
                  </span>
                  Click
                  <router-link to="/Media/News/item/blog/2024/01/09/Deprecation-of-Legacy-Downloads-Underway">
                    here
                  </router-link>
                  for details or use the <a href="https://cveform.mitre.org/" target="_blank">CVE Program Request web forms</a> for help.
                </p>
              </div>
            </div>
            <p class="has-text-weight-bold">
              CVE downloads data last generated: <time :datetime='todayDateTimeStr'>{{today}}</time>
            </p>
            <div class="table-container" id="downloads-table">
              <table class="table is-striped is-hoverable cve-border-dark-blue cve-border-bottom-unset">
                <thead>
                  <tr>
                    <th style="width: 10%">Format</th>
                    <th style="width: 20%">Unix Compressed (.Z)</th>
                    <th style="width: 20%">Gzipped</th>
                    <th style="width: 20%">Raw</th>
                    <th>Additional Notes</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-label="Format" style="width: 10%">CSV</td>
                    <td data-label="Unix Compressed" style="width: 20%">
                      <a href="https://cve.mitre.org/data/downloads/allitems.csv.Z">allitems.csv.Z</a>
                    </td>
                    <td data-label="Gzipped" style="width: 20%">
                      <a href="https://cve.mitre.org/data/downloads/allitems.csv.gz">allitems.csv.gz</a>
                    </td>
                    <td data-label="Raw" style="width: 20%">
                      <a href="https://cve.mitre.org/data/downloads/allitems.csv">allitems.csv</a>
                    </td>
                    <td data-label="Additional Notes">NOTE: suitable for import into spreadsheet programs</td>
                  </tr>
                  <tr>
                    <td data-label="Format" style="width: 10%">HTML</td>
                    <td data-label="Unix Compressed" style="width: 20%">
                      <a href="https://cve.mitre.org/data/downloads/allitems.html.Z">allitems.html.Z</a>
                    </td>
                    <td data-label="Gzipped" style="width: 20%">
                      <a href="https://cve.mitre.org/data/downloads/allitems.html.gz">allitems.html.gz</a>
                    </td>
                    <td data-label="Raw" style="width: 20%">
                      <a href="https://cve.mitre.org/data/downloads/allitems.html">allitems.html</a>
                    </td>
                    <td data-label="Additional Notes"></td>
                  </tr>
                  <tr>
                    <td data-label="Format" style="width: 10%">Text</td>
                    <td data-label="Unix Compressed" style="width: 20%">
                      <a href="https://cve.mitre.org/data/downloads/allitems.txt.Z">allitems.txt.Z</a>
                    </td>
                    <td data-label="Gzipped" style="width: 20%">
                      <a href="https://cve.mitre.org/data/downloads/allitems.txt.gz">allitems.txt.gz</a>
                    </td>
                    <td data-label="Raw" style="width: 20%">
                      <a href="https://cve.mitre.org/data/downloads/allitems.txt">allitems.txt</a>
                    </td>
                    <td data-label="Additional Notes"></td>
                  </tr>
                  <tr>
                    <td data-label="Format" style="width: 10%">XML</td>
                    <td data-label="Unix Compressed" style="width: 20%">
                      <a href="https://cve.mitre.org/data/downloads/allitems.xml.Z">allitems.xml.Z</a>
                    </td>
                    <td data-label="Gzipped" style="width: 20%">
                      <a href="https://cve.mitre.org/data/downloads/allitems.xml.gz">allitems.xml.gz</a>
                    </td>
                    <td data-label="Raw" style="width: 20%">
                      <a href="https://cve.mitre.org/data/downloads/allitems.xml">allitems.xml</a>
                    </td>
                    <td data-label="Additional Notes">XML Schema Design:
                      <a href="https://cve.mitre.org/schema/cve/cve_1.0.xsd" target="_blank">cve_1.0.xsd</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h3 class="title">CVRF Files</h3>
            <p>
              CVE uses
              <a href="https://github.com/CVRF/cvrf1.1" target="_blank">Common Vulnerability Reporting Framework (CVRF)</a>
              Version 1.1 for the legacy download files below. Learn more about
              <a href="https://cve.mitre.org/cve/cvrf.html" target="_blank">CVE and CVRF</a>
              on the legacy CVE website.
            </p>
            <p>
              All files below are large, approximately between 15 and 35 MB each
            </p>
            <div role="alert" class="notification is-warning">
              <div class="is-flex" style="justify-content: center;">
                <p id="alertIconCvrf" class="is-hidden">alert</p>
                <font-awesome-icon style="flex: 0 0 40px;" size="1x"  icon="exclamation-triangle" role="alert"
                  aria-labelledby="alertIconCvrf" aria-hidden="false" />
                <p>Download of large files causes many browsers to crash.</p>
              </div>
            </div>
            <div class="columns">
              <div class="column pl-3 is-full-width">
                <div class="tile is-ancestor cve-task-tiles-container">
                  <div class="tile is-parent cve-task-left-tile">
                    <article class="tile is-child cve-border-dark-blue">
                      <h3 class="title cve-task-tile-header">
                        CVRF Files (XML)
                      </h3>
                      <p class="ml-4">
                        <a href="https://cve.mitre.org/data/downloads/allitems-cvrf.xml">All records - Raw (cvrf.xml, at least 280MB)</a>
                      </p>
                      <p class="mb-0 ml-4 has-text-weight-bold">
                        XML Files By Year:
                      </p>
                      <ul class="mt-0 tile-body cve-task-tile-list cve-list-no-bullet">
                        <li><a href="https://cve.mitre.org/data/downloads/allitems-cvrf-year-2023.xml">CVE-2023-xxxxxx records</a></li>
                        <li><a href="https://cve.mitre.org/data/downloads/allitems-cvrf-year-2022.xml">CVE-2022-xxxxxx records</a></li>
                        <li><a href="https://cve.mitre.org/data/downloads/allitems-cvrf-year-2021.xml">CVE-2021-xxxxxx records</a></li>
                        <li><a href="https://cve.mitre.org/data/downloads/allitems-cvrf-year-2020.xml">CVE-2020-xxxxxx records</a></li>
                        <li><a href="https://cve.mitre.org/data/downloads/allitems-cvrf-year-2019.xml">CVE-2019-xxxxxx records</a></li>
                        <li><a href="https://cve.mitre.org/data/downloads/allitems-cvrf-year-2018.xml">CVE-2018-xxxxxx records</a></li>
                        <li><a href="https://cve.mitre.org/data/downloads/allitems-cvrf-year-2017.xml">CVE-2017-xxxxxx records</a></li>
                        <li><a href="https://cve.mitre.org/data/downloads/allitems-cvrf-year-2016.xml">CVE-2016-xxxxxx records</a></li>
                        <li><a href="https://cve.mitre.org/data/downloads/allitems-cvrf-year-2015.xml">CVE-2015-xxxxxx records</a></li>
                        <li><a href="https://cve.mitre.org/data/downloads/allitems-cvrf-year-2014.xml">CVE-2014-xxxx records</a></li>
                        <li><a href="https://cve.mitre.org/data/downloads/allitems-cvrf-year-2013.xml">CVE-2013-xxxx records</a></li>
                        <li><a href="https://cve.mitre.org/data/downloads/allitems-cvrf-year-2012.xml">CVE-2012-xxxx records</a></li>
                        <li><a href="https://cve.mitre.org/data/downloads/allitems-cvrf-year-2011.xml">CVE-2011-xxxx records</a></li>
                        <li><a href="https://cve.mitre.org/data/downloads/allitems-cvrf-year-2010.xml">CVE-2010-xxxx records</a></li>
                        <li><a href="https://cve.mitre.org/data/downloads/allitems-cvrf-year-2009.xml">CVE-2009-xxxx records</a></li>
                        <li><a href="https://cve.mitre.org/data/downloads/allitems-cvrf-year-2008.xml">CVE-2008-xxxx records</a></li>
                        <li><a href="https://cve.mitre.org/data/downloads/allitems-cvrf-year-2007.xml">CVE-2007-xxxx records</a></li>
                        <li><a href="https://cve.mitre.org/data/downloads/allitems-cvrf-year-2006.xml">CVE-2006-xxxx records</a></li>
                        <li><a href="https://cve.mitre.org/data/downloads/allitems-cvrf-year-2005.xml">CVE-2005-xxxx records</a></li>
                        <li><a href="https://cve.mitre.org/data/downloads/allitems-cvrf-year-2004.xml">CVE-2004-xxxx records</a></li>
                        <li><a href="https://cve.mitre.org/data/downloads/allitems-cvrf-year-2003.xml">CVE-2003-xxxx records</a></li>
                        <li><a href="https://cve.mitre.org/data/downloads/allitems-cvrf-year-2002.xml">CVE-2002-xxxx records</a></li>
                        <li><a href="https://cve.mitre.org/data/downloads/allitems-cvrf-year-2001.xml">CVE-2001-xxxx records</a></li>
                        <li><a href="https://cve.mitre.org/data/downloads/allitems-cvrf-year-2000.xml">CVE-2000-xxxx records</a></li>
                        <li><a href="https://cve.mitre.org/data/downloads/allitems-cvrf-year-1999.xml">CVE-1999-xxxx records</a></li>
                      </ul>
                    </article>
                  </div>
                </div> <!-- end task tile container -->
              </div>
            </div>
          </div> <!-- content -->
        </main>
        <SurveyLinkComponent :surveyLink=surveyLink />
     </div>
     <div class="column is-3 is-hidden-touch">
        <OnPageSidebar :nav="cvenavs['Downloads'].submenu['Downloads']" />
      </div>
    </div>
  </div>
</template>

<script>
import OnPageSidebar from '@/components/OnPageSidebar.vue';
import SurveyLinkComponent from '@/components/SurveyLinkComponent.vue';

export default {
  name: 'Downloads',
  components: {
    OnPageSidebar,
    SurveyLinkComponent,
  },
  props: {
    cvenavs: {
      type: Object,
      required: true,
    },
    surveyLink: {
      type: String,
      default: 'https://forms.office.com/Pages/ResponsePage.aspx?id='
      + 'SNwgxlAdUkmLOd9NVNdNgksLeUGJ_vlDujipnPAHzqlUNUxaSE1HQ0dXNVdYUzFGSFhaU000Q0NKTyQlQCN0PWcu',
    },
  },
  data() {
    return {
      today: new Date().toLocaleString([], { year: 'numeric', month: 'long', day: '2-digit' }),
      todayDateStr: this.getDateTimeStr(),
    };
  },
  methods: {
    getDateTimeStr() {
      this.dateObj = new Date();
      this.todayDateTimeStr = `${this.dateObj.toLocaleString([], { year: 'numeric' })
      }-${this.dateObj.toLocaleString([], { month: '2-digit' })
      }-${this.dateObj.toLocaleString([], { day: '2-digit' })}`;
      return this.todayDateTimeStr;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../assets/style/globals.scss';
@import '../assets/style/elements/cveTableStacked.scss';
</style>

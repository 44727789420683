<template>
  <header id="CVE-Notification-Banner" class="is-info-banner" role="alert" v-if="shouldDisplay">
    <div class="notification is-info-banner" style="padding: 5px 20px 5px 30px">
      <div style="margin:auto;">
          <section class="accordions" style="display: flex; justify-content: center; flex-direction: row;">
            <div class="notification-body" id="notification-short" style="max-width: 100%; flex-wrap: nowrap;">
              <div class="accordion is-active cve-notification-wrapper"
                :class="{'is-hidden': accordian['notificationshort']}" id="notificationshort">
                  <div class="accordion-body pt-1 pb-1" style="margin-right: auto; margin-left:a uto; max-width: 100%; flex-wrap: nowrap;">
                    <div class="accordion-content" style="display: flex; flex-direction: row; justify-content: flex-start;">
                      <span>
                        <p id="alertIcon" class="is-hidden">alert</p>
                        <font-awesome-icon style="flex: 0 0 40px; margin-top: 3px;" size="1x"  icon="info-circle" role="alert"
                          aria-labelledby="alertIcon" aria-hidden="false"/>
                      </span>
                      <div style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                        <span style="padding-left: 5px" v-for="(section, index) in notificationContents" :key="index">
                          <span v-if="section.contentType == 'paragraph'" v-html="$sanitize(section.content) + ' '"></span>
                          <span class="has-text-weight-bold" v-if="section.contentType == 'bold'">{{section.content}} </span>
                          <a v-if="section.contentType == 'externalLink'" :href="section.link" target="_blank">{{section.linkText}}
                            <span class="icon cve-icon-xxs">
                              <p :id="'bannerExtenalLink' + index" class="is-hidden">external link</p>
                              <font-awesome-icon icon="external-link-alt" :aria-labelledby="'bannerExtenalLink' + index"/>
                            </span>
                          </a>
                          <span v-if="section.contentType == 'internalLink'">
                            <router-link :to="section.link">{{section.linkText}}</router-link>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
              </div>
            </div>

            <div class="notification-body" id="notification-full">
              <div class="accordion is-active cve-notification-wrapper" style="padding-top: 5px;"
              :class="{'is-hidden': accordian['notification']}">
                <div class="accordion-body">
                  <div class="accordion-content" style="display: flex; flex-direction: row; justify-content: flex-start;">
                    <span>
                      <p id="alertIcon" class="is-hidden">alert</p>
                      <font-awesome-icon style="flex: 0 0 40px; margin-top: 3px" size="1x"  icon="info-circle" role="alert"
                        aria-labelledby="alertIcon" aria-hidden="false"/>
                    </span>
                    <div>
                      <span style="padding-left: 5px" v-for="(section, index) in notificationContents" :key="index">
                        <span v-if="section.contentType == 'paragraph'" v-html="$sanitize(section.content) + ' '"></span>
                        <span class="has-text-weight-bold" v-if="section.contentType == 'bold'">{{section.content}} </span>
                        <a v-if="section.contentType == 'externalLink'" :href="section.link" target="_blank">{{section.linkText}}
                          <span class="icon cve-icon-xxs">
                            <p :id="'bannerExtenalLink' + index" class="is-hidden">external link</p>
                            <font-awesome-icon icon="external-link-alt" :aria-labelledby="'bannerExtenalLink' + index"/>
                          </span>
                          {{ ' ' }}
                        </a>
                        <span v-if="section.contentType == 'internalLink'">
                          <router-link :to="section.link">{{section.linkText}}</router-link>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p id="expandCollapseButton" class="is-hidden">Expand or collapse notification button</p>
              <button id="alertIcon" class="button is-text pl-3 pb-0 pt-0" style="height: 1.5rem" aria-labelledby="expandCollapseButton"
                @click="toggleAccordianItem('notification', 'notificationshort')" :aria-expanded="!accordian['notificationshort'] ? true : false">
                <span class="icon is-small" style="padding-top: 6px;">
                  <p :id="accordian['notificationshort'] ? 'caret-down' : 'caret-up'" class="is-hidden">close notification button</p>
                  <font-awesome-icon style="font-size: 25px" :icon="accordian['notificationshort'] ? 'caret-down' : 'caret-up'"
                    aria-hidden="false" focusable="true" :aria-labelledby="accordian['notificationshort'] ? 'caret-down' : 'caret-up'"/>
                </span>
              </button>
            </div>
          </section>
        </div>
      </div>
  </header>
</template>

<script>
import Vue from 'vue';
import NotificationData from '@/assets/data/NotificationBanner.json';

export default {
  data() {
    return {
      notificationContents: NotificationData.notificationContents,
      shouldDisplay: NotificationData.shouldDisplay,
      accordian: { notification: this.$store.state.collapsed, notificationshort: !(this.$store.state.collapsed) },
    };
  },
  methods: {
    toggleNotificationBanner() {
      this.$store.commit('toggleNotificationBanner');
    },
    toggleAccordianItem(id1, id2) {
      Vue.set(this.accordian, id1, !this.accordian[id1]);
      Vue.set(this.accordian, id2, !this.accordian[id2]);

      this.toggleNotificationBanner();
    },
  },
};

</script>

<style scoped lang="scss">
@import '../assets/style/globals.scss';
.cve-notification-wrapper {
  text-align: center;
  display: flex;
  justify-content: center;
}
</style>

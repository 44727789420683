<template>
  <div class="content">
    <nav class="level">
      <div class="level-left">
        <div class="level-item">
          <h1 class="title">{{this.$store.state.cveId}}</h1>
        </div>
        <div class="level-item" v-if="hasState">
          <span class="tag cve-state-tag has-text-weight-bold">{{this.$store.state.idData.state}}</span>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <a id="cve-view-json" :href="`https://cveawg-test.mitre.org/api/cve-id/${this.$store.state.cveId}`" target="_blank">
            View JSON
          </a>
        </div>
      </div>
    </nav>
    <div role="information" class="notification is-info is-light p-3">
      <div class="is-flex" style="justify-content: center;">
          <section class="cve-accordian" style="flex: 0 0 100%;">
              <div class="message" style="background: transparent !important;">
                <div class="message-header p-0"
                  style="background: transparent !important;border-bottom: unset !important">
                  <span class="icon-text">
                    <span class="icon">
                      <p id="infoIconVariousFormts" class="is-hidden">information</p>
                      <font-awesome-icon style="flex: 0 0 40px;" size="1x"  icon="info-circle" role="alert"
                        aria-labelledby="alertIconVariousFormts" aria-hidden="false" />
                    </span>
                    <span class="mr-1">Important CVE JSON 5 Information</span>
                  </span>
                  <button class="button message-header-button" style="background: transparent !important;"
                    @click="isMessageExpanded = !isMessageExpanded">
                    <span class="icon is-small">
                      <p id="expandCollapseAltText" class="is-hidden">
                        {{isMessageExpanded ? 'expand': 'collapse'}}
                      </p>
                      <font-awesome-icon :icon="isMessageExpanded ? 'minus' : 'plus'"
                        aria-hidden="false" focusable="true" aria-labelledby="expandCollapseAltText"/>
                    </span>
                  </button>
                </div>
              </div>
          </section>
      </div>
      <div v-if="isMessageExpanded" class="p-4 has-text-justified">
        <p>
          <router-link to='/ResourcesSupport/Glossary?activeTerm=glossaryRecord'>CVE Records</router-link>
          on this CVE.ORG website are displayed in
          <router-link to='/AllResources/CveServices#cve-json-5'>CVE JSON 5.0</router-link>.
          Downloads in this format are available on the <router-link to='/Downloads'>Downloads</router-link>
          page. Learn more about
          <router-link to='/AllResources/CveServices#cve-json-5'>CVE JSON 5.0 here</router-link>.
        </p>
      </div>
    </div>
    <div class="has-text-justified">
      <p>This ID has been reserved by a <router-link to="/ProgramOrganization/CNAs">CNA</router-link>.</p>
      <p>
        This candidate has been reserved by a CVE Numbering Authority (CNA). This record will be updated by the assigning CNA once details are
        available. Learn more about the Reserved state
        <router-link to="ResourcesSupport/FAQs#pc_cve_recordsreserved_signify_in_cve_record">here</router-link>.
      </p>
    </div>
  </div>
</template>

<script>
export default ({
  data() {
    return {
      isMessageExpanded: false,
    };
  },
  methods: {
    hasState() {
      if (this.$store.state.idData?.state && this.$store.state.idData.state.length > 0) {
        return true;
      }

      return false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/style/cveRecord.scss';

.tag.cve-state-tag {
  background-color: $theme-color-base-light !important;
  color: $theme-color-base-darkest !important;
}
</style>

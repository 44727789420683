<template>
  <div id="cve-tertiary-page-main-container" class="container">
    <div class="columns is-centered">
      <div class="column is-8">
        <main id="cve-main-page-content" role="main">
          <div class="content">
            <h1 class="title">CVE<sup>®</sup> Program Privacy Policy</h1>
            <ExternalLinkMessage/>
            <div class="content">
              <p>Effective Date: <time>November 7, 2023</time></p>
              <p>
                This Privacy Policy explains the types of personal information that the CVE<sup>®</sup> Program (“CVE,” “we,” “our,”
                “us”) collects from users of its Sites at
                <router-link to="/">
                  www.cve.org
                </router-link>
                and
                <a href='https://test.cve.org/' target="_blank">
                  test.cve.org
                </a>
                (the “Sites”); how we use, share, protect, store, and otherwise process that personal information; and your choices with respect
                to our use of your personal information. These Sites are operated by
                <a href='https://www.mitre.org/' target="_blank">
                  The MITRE Corporation (“MITRE”)
                </a>
                on behalf of the
                <a href='https://www.dhs.gov/' target="_blank">
                  U.S. Department of Homeland Security (DHS)
                </a>
                which funds the CVE Program, and other partner organizations. By accessing these Sites, you acknowledge that you understand and
                agree to the terms outlined in this Privacy Policy. If you have any questions, you may contact us using the information provided
                at the end of this Privacy Policy.
              </p>
              <p>This notice is provided in a layered format so you can click through to the specific areas listed below.</p>
              <ul>
                <li><router-link to="#PersonalInformation">Personal Information We Collect</router-link></li>
                <li><router-link to="#HowWeUse">How We Use Personal Information</router-link></li>
                <li><router-link to="#HowWeShare">How We Share Personal Information</router-link></li>
                <li><router-link to="#LinkedWebsites">Linked Websites</router-link></li>
                <li><router-link to="#SocialFeatures">Social Features</router-link></li>
                <li><router-link to="#SecurityPersonalInformation">Security Of Personal Information</router-link></li>
                <li><router-link to="#YourChoices">Your Choices</router-link></li>
                <li><router-link to="#VisitorsOutsideUS">Information For Visitors From Outside The United States</router-link></li>
                <li>
                  <router-link to="#VisitorsFromEUandUK">
                    Information For Visitors From The European Economic Area And The United Kingdom
                  </router-link>
                </li>
                <li><router-link to="#SpecificIndividuals">Information for Specific Individuals</router-link></li>
                <li><router-link to="#Children">Privacy Of Children</router-link></li>
                <li><router-link to="#ChangesToOurPolicy">Changes To Our Privacy Policy</router-link></li>
                <li><router-link to="#Questions">Questions</router-link></li>
              </ul>
            </div>
            <h2 class="title" id="PersonalInformation">Personal Information We Collect</h2>
            <div class="content">
              <h3 class="title">Personal Information You Give Us</h3>
              <p>
                The CVE Program may obtain your personal information when you interact with our Sites. Personal information is data that identifies
                you, or could reasonably be used to identify you, as an individual, such as your name, postal address, email address, and phone
                number.
              </p>
              <h3 class="title">Information We Collect Automatically</h3>
              <p>
                We also may collect other information about your visits to our Sites using automated tools; for example, cookies and other passive
                information collection technologies enable MITRE to compile aggregate statistics concerning use of the Sites, analyze trends,
                enhance the security of the Sites, deliver content, and otherwise administer and improve the Sites. This information may include
                your browser type, language preference, operating system, device identifier, device type, access time, Internet Protocol (IP)
                address, the URLs of websites you visited before and after visiting the Sites, the web search that landed you on the Site, length
                of your visits to the Sites, and the links you click and pages you visit within our Sites. The Sites uses The Sites uses cookies
                for purposes of generating analytics as described in the following paragraph. We do not use cookies for personalization or for
                targeted advertising. For more information, see our
                  <router-link to="/Legal/CookieNotice">
                    Cookie Notice
                  </router-link>.
              </p>
              <p>
                We use certain third-party web analytics services to help us understand and analyze how visitors use our Sites and to serve on
                our behalf across the Internet. We have implemented Google Analytics on our Sites. For more information on how Google Analytics
                uses data collected through the Sites, visit
                <a href='https://www.google.com/policies/privacy/partners/' target="_blank">
                  https://www.google.com/policies/privacy/partners/
                </a>.
                To opt out of Google Analytics cookies, visit:
                <a href='https://www.google.com/settings/ads' target="_blank">
                  https://www.google.com/settings/ads
                </a>
                and
                <a href='https://tools.google.com/dlpage/gaoptout/' target="_blank">
                  https://tools.google.com/dlpage/gaoptout/
                </a>.
              </p>
              <p>
                Please note that we, and other parties we work with, may collect personal information about your online activities over time and
                across different devices and sites when you visit the Sites. Your web browser may have settings that allow you to transmit a
                “Do Not Track” signal when you visit various websites or use online services. Like many websites, these Sites are not designed to
                respond to “Do Not Track” signals received from browsers. To learn more about online tracking, the Federal Trade Commission (FTC)
                provides guidance on
                <a href="https://www.consumer.ftc.gov/articles/how-protect-your-privacy-online" target="_blank">
                  How To Protect Your Privacy Online
                </a>.
              </p>
              <h2 class="title" id="HowWeUse">How We Use Personal Information</h2>
              <p>
                The CVE Program may use personal information we collect through the Sites to:
              </p>
              <ul>
                <li>
                  communicate with you, including to respond to your questions and requests, send you notices about our services, or contact
                  you for additional information when needed;
                </li>
                <li>market our services;</li>
                <li>analyze trends, usage, and the activities of Site visitors;</li>
                <li>improve our Sites and notify you about important updates;</li>
                <li>perform internal business analyses or for other business purposes consistent with our mission;</li>
                <li>facilitate, manage, personalize, and improve our partnership relationships;</li>
                <li>
                  identify, prevent, investigate, and take other actions with respect to suspected or actual fraud or illegal activity or
                  other activity that violates our policies;
                </li>
                <li>ensure the security and integrity of our personal information processing;</li>
                <li>comply with applicable laws, rules, regulations, and legal processes as well as our company policies; and</li>
                <li>fulfill other purposes, with your consent (as required).</li>
              </ul>
              <h2 class="title" id="HowWeShare">How We Share Personal Information</h2>
              <p>
                The CVE Program does not sell or share your personal information to deliver targeted advertising to you. The Program may
                share your personal information within our Program and with our program partners. We also may share your personal information to:
              </p>
              <ul>
                <li>better respond to your inquiries;</li>
                <li>perform marketing research for support- and service-related purposes;</li>
                <li>protect rights, property, life, health, security, and safety;</li>
                <li>
                  negotiate or complete any proposed or actual merger, purchase, sale, or any other type of acquisition or other transaction,
                  including a transfer of all or a portion of our business to another organization;
                </li>
                <li>disclose personal information with your consent or at your direction; and</li>
                <li>achieve any other purpose consistent with our statements in this Privacy Policy or otherwise allowed by applicable law.</li>
              </ul>
              <p>
                The CVE Program may disclose your personal information to comply with applicable law, such as in response to requests from law
                enforcement agencies, regulators, other public authorities, courts, and third-party litigants in connection with legal proceedings
                or investigations.
              </p>
              <h2 class="title" id="LinkedWebsites">Linked Websites</h2>
              <p>
                Our Site may include links to other websites that are not owned or operated by the CVE Program. This Privacy Policy does not apply
                to those websites, which may have their own privacy policies that you should review to understand how they may collect, use, or
                disclose your personal information. The CVE® Program is not responsible for the content or privacy practices of any linked websites
                that it does not control.
              </p>
              <h2 class="title" id="SocialFeatures">Social Features</h2>
              <p>
                Certain features of our Sites may permit you to interact with social media networks operated by unaffiliated parties, for example,
                if you “like” or “follow” the CVE Program on those platforms (“<span class="has-text-weight-bold">Social Features</span>”). If
                you choose to “like” or share content or post information using Social Features, that information may be publicly displayed, and
                the party operating the social media platform may receive information about you and your use of our Sites. Similarly, if you
                interact with us through Social Features, we may have access to information about you from the social media platform. Please note
                that if you mention the CVE Program, or comment about or in response to us, in your post on a social media platform, that platform
                may allow us to publish your post on our Sites. You should review the terms, policies, and settings of these platforms to learn
                more about their data practices and adjust your settings accordingly.
              </p>
              <h2 class="title" id="SecurityPersonalInformation">Security of Personal Information</h2>
              <p>
                The CVE Program maintains reasonable safeguards designed to protect personal information from loss, theft, misuse, and unauthorized
                access, disclosure, alteration, and destruction. The CVE Program employs encryption technologies and user authentication procedures
                that are designed to keep data secure. Nevertheless, transmission via the Internet and online digital storage are not completely
                secure, so we cannot guarantee the security of your personal information.
              </p>
              <h2 class="title" id="YourChoices">Your Choices</h2>
              <p>
                If you are currently on our communications list and do not wish to receive further promotional email messages, you may email a
                request to “
                  <a href="mailto:lms@mitre.org">
                    lms@mitre.org
                  </a>”
                with the subject “signoff cve-announce-list.” Opting out of marketing emails will not affect our
                administrative emails to you (for example, emails about your use of our services).
              </p>
              <h2 class="title" id="VisitorsOutsideUS">Information for Visitors from Outside the United States</h2>
              <p>
                If you are a visitor from outside the United States, please be aware that information we obtain about you may be transferred to
                and processed in the United States or other jurisdictions. By using our Sites and providing your personal information, you
                acknowledge that your personal information may be transferred to and processed in jurisdictions outside your own. Please be
                aware that the data protection laws and regulations that may apply to your personal information transferred to the United States
                or other countries may be different from the laws in your country of residence.
              </p>
            </div>
            <div class="content">
              <h2 class="title" id="VisitorsFromEUandUK">Information for Visitors from the European Economic Area and the United Kingdom</h2>
              <p>
                This section provides a GDPR Notice (“<span class="has-text-weight-bold">Notice</span>”) for residents of the European Economic
                Area (“<span class="has-text-weight-bold">EEA</span>”) and United Kingdom (“<span class="has-text-weight-bold">UK</span>”)
                regarding their respective rights under the European Union’s General Data Protection Regulation and the United Kingdom’s General
                Data Protection Regulation (collectively, the “<span class="has-text-weight-bold">GDPR</span>”). MITRE is the data controller for
                personal data collected through our Site.
              </p>
              <p>
                This Notice supplements the information in this Privacy Policy and other MITRE and CVE Program privacy policies and notices. If
                there is a conflict between any other privacy policy, statement, or notice and this Notice, this Notice will prevail.
              </p>
              <h3 class="title">Our Collection and Use of Personal Data</h3>
              <p>
                Personal data collected through the Sites may include:
              </p>
              <ul>
                <li>
                  “<span class="has-text-weight-bold">Contact Data</span>”. You may provide your contact details, such as your name, phone number,
                  postal address, email address, and company affiliation; for example, when you contact us for further information or subscribe to
                  receive our news and information offerings.
                </li>
                <li>
                  “<span class="has-text-weight-bold">Device Data</span>”. We may obtain information about devices that access our Sites, including
                  the type of device, operating system, device settings, unique device identifiers, and error data.
                </li>
                <li>
                  “<span class="has-text-weight-bold">Other Data You Provide</span>”. This includes personal data you include in communications you
                  send to us, such as inquiries about our services.
                </li>
              </ul>
              <h3 class="title">Our Processing of Your Personal Data</h3>
              <p>
                Your personal data may be required for us to provide some of our services. In some instances, if you fail to provide your
                personal data, you may not be able to access or use our services. We may process the personal data you provide for any of the
                purposes identified in the “How We Use Personal Information” and “How We Share Personal Information” Sections of our Online
                Privacy Policy.
              </p>
              <p>
                Your personal data is processed pursuant to the following <span class="has-text-weight-bold">legal bases</span>:
              </p>
              <ul>
                <li>
                  The processing is <span class="has-text-weight-bold">necessary for us to provide you with the services</span> you request or to
                  respond to your questions.
                </li>
                <li>
                  We have a <span class="has-text-weight-bold">legal obligation</span> to process your personal data, such as compliance with
                  applicable tax laws or other government regulations or compliance with a court order or binding law enforcement request.
                </li>
                <li>
                  We have a <span class="has-text-weight-bold">legitimate interest</span> in processing your personal data and our reasons for using
                  the personal data outweigh the potential prejudice to your data protection rights. In particular, we have a legitimate interest
                  in the following instances:
                  <ul>
                    <li>
                      To analyze and improve the safety and security of our Sites and services, including by implementing and enhancing security
                      measures and safeguards and protecting against fraud, spam, and other abuses;
                      </li>
                    <li>
                      To maintain and improve the Sites and its services; and
                    </li>
                    <li>
                      To operate and promote the CVE Program and provide you with information and communications about our services that are
                      tailored to, and in accordance with, your preferences.
                    </li>
                  </ul>
                </li>
                <li>
                  You have <span class="has-text-weight-bold">consented to</span> our processing of your personal data. When you consent, you may
                  change your mind and withdraw your consent at any time by emailing us at
                  <a href="mailto:privacy@mitre.org">
                    privacy@mitre.org
                  </a>.
                </li>
              </ul>
              <h3 class="title">Your Rights Under the GDPR</h3>
              <p>
                The GDPR provides individuals with certain rights regarding their personal data. You may ask us to take the following actions:
              </p>
              <ul>
                <li>provide you with information about our processing of your personal data and access to your personal data;</li>
                <li>update or correct inaccuracies in your personal data;</li>
                <li>delete your personal data;</li>
                <li>transfer a copy of your personal data to you or a third party of your choice;</li>
                <li>restrict the processing of your personal data;</li>
                <li>object to our use of your personal data for marketing purposes; and</li>
                <li>object to our reliance on legitimate interests as the basis for processing your personal data.</li>
              </ul>
              <p>
                You may submit these requests by email to
                <a href="mailto:privacy@mitre.org">
                    privacy@mitre.org
                </a>.
                We may require specific information from you to help us verify your identity prior to processing your request. Applicable law
                may require or permit us to decline your request. If we decline your request, we will tell you why, subject to any legal
                restrictions on disclosing this information.
              </p>
              <p>
                If you would like to submit a complaint about our use of your personal data or our response to your request regarding your personal
                data, you may contact us at <a href="mailto:privacy@mitre.org">privacy@mitre.org</a> or submit a complaint directly to the data
                protection authority in your jurisdiction. If you reside in the EEA, you can find information about your data protection authority
                <a href="https://edpb.europa.eu/about-edpb/about-edpb/members_en" target="_blank">
                  here
                </a>.
                If you reside in the UK, you may file complaints with the Information Commissioner’s Office
                <a href="https://ico.org.uk/make-a-complaint/" target="_blank">
                  here
                </a>.
              </p>
              <h3 class="title">Our Retention of Your Personal Data</h3>
              <p>
                The CVE Program retains your personal data for no longer than is necessary to achieve the purposes for which the personal data
                was collected, or as may otherwise be permitted or required under applicable law. To determine the appropriate retention period,
                we will consider the scope and sensitivity of the personal data; the potential risk of harm from unauthorized access to, use, or
                disclosure of the data; the purposes for which we process the data; whether we can achieve our purposes through other means; our
                business needs; and applicable legal requirements. Unless otherwise required by applicable law, at the end of the retention period,
                we will anonymize or securely destroy your personal data.
              </p>
              <h3 class="title">Personal Data Transfers</h3>
              <p>
                By using the Sites, you acknowledge that your personal data may be collected, transferred to, and processed in jurisdictions
                outside your own. When you directly provide your personal data through our Sites, you acknowledge that your personal data is
                being provided by you to entities based in the United States. The laws that apply to personal data protection in the United States
                differ from those applicable in the EEA and the UK.
              </p>
              <p>
                If it is necessary for us to transfer personal data out of the EEA and the UK, we do so by using suitable data transfer mechanisms,
                such as the standard contractual clauses approved by the European Commission, which impose data protection obligations on parties
                to the transfer.
              </p>
            </div>
            <div class="content">
              <h2 class="title" id="SpecificIndividuals">Information for Specific Individuals</h2>
              <p>
                Residents of U.S. states with consumer privacy laws in effect and enforceable may contact us at
                <a href="mailto:privacy@mitre.org">
                  privacy@mitre.org
                </a>
                for further information about our privacy practices.
              </p>
              <h2 class="title" id="Children">Privacy of Children</h2>
              <p>
                Our Sites are not intended for children, and we do not knowingly collect personal information from children under the age of 16.
                If we become aware that we have collected personal information from a child, we will delete it in accordance with applicable law.
              </p>
              <h2 class="title" id="ChangesToOurPolicy">Changes to Our Privacy Policy</h2>
              <p>
                The CVE Program may update or modify this Privacy Policy from time to time at our discretion. We will indicate changes to this
                Privacy Policy by updating the “Effective Date” at the beginning of the Privacy Policy. Please review this Privacy Policy
                periodically and especially before you provide any personal information to us. Your continued use of these Sites after any update
                to this Privacy Policy will constitute your acceptance of our changes.
              </p>
              <h2 class="title" id="Questions">Questions</h2>
              <p>
                If you have questions about this Privacy Policy or the CVE Program’s privacy practices, you may email
                <a href="mailto:privacy@mitre.org">
                  privacy@mitre.org
                </a>.
              </p>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  data() {
    return {
      siteName: this.$store.state.API_BASE,
    };
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/style/globals.scss';
</style>

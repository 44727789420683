<template>
  <div id="cve-secondary-page-main-container" class="container">
    <div class="columns is-centered">
      <div class="column is-8-desktop cve-main-column-content-width is-12-tablet">
        <main id="cve-main-page-content" role="main">
          <div class="content" v-if="typeof newsItem === 'undefined'">
            <h1 class="title">News item not found.</h1>
          </div>
          <div class="content" v-else>
            <h1 class="title" v-html="$sanitize(newsItem.title)"></h1>
            <ExternalLinkMessage/>
            <span class ="level is-mobile has-text-grey-dark mb-3">
              <div class ="level-left">
                <span class ="level-item tag is-rounded has-text-grey-dark is-capitalized">{{newsItem.newsType}}</span>
                <time class ="level-item" :datetime="newsItem.date">{{formatDate(newsItem.date)}}</time>
              </div>
            </span>
            <p class="ml-2" v-if="newsItem.newsType == 'blog'">
              By {{newsItem.author.name}}<span v-if="newsItem.author.organization.name !== 'CVE Program'">,</span>
              <span v-if="newsItem.author.organization.name !== 'CVE Program'">
                <a :href="newsItem.author.organization.url" v-if="newsItem.author.organization.name !== 'CVE Program'" target="_blank">
                  {{newsItem.author.organization.name}},
                </a>
                <span class="pl-1">{{newsItem.author.title}}</span>
              </span>
            </p>
            <div class="content ml-2 mt-5">
              <div class="block" v-for="(section, index) in newsItem.description" :key="index">
                <p v-if="section.contentnewsType == 'paragraph'" v-html="section.content"></p>
                <blockquote v-if="section.contentnewsType == 'quote'">{{section.content}}</blockquote>
                <figure v-if="section.contentnewsType == 'video'" class="image is-3by2">
                  <iframe class="has-ratio" :src="section.content" frameborder="0" allowfullscreen>
                  </iframe>
                </figure>
                <figure class="image" v-if="section.contentnewsType == 'image'">
                  <img :style="`width : ${section.imageWidth}px`" :src="`/images${section.href}`" :alt="section.imgAltText"/>
                  <figcaption v-html="section.captionText"></figcaption>
                </figure>
                <div class="columns" v-if="section.contentnewsType == 'table'">
                  <div class="column"></div>
                  <div class="column is-three-quarters">
                    <table class="table is-striped is-hoverable cve-border-dark-blue cve-center">
                      <thead>
                        <tr>
                          <th>Year</th>
                          <th :colspan="section.quarter.length" class="cve-table-year-header">{{section.year}}</th>
                        </tr>
                        <tr>
                          <th>Quarter</th>
                          <th v-for="quarter in section.quarter" :key="quarter">{{quarter}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>{{section.dataRowTitle}}</th>
                          <td v-for="count in section.dataRowCounts" :key="count">{{count}}</td>
                        </tr>
                      </tbody>
                  </table>
                  </div>
                  <div class="column"></div>
                </div>
              </div>
              <figure class="image is-3by2" v-if="newsItem.newsType == 'podcast'">
                <iframe class="has-ratio" :src="newsItem.url" frameborder="0" allowfullscreen>
                </iframe>
              </figure>
            </div>
          </div>
        </main>
        <SurveyLinkComponent :surveyLink="cvenavs['AllNews']['submenu']['Recent']['submenu'][`${submenuName}`]['surveyLink']" />
      </div>
      <div class="column is-3 is-hidden-touch">
        <NavigationSidebar :nav="cvenavs['AllNews']" />
      </div>
    </div>
  </div>
</template>

<script>
import NavigationSidebar from '@/components/NavigationSidebar.vue';
import SurveyLinkComponent from '@/components/SurveyLinkComponent.vue';
import newsData from '@/assets/data/news.json';
import newsMixins from '@/mixins/newsMixins';

export default {
  name: 'NewsItem',
  mixins: [newsMixins],
  components: {
    NavigationSidebar,
    SurveyLinkComponent,
  },
  data() {
    return {
      submenuName: '',
      cveNavs: this.cvenavs,
    };
  },
  created() {
    this.getSubmenuName();
  },
  computed: {
    newsItem() {
      const urlParameters = this.$route.params;
      const itemDate = `${urlParameters.year}-${urlParameters.month}-${urlParameters.day}`;
      return newsData.currentNews.find((newsItem) => {
        if (newsItem.newsType === urlParameters.newsType && newsItem.date === itemDate
          && this.createShortTitle(newsItem) === urlParameters.shortTitle) return true;
        return null;
      });
    },
  },
  methods: {
    getSubmenuName() {
      const { newsType } = this.$route.params;
      if (newsType === 'news') {
        this.submenuName = 'News Articles';
      } else if (newsType === 'press-release') {
        this.submenuName = 'Press Releases';
      } else {
        this.submenuName = `${newsType.charAt(0).toUpperCase()}${newsType.substring(1)}s`;
      }
    },
    formatDate(newsDate) {
      const [year, month, day] = newsDate.split('-');
      const formattedDate = new Date();
      formattedDate.setFullYear(parseInt(year, 10));
      formattedDate.setMonth(parseInt(month, 10) - 1, parseInt(day, 10));
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return formattedDate.toLocaleDateString(undefined, options);
    },
  },
  props: {
    cvenavs: {
      type: Object,
      required: true,
    },
  },
};

</script>

<style scoped lang="scss">
@import '@/assets/style/globals.scss';

//Equally space data cells in metrics tables
td {
  width: auto !important;
}
</style>

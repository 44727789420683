<template>
  <p class="cve-help-text">
    Links that redirect to external websites <span class="icon cve-icon-xxs help-text-icon">
    <font-awesome-icon icon="external-link-alt" aria-labelledby="extenalSiteLinks"/>
    </span> will open a new window or tab depending on the web browser used.
  </p>
</template>

<script>

export default {
  name: 'ExternalLinkMessage',
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../assets/style/globals.scss';

</style>

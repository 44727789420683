<template>
<div>
  <main id="cve-main-page-content" role="main">
  <section class="hero" >
    <div class="hero-body">
    <div class="container cve-mission-container mt-1">
      <h1 class="title is-hidden">CVE</h1>
      <h2 class="title">
        CVE<sup id="cve-mission-trademark">®</sup> Program Mission
      </h2>
      <div id="cve-mission-text" class="subtitle">
        Identify, define, and catalog publicly disclosed cybersecurity vulnerabilities.
        <button v-if="cveRecordsLoading" class="button is-loading cve-margin-top-neg7 cve-button-ghost"></button>
        <p class="subtile mt-2" v-if="!cveRecordsLoading && !cveRecordsRequestErrored">
          Currently, there are <span class="has-text-weight-bold">{{cveRecordsTotal}}</span> CVE Records accessible via
          <router-link to="/Downloads">Download</router-link> or
          <span class="icon-text">
            <a href="https://cve.mitre.org/cve/search_cve_list.html" target="_blank">Search
              <span class="icon cve-icon-xxs">
                <p id="externalLinkIcon" class="is-hidden">external website</p>
                <font-awesome-icon icon="external-link-alt"
                 aria-labelledby="externalLinkIcon" aria-hidden="false"/>
              </span>
            </a>
          </span>
        </p>
      </div>
      <div class="notification is-warning" role="alert" v-if="cveRecordsRequestErrored">
        <div class="is-flex" style="justify-content: center;">
          <p id="alertIconCveRecordsRequestErrored" class="is-hidden">alert</p>
          <font-awesome-icon style="flex: 0 0 40px;" size="1x"  icon="exclamation-triangle" role="img"
            aria-labelledby="alertIconCveRecordsRequestErrored" aria-hidden="false" />
          <p>
            Total count of CVE records is currently unavailable. Please
            <span class="icon-text">
              <a href="https://cveform.mitre.org/" target="_blank">report the issue
                <span class="icon is-size-7 cve-icon-xxs">
                  <p id="extenalLinkReport" class="is-hidden">external site</p>
                  <font-awesome-icon icon="external-link-alt" aria-labelledby="extenalLinkReport" aria-hidden="false" focusable="false"/>
                </span>
              </a>
            </span>
            and try again later. Sorry for the inconvenience.
          </p>
        </div>
      </div>
    </div>
    </div>
  </section>

  <div id="cve-homepage-columns-container" class="container">
    <HomeModule :cvenavs="cvenavs"/>
  </div>
  </main>

</div>
</template>

<script>
import HomeModule from '@/components/HomeModule.vue';
import axios from 'axios';

export default {
  name: 'Home',
  components: {
    HomeModule,
  },
  data() {
    return {
      cveRecordsTotal: 0,
      cveRecordsLoading: true,
      cveRecordsRequestErrored: false,
    };
  },
  created() {
    this.getCVERecordsCount();
  },
  computed: {
    API_BASE() { return this.$store.state.API_BASE; },
  },
  props: {
    cvenavs: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getCVERecordsCount() {
      axios
        .get(`https://cveawg.mitre.org/api/health-check`, { timeout: 30000 })
        .then((res) => {
          let count = '212345';
          count = this.$sanitize(count);
          count = parseInt(count, 10);

          if ((typeof count === 'number') && !Number.isNaN(count)) {
            this.cveRecordsRequestErrored = false;
            this.cveRecordsTotal = count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          } else {
            this.cveRecordsRequestErrored = true;
          }
        })
        .catch(() => {
          this.cveRecordsRequestErrored = true;
        })
        .finally(() => {
          this.cveRecordsLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/style/globals.scss';

.hero {
  background: white;
  border-bottom: 0.5px solid $theme-color-base-lighter;
}

.hero-body {
  padding: 1.5rem 1rem;
}
.container.cve-mission-container {
  background: white;
  text-align: center;
}

.cve-margin-top-neg7 {
  margin-top: -7px !important;
}

#cve-mission-trademark {
  font-size: 18px;
}
</style>

<template>
  <div class="columns">
    <div class="column is-9">
      <section class="hero">
        <div class="hero-body cve-partnership-hero-body">
          <div class="container cve-partnership-container">
            <div class="columns">
              <div class="column is-two-thirds cve-partnership-left-column">
                <h3 class="cve-hidden">CVE partnership</h3>
                <p class="subtitle cve-subtitle has-text-centered">
                  The CVE Program partners with community members worldwide to grow CVE content and expand its usage. Click below to learn more
                  about the role of <router-link to="/ResourcesSupport/Glossary?activeTerm=glossaryCNA">CVE Numbering Authorities (CNAs)
                  </router-link> and <router-link to="/ResourcesSupport/Glossary?activeTerm=glossaryRoot">Roots</router-link>.
                </p>
                <div class="buttons is-centered">
                  <router-link to="/PartnerInformation/Partner" class="button cve-button cve-button-base-color">Learn More</router-link>
                  <router-link to="/PartnerInformation/Partner#HowToBecomeAPartner" class="button cve-button cve-button-accent-warm">
                    Become a Partner
                  </router-link>
                  <router-link to="/vulnogram/vulnogram.html" class="button cve-button cve-button-accent-vulnogram">
                    Vulnogram
                  </router-link>
                </div>
              </div>
              <div class="column cve-partnership-right-column">
                <figure class="image">
                  <img src="@/assets/images/cvePartnershipGraphic.svg" class="cve-partnership-img" alt=""/>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="hero">
        <div class="hero-body cve-task-tiles-hero-body">
            <div class="tile is-ancestor cve-task-tiles-container">
              <div class="tile is-parent cve-task-access-tile">
                <article class="tile is-child cve-border-dark-blue">
                  <h3 class="title cve-task-tile-header">
                    <span class="icon is-medium is-block cve-task-tile-icon"><font-awesome-icon :icon="['far', 'arrow-alt-circle-right']" /></span>
                    Access
                  </h3>
                  <ul class="tile-body cve-task-tile-list">
                    <li class="cve-task-tile-list-item"><router-link to="/PartnerInformation/ListofPartners">List of Partners</router-link></li>
                    <li class="cve-task-tile-list-item"><router-link to="/ResourcesSupport/AllResources/CNARules">CNA Rules</router-link></li>
                    <li class="cve-task-tile-list-item"><router-link to="/About/Process#CVERecordLifecycle">CVE Record Lifecycle</router-link></li>
                    <li class="cve-task-tile-list-item">
                      <span class="icon-text">
                        <a href="https://github.com/CVEProject">CVEProject on GitHub for Development
                        <span class="icon is-size-7 cve-icon-xxs">
                          <p id="cveGithub" class="is-hidden">external site</p>
                          <font-awesome-icon icon="external-link-alt" aria-labelledby="cveGithub" aria-hidden="false" >
                          </font-awesome-icon>
                        </span>
                        </a>
                      </span>
                    </li>
                    <li class="cve-task-tile-list-item">
                      <span class="icon-text">
                        <a href="https://github.com/CVEProject/Ideas">Idea tracker
                        <span class="icon is-size-7 cve-icon-xxs">
                          <p id="cveGithub" class="is-hidden">external site</p>
                          <font-awesome-icon icon="external-link-alt" aria-labelledby="IdeaTrackeronGitHub" aria-hidden="false" >
                          </font-awesome-icon>
                        </span>
                        </a>
                      </span>
                    </li>
                  </ul>
                </article>
              </div>
              <div class="tile is-parent cve-task-learn-tile">
                <article class="tile is-child cve-border-dark-blue">
                  <h3 class="title cve-task-tile-header">
                    <span class="icon is-medium is-block cve-task-tile-icon"><font-awesome-icon :icon="['fab', 'leanpub']"/></span>
                    Learn
                  </h3>
                  <ul class="tile-body cve-task-tile-list">
                    <li class="cve-task-tile-list-item"><router-link to="/About/Overview">About CVE</router-link></li>
                    <li class="cve-task-tile-list-item"><router-link to="/About/Process">Process</router-link></li>
                    <li class="cve-task-tile-list-item"><router-link to="/ProgramOrganization/Structure">Program Organization</router-link></li>
                    <li class="cve-task-tile-list-item">
                      <router-link to="/About/RelatedEfforts">Related Efforts</router-link>
                    </li>
                    <li class="cve-task-tile-list-item"><router-link to="/ResourcesSupport/Glossary">Terminology</router-link></li>
                    <li class="cve-task-tile-list-item">
                      <router-link :to="`/AllResources/CveServices`">CVE Services for CNAs</router-link>
                    </li>
                  </ul>
                </article>
              </div>
              <div class="tile is-parent cve-task-report-tile">
                <article class="tile is-child cve-border-dark-blue">
                  <h3 class="title is-4 cve-task-tile-header">
                    <span class="icon is-medium is-block cve-task-tile-icon"><font-awesome-icon :icon="['far', 'clipboard']" /></span>
                    Report/Request
                  </h3>
                  <ul class="tile-body cve-task-tile-list">
                    <li class="cve-task-tile-list-item">
                      <router-link to="/ReportRequest/ReportRequestForNonCNAs">Report vulnerability/Request CVE ID</router-link>
                    </li>
                    <li class="cve-task-tile-list-item">
                      <router-link to="/ReportRequest/ReportRequestForNonCNAs">Request CVE Record be published/updated</router-link>
                    </li>
                    <li class="cve-task-tile-list-item">
                      <span class="icon-text">
                        <a href="https://cveform.mitre.org/" target="_blank">Report the use of a reserved CVE ID
                        <span class="icon is-size-7 cve-icon-xxs">
                          <p id="usedCVEID" class="is-hidden">external site</p>
                          <font-awesome-icon icon="external-link-alt" aria-labelledby="usedCVEID" aria-hidden="false" >
                          </font-awesome-icon>
                        </span>
                        </a>
                      </span>
                    </li>
                  </ul>
                </article>
              </div>
            </div>
        </div>
      </section>
      <section class="hero">
        <div class="hero-body cve-persona-tiles-hero-body">
          <div class="container">
            <h3 class="title is-4 cve-task-tile-group-header">
              Access Resources Based on Role
            </h3>
            <div class="tile is-ancestor cve-persona-tiles">
              <router-link to="/ResourcesSupport/Resources#CVENumberingAuthorities" class="tile is-ancestor cve-persona-tile-container">
                <div class="tile is-parent cve-persona-tile-container">
                  <div class="tile is-child cve-persona-tile">
                    <div class="cve-persona-tile-content">
                      <span class="icon is-large is-block cve-persona-icon"><font-awesome-icon class="fas fa-3x" icon="handshake" /></span>
                      <p class="title is-6 has-text-centered">CVE Numbering Authority (CNA)</p>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/ResourcesSupport/Resources#CVEWorkingGroups" class="tile is-ancestor cve-persona-tile-container">
                <div class="tile is-parent cve-persona-tile-container">
                  <div class="tile is-child cve-persona-tile">
                    <div class="cve-persona-tile-content">
                        <span class="icon is-large is-block cve-persona-icon"><font-awesome-icon class="fas fa-3x" icon="users-cog" /></span>
                        <p class="title is-6 has-text-centered">Working Group</p>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/ResourcesSupport/Resources#CVEIDRequestersVulnerabilityResearchers"
              class="tile is-ancestor cve-persona-tile-container">
                <div class="tile is-parent cve-persona-tile-container">
                  <div class="tile is-child cve-persona-tile">
                    <div class="cve-persona-tile-content">
                      <span class="icon is-large is-block cve-persona-icon"><font-awesome-icon class="fas fa-3x" icon="laptop-code" /></span>
                      <p class="title is-6 has-text-centered">Vulnerability Researcher</p>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </section>
      <div class="cve-feedback-link">
          <span class="icon-text">
            <a href="https://tinyurl.com/cve-home-page-survey" target="_blank">Provide feedback for this page
            <span class="icon is-size-7 cve-icon-xxs">
              <p id="extenalSurveyLink" class="is-hidden">external site</p>
              <font-awesome-icon icon="external-link-alt" aria-labelledby="extenalSurveyLink" aria-hidden="false"/>
            </span>
            </a>
          </span>
      </div>
      <p class="cve-help-text is-centered has-text-centered mt-2">
        Some of the links will take you back to the
        <span>
          <a href="https://cve.mitre.org/" target="_blank">original CVE website
           <span class="icon cve-icon-xxs">
            <p id="extenalLinkOrignalCveWebsite" class="is-hidden">external site</p>
            <font-awesome-icon icon="external-link-alt" aria-labelledby="extenalLinkOrignalCveWebsite"/>
          </span>
          </a>
        </span>.
      </p>
      <span class="is-centered has-text-centered"><ExternalLinkMessage/></span>
    </div>
    <HomePageSidebarModule/>
  </div>
</template>

<script>
import HomePageSidebarModule from '@/components/HomePageSidebarModule.vue';

export default {
  name: 'HomeModule',
  components: {
    HomePageSidebarModule,
  },
};
</script>

<style scoped lang="scss">
@import '../assets/style/globals.scss';

.cve-feedback-link {
  padding-top: 1.5rem;
  text-align: center;
}

.cve-hidden {
  display: none;
}

.cve-partnership-hero-body {
  border: 1px solid $theme-color-base-light;
  border-radius: 5px;
  background-color: white;
  padding: 1rem;
}

// column cve-partnership-right-column
.cve-partnership-right-column {
  text-align: center;
  padding: auto;
}

.cve-partnership-text-subtile {
  color: black;
}

.cve-partnership-img {
  max-height: 200px;
  vertical-align: middle;
}

.hero-body.cve-persona-tiles-hero-body, .hero-body.cve-task-tiles-hero-body {
  padding-left: unset;
  padding-right: unset;
}

.tile.is-ancestor.cve-persona-tile-container {
  padding: unset;
  margin-bottom: unset;
}

.tile.is-parent.cve-persona-tile-container {
  border-style: solid;
  border-width: 1px;
  border-color: $theme-color-primary-darker;
  border-left-style: none;
}

.tile.is-parent.cve-persona-tile-container:first-child {
  border-left-style: solid;
}

.cve-partner-button {
  color: black;
  background-color: $theme-color-accent-warm;
  border-color: black;
}

.cve-persona-icon {
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 15px;
  color: $theme-color-primary-darker;
}

.tile.is-child.cve-persona-tile {
  font-weight: bold;
  padding: unset;
}

.cve-persona-tile-content {
  padding: 0.8rem;
}

.cve-task-tile-header {
    background-color: $theme-color-primary-darker;
    color: white;
    padding: 8px 10px 8px 10px;
    text-align: left;
    display: flex;
}

.cve-task-tile-title {
  margin-left:15px;
}

.title:not(:last-child) {
  margin-bottom: 1rem;
}

.tile-body {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.tile.is-parent {
  padding: 0.25rem;
}

.tile .is-parent.cve-task-access-tile {
  padding-left: 0;
}

.cve-task-tile-icon {
  color: white;
  margin-left: unset;
  margin-right: unset;
}

.cve-task-report-tile {
  padding-right: 0px !important;
}

.tile.is-child.task-tile {
    padding: unset;
}

.cve-task-tile-list {
  padding: 0px 10px 20px 35px;
  text-align: left;
}

.cve-task-tile-list-item {
  list-style: square;
}

.icon {
  margin-bottom: unset;
}

.subtitle.cve-subtitle {
  margin-bottom: 1rem;
}
</style>

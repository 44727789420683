<template>
  <div>
     <meta http-equiv="refresh" :content="'0;URL=' + fileUrl" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      fileUrl: '/vulnogram/js/tagify.min.js',
    };
  },
};
</script>

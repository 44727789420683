<template>
  <div id="cve-secondary-page-main-container" class="container">
    <div class="columns is-centered">
      <div class="column is-8-desktop cve-main-column-content-width is-12-tablet">
        <main id="cve-main-page-content" role="main">
          <div class="content">
            <h1 :id="cvenavs['About']['submenu']['History']" class="title">{{cvenavs['About']['submenu']['History']['label']}}</h1>
            <ExternalLinkMessage/>
            <h2 :id="cvenavs['About']['submenu']['History']['items']['Overview']['anchorId']" class="title">
              {{cvenavs['About']['submenu']['History']['items']['Overview']['label']}}</h2>
            <p>
              The original concept for what would become the <router-link to='/ResourcesSupport/Glossary?activeTerm=glossaryCVEList'>
              CVE List</router-link> was presented by the co-creators of CVE, The MITRE Corporation’s
              David E. Mann and Steven M. Christey, as a white paper entitled,
              <router-link to='/Resources/General/Towards-a-Common-Enumeration-of-Vulnerabilities.pdf' target="_blank">
              <span class='is-italic'>Towards a Common Enumeration of Vulnerabilities (PDF, 0.3MB)</span></router-link>, at the
              <span class='is-italic'>2nd Workshop on Research with Security Vulnerability Databases</span> on January 21-22, 1999 at Purdue
              University in West Lafayette, Indiana, USA.
            </p>
            <p>
              From that original concept, a working group was formed (which would later become the initial 19-member CVE Editorial Board),
              and the original 321 CVE Records were created. The <router-link to='/ResourcesSupport/Glossary?activeTerm=glossaryCVEList'>
              CVE List</router-link> was officially launched for the public in September 1999.
            </p>
            <h3>Widespread Adoption of CVE by the Community</h3>
            <p>
              The cybersecurity community endorsed the importance of CVE via “CVE-compatible” products and services from the moment CVE
              was launched in 1999. As quickly as December 2000 there were 29 organizations participating with declarations of compatibility
              for 43 products. Today, those numbers have increased significantly with numerous products and services from around the world
              incorporating CVE Records.
            </p>
            <p>
              Another catalyst for adoption is the ongoing inclusion of CVE IDs in security advisories. Numerous major operating system (OS)
              vendors and other organizations from around the world include CVE IDs in their alerts to ensure that the international community
              benefits by having the CVE IDs as soon as a problem is announced. In addition, CVE Records are used to uniquely identify
              vulnerabilities in public watch lists such as the <a href='https://www.owasp.org/index.php/OWASP_Top_Ten_Project' target="_blank">OWASP
              Top 10 Web Application Security Issues</a>, among others, and are rated by severity in the
              <a href='https://www.first.org/cvss/' target="_blank">Common Vulnerability Scoring System (CVSS)</a>. CVE IDs are also frequently cited
              in trade publications and general news media reports regarding software bugs, such as
              <a href='https://cve.mitre.org/cgi-bin/cvename.cgi?name=CVE-2014-0160' target="_blank">CVE-2014-0160</a> for
              “<a href='https://tinyurl.com/ukm1e8vt' target="_blank">
              Heartbleed</a>” and <a href='https://cve.mitre.org/cgi-bin/cvename.cgi?name=CVE-2019-0708' target="_blank">CVE-2019-0708</a> for
              “<a href='https://en.wikipedia.org/wiki/BlueKeep' target="_blank">Bluekeep</a>.”
            </p>
            <p>
              Use of CVE by U.S. agencies was recommended by the
              <a href='https://www.nist.gov/index.html' target="_blank">
              National Institute of Standards and Technology
              (NIST)</a> in “<span class='is-italic'>NIST Special Publication (SP) 800-51, Use of the
              CVE Vulnerability Naming Scheme</span>,” which was initially released in 2002 and updated in 2011.
              In June 2004, the <a href='https://www.disa.mil/' target="_blank">U.S. Defense Information Systems Agency (DISA)</a> issued a
              task order for information assurance applications that requires the use of products that use CVE IDs.
            </p>
            <p>
              CVE has also been used as the basis for entirely new services. NIST's <a href='https://nvd.nist.gov/' target="_blank">U.S. National
              Vulnerability Database (NVD)</a>
              a “comprehensive cybersecurity vulnerability database that integrates all publicly available U.S. Government vulnerability
              resources and provides
              references to industry resources” is synchronized with, and based upon, the CVE List. NVD also includes
              <a href='https://scap.nist.gov/' target="_blank">Security
              Content Automation Protocol (SCAP)</a> mappings for CVE IDs. SCAP is a method for using specific standards to enable
              automated vulnerability
              management, measurement, and policy compliance evaluation (e.g., FISMA compliance) and CVE is one of the open community
              standards SCAP uses for enumerating, evaluating, and measuring the impact of software problems and reporting results. In
              addition, the U.S. <a href='https://nvd.nist.gov/fdcc/index.cfm' target="_blank">
              Federal Desktop Core Configuration (FDCC)</a> requires verification of compliance with FDCC requirements using
              SCAP-validated scanning tools.
              <a href='https://cassandra.cerias.purdue.edu/CVE_changes/' target="_blank">CVE Change Logs</a> is a tool created by
              <a href='http://www.cerias.purdue.edu/' target="_blank">
              CERIAS/Purdue University</a> that monitors additions and changes to the CVE List and allows users to obtain daily or
              monthly reports.
              <a href='https://oval.cisecurity.org/' target="_blank">Open Vulnerability and Assessment Language (OVAL)</a>, operated by the
              <a href='https://www.cisecurity.org/' target="_blank">
              Center for Internet Security</a>, is a standard for determining the machine state of a computer systems using
              community-developed OVAL Vulnerability
              Definitions that are based primarily on CVE Records. The <a href='https://cwe.mitre.org/' target="_blank">Common Weakness Enumeration
              (CWE™)</a> is a formal
              dictionary of common software weaknesses that is based in part on the 150,000+ CVE Records on the CVE List.
            </p>
            <p>
              In 2011, the <a href='https://www.itu.int/en/pages/default.aspx' target="_blank">International Telecommunication Union’s (ITU-T)</a>
              Cybersecurity Rapporteur
              Group, which is the telecom/information system standards body within the treaty-based 150-year-old intergovernmental
              organization, adopted CVE as
              a part of its new “<a href='https://www.itu.int/en/ITU-T/studygroups/com17/Pages/cybex.aspx' target="_blank">Global Cybersecurity
              Information Exchange techniques
              (X.CYBEX)”</a> by issuing <a href='https://www.itu.int/ITU-T/recommendations/rec.aspx?rec=11061' target="_blank">Recommendation ITU-T
              X.1520 Common Vulnerabilities
              and Exposures (CVE)</a>, was based upon the former CVE Compatibility Program’s archived <span class='is-italic'>
              Requirements and Recommendations
              for CVE Compatibility</span> document.
            </p>
            <h3>CVE Continues to Grow</h3>
            <p>
              In 2016, the CVE Program began actively expanding the number of organizations participating as
              <router-link to='/ResourcesSupport/Glossary?activeTerm=glossaryCNA'>CVE
              Numbering Authorities (CNAs)</router-link>. CNA partners are how the
              <router-link to='/ResourcesSupport/Glossary?activeTerm=glossaryCVEList'>
              CVE List</router-link> is built. Every <router-link to='/ResourcesSupport/Glossary?activeTerm=glossaryRecord'>CVE Record</router-link>
              is added by a CNA. This expansion continues today with more and more organizations from around the world deciding to partner with the
              CVE Program to <router-link to='/PartnerInformation/Partner#HowToBecomeAPartner'>become a CNA</router-link>.
            </p>

            <h2 :id="cvenavs['About']['submenu']['History']['items']['Past Sponsors']['anchorId']" class="title">
              {{cvenavs['About']['submenu']['History']['items']['Past Sponsors']['label']}}</h2>
            <ul>
              <li><a href="https://www.disa.mil/" target="_blank">Defense Information Systems Agency (DISA):</a> Colonel Larry Huffman</li>
              <li><a href="https://www.energy.gov/" target="_blank">Department of Energy (DOE):</a> John Przysucha</li>
              <li><a href="https://www.treasury.gov/Pages/default.aspx" target="_blank">Department of the Treasury:</a> Jim Flyzik</li>
              <li><a href="https://www.gsa.gov/" target="_blank">General Services Administration:</a> Sallie McDonald</li>
              <li><a href="https://www.intelligence.gov/" target="_blank">Intelligence Community:</a> Bill Dawson</li>
              <li><a href="https://www.irs.gov/" target="_blank">Internal Revenue Service (IRS):</a> Len Baptiste</li>
              <li><a href="https://www.mitre.org/" target="_blank">MITRE Corporation:</a> Pete Tasker</li>
              <li><a href="https://www.nasa.gov/" target="_blank">National Aeronautics and Space Administration (NASA):</a> Dave Nelson</li>
              <li><a href="https://www.niap-ccevs.org/" target="_blank">National Information Assurance Partnership (NIAP):</a> Ron Ross</li>
              <li>National Infrastructure Protection Center (NIPC): Bob Gerber</li>
              <li><a href="https://www.nist.gov/index.html" target="_blank">National Institute of Standards and Technology (NIST):</a> Tim Grance</li>
              <li><a href="https://www.nsa.gov/" target="_blank">National Security Agency (NSA):</a> Tony Sager</li>
              <li><a href="https://www.af.mil/" target="_blank">U.S. Air Force:</a> Matt Mleziva</li>
            </ul>

            <SurveyLinkComponent :surveyLink="cvenavs['About']['submenu']['History']['surveyLink']" />
          </div>
        </main>
      </div>
      <div class="column is-3 is-hidden-touch">
        <NavigationSidebar :nav="cvenavs['About']" />
      </div>
    </div>
  </div>
</template>

<script>
import NavigationSidebar from '@/components/NavigationSidebar.vue';
import SurveyLinkComponent from '@/components/SurveyLinkComponent.vue';

export default {
  name: 'History',
  components: {
    NavigationSidebar,
    SurveyLinkComponent,
  },
  props: {
    cvenavs: {
      type: Object,
      required: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../assets/style/globals.scss';

</style>

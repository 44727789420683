<template>
  <div id="cve-secondary-page-main-container" class="container">
    <div class="columns is-centered">
      <div class="column is-12-desktop is-11-tablet cve-main-column-content-width">
        <main id="cve-main-page-content" role="main">
          <div class="content">
            <h1 class="title">
              Press Release Archives
            </h1>
            <p>
              Press releases from 1999-2019 are archived below in a single PDF. For the most recent press releases, go to the
              <router-link to="/Media/News/PressReleases">Press Releases</router-link> page.
            </p>
            <div class="table-container">
              <table class="table is-striped is-hoverable cve-border-dark-blue cve-border-bottom-unset">
                <thead>
                  <tr>
                    <th style="width: 25%">Date</th>
                    <th style="width: 75%">Press Releases</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(pressRelease, index) in archivePressReleases" :key="index">
                    <td style="width: 25%">{{pressRelease.date}}</td>
                    <td style="width: 75%">
                      <router-link :to="pressRelease.url" target="_blank">{{pressRelease.title}}</router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <SurveyLinkComponent :surveyLink="cvenavs['AllNews']['submenu']['Archives']['submenu']['Press Releases']['surveyLink']" />
          </div>
        </main>
      </div>
      <div class="column is-3" :class="isNavSidebarHiddenTouch ? 'is-hidden-touch' : ''">
        <NavigationSidebar :nav="cvenavs['AllNews']" :isNavSidebarHiddenTouch="isNavSidebarHiddenTouch"/>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationSidebar from '@/components/NavigationSidebar.vue';
import SurveyLinkComponent from '@/components/SurveyLinkComponent.vue';
import newsData from '@/assets/data/news.json';

export default {
  name: 'PressReleaseArchives',
  components: {
    NavigationSidebar,
    SurveyLinkComponent,
  },
  data() {
    return {
      archivePressReleases: [],
      isNavSidebarHiddenTouch: false,
    };
  },
  created() {
    this.transformPressReleases();
    this.sortPressReleases();
  },
  methods: {
    transformPressReleases() {
      newsData.archivePressReleases.forEach((pressRelease) => {
        const [, startYear, endYear, title] = pressRelease.url.split(/(\d{4})-(\d{4})_([a-zA-Z\d-]+)\.([a-zA-Z]+)$/);
        const transformedEntry = {
          date: `${startYear}-${endYear}`,
          title: title.replaceAll('-', ' '),
          url: pressRelease.url,
        };

        this.archivePressReleases.push(transformedEntry);
      });
    },
    sortPressReleases() {
      return this.archivePressReleases.sort((a, b) => {
        const date1 = new Date(a.date);
        const date2 = new Date(b.date);
        return date2 - date1;
      });
    },
  },
  props: {
    cvenavs: {
      type: Object,
      required: true,
    },
  },
};
</script>

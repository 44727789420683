<template>
  <div id="cve-secondary-page-main-container" class="container">
    <div class="columns is-centered">
      <div class="column is-12-desktop is-11-tablet cve-main-column-content-width">
        <main id="cve-main-page-content" role="main">
          <div class="content">
            <h1 class="title">
              Blog Archives
            </h1>
            <p>
              Blog articles from July 2021 and earlier are archived below. There is one PDF for each blog article. For the most recent articles, go
              to the <router-link to="/Media/News/Blogs">Blogs</router-link> page.
            </p>
            <div class="has-text-right pb-4">
              <button class="button cve-button" @click="toggleAccordianAll()">
                {{accordian.length > 1 ? 'Collapse All' : 'Expand All'}}
              </button>
            </div>
            <section class="cve-accordian">
              <div v-for="(archiveYear, index) in Object.keys(sortedEntries).sort(function(a,b){return b-a})"
                :key="index" class="message">
                <div class="message-header cve-base-light-gray-borders-bg">
                  <p class="message-label has-text-weight-bold" style="margin-top: auto; margin-bottom: auto;">
                    {{archiveYear}}
                  </p>
                  <button class="button" @click="toggleAccordianItem(archiveYear)"
                    :aria-expanded="!accordian.includes(archiveYear) ? 'true' : 'false'"
                    :aria-controls="archiveYear">
                    <span class="icon is-small">
                      <p :id="archiveYear + '-alttext'" class="is-hidden">
                        {{accordian.includes(archiveYear) ? 'collapse' : 'expand'}}
                      </p>
                      <font-awesome-icon :icon="accordian.includes(archiveYear) ? 'plus' : 'minus'"
                        aria-hidden="false" focusable="true" :aria-labelledby="archiveYear + '-alttext'"/>
                    </span>
                  </button>
                </div>
                <div class="message-body" v-show="accordian.includes(archiveYear)" :id="archiveYear">
                  <div class="table-container" :id="archiveYear">
                    <table class="table is-striped is-hoverable cve-border-dark-blue cve-border-bottom-unset">
                      <thead>
                        <tr>
                          <th style="width: 25%">Date</th>
                          <th style="width: 75%">Article</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(blogEntry, index) in sortedEntries[archiveYear]" :key="index">
                          <td style="width: 25%">{{formatDate(blogEntry.date)}}</td>
                          <td style="width: 75%">
                            <router-link :to="blogEntry.url" target="_blank">{{blogEntry.title}}</router-link>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
            <p class="cve-help-text">
              *Note: Additional archives will be added soon.
            </p>
            <SurveyLinkComponent :surveyLink="cvenavs['AllNews']['submenu']['Archives']['submenu']['Blogs']['surveyLink']" />
          </div>
        </main>
      </div>
      <div class="column is-3" :class="isNavSidebarHiddenTouch ? 'is-hidden-touch' : ''">
        <NavigationSidebar :nav="cvenavs['AllNews']" :isNavSidebarHiddenTouch="isNavSidebarHiddenTouch"/>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationSidebar from '@/components/NavigationSidebar.vue';
import SurveyLinkComponent from '@/components/SurveyLinkComponent.vue';
import newsData from '@/assets/data/news.json';

export default {
  name: 'BlogArchives',
  components: {
    NavigationSidebar,
    SurveyLinkComponent,
  },
  data() {
    return {
      archiveBlogs: newsData.archiveBlogs,
      accordian: [],
      sortedEntries: {},
      isNavSidebarHiddenTouch: false,
    };
  },
  created() {
    this.sortBlogEntries();
  },
  methods: {
    formatDate(newsDate) {
      const [year, month, day] = newsDate.split('-');
      const formattedDate = new Date();
      formattedDate.setFullYear(parseInt(year, 10));
      formattedDate.setMonth(parseInt(month, 10) - 1, parseInt(day, 10));
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return formattedDate.toLocaleDateString(undefined, options);
    },
    sortBlogEntries() {
      const blogArchivesByYear = {};
      const sortedBlogArchives = {};
      this.archiveBlogs.forEach((blogEntry) => {
        const [, year, month, day, title] = blogEntry.url.split(/(\d{4})-(\d{2})-(\d{2})_([a-zA-Z\d-]+)\.([a-zA-Z]+)$/);
        const newEntry = {
          year,
          date: `${year}-${month}-${day}`,
          title: title.replaceAll('-', ' '),
          url: blogEntry.url,
        };
        if (Object.prototype.hasOwnProperty.call(blogArchivesByYear, year)) {
          blogArchivesByYear[year].push(newEntry);
        } else {
          blogArchivesByYear[year] = [newEntry];
        }
      });
      Object.keys(blogArchivesByYear).forEach((archiveYear) => {
        sortedBlogArchives[archiveYear] = this.sortYearEntries(blogArchivesByYear[archiveYear]);
        this.accordian.push(archiveYear);
      });
      this.sortedEntries = sortedBlogArchives;
    },
    sortYearEntries(entryList) {
      const sortedEntries = entryList.sort((a, b) => {
        const date1 = new Date(a.date);
        const date2 = new Date(b.date);
        return date2 - date1;
      });
      return sortedEntries;
    },
    toggleAccordianAll() {
      if (this.accordian.length > 1) {
        this.accordian = [];
      } else {
        Object.keys(this.sortedEntries).forEach((year) => {
          this.accordian.push(year);
        });
      }
    },
    toggleAccordianItem(id) {
      if (this.accordian.includes(id)) {
        const index = this.accordian.indexOf(id);
        this.accordian.splice(index, 1);
      } else {
        this.accordian.push(id);
      }
    },
  },
  props: {
    cvenavs: {
      type: Object,
      required: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/assets/style/globals.scss';
@import '@/assets/style/elements/cveTableStacked.scss';

.is-active {
  color: $black;
}

.collapse {
  display: inline;
  margin-left: 5px;
}
</style>

<template>
  <div id="cve-secondary-page-main-container" class="container">
    <div class="columns  is-centered">
      <div class="column is-8-desktop cve-main-column-content-width is-12-tablet">
        <main id="cve-main-page-content" role="main">
          <div class="content">
            <h1 :id="cvenavs['Program Organization']['submenu']['Working Groups']['id']" class="title">
              {{cvenavs['Program Organization']['submenu']['Working Groups']['label']}}</h1>
            <ExternalLinkMessage/>
            <p>
              CVE Working Groups (WGs) actively focus on improving processes, workflows, and other aspects of the program as it continues to grow
              and expand. There are seven main WGs: Automation (AWG), CNA Coordination (CNACWG), Outreach and Communications (OCWG), Quality (QWG),
              Strategic Planning (SPWG), Tactical (TWG), and Vulnerability Conference and Events (VCEWG).
            </p>
            <p>
              Details about these WGs are found below including information on how to join, as well as links to documents, repositories,
              and projects. Read the
              <router-link to="/Resources/Roles/WorkingGroups/CVE-Working-Group-Operations-Handbook-v1-0.pdf" target="_blank">CVE Working Group
              Operations Handbook</router-link>.
            </p>
            <div id="cve-how-to-join">
              <h3 class="title">
                How to Join
              </h3>
              <p>
                To join a group or request more information, please click the button below to access the CVE Program Request forms and select “Other”
                from the dropdown menu.
              </p>
              <div class="buttons is-centered pt-4">
                <a href="https://cveform.mitre.org/" class="button cve-button cve-button-accent-warm" target="_blank">Join a WG</a>
              </div>
            </div>
            <br/>
            <div class="cve-white-bg-gray-border-container">
              <h3 class="title mt-4">Podcast - CVE Working Groups, What They Are and How They Improve CVE</h3>
              <figure class ="image is-16by9">  <!-- podcast -->
                <iframe class="has-ratio" width="460" height="215" src="https://www.youtube.com/embed/IT9HlBOeKKc" frameborder="0" allowfullscreen>
                </iframe>
              </figure>
              <p>
                The chairs and co-chairs of the CVE Working Groups (WGs) &mdash; each of whom is an active member of the
                CVE community &mdash; chat about their WG’s overall mission, current work, and future plans. WGs help improve quality, automation,
                processes, and other aspects of the CVE Program as it continues to grow and expand. How and why to participate, and the impact
                individuals can make on the program, are also included.
              </p>
            </div>
            <h2 :id="cvenavs['Program Organization']['submenu']['Working Groups']['items']['Automation Working Group (AWG)']['anchorId']"
            class="title">
            {{cvenavs['Program Organization']['submenu']['Working Groups']['items']['Automation Working Group (AWG)']['label']}}</h2>
            <p>
              The AWG is focused on identifying and advancing proposals for the collaborative design, development, and deployment of
              automated capabilities that support the efficient management of the CVE Program.
            </p>
            <p>
              <span class="has-text-weight-bold">Membership Eligibility:</span> Per the AWG Charter, “Any active, CVE authorized
              program member may participate on the AWG. This includes Board members, CVE Numbering Authority (CNA) representatives,
              [and] participants from the Secretariat's organization. In addition, the AWG is open to participation from the public
              at large. There is no limit to the number of representatives a given organization may have as members of the
              working group.”
            </p>
            <div class="tile is-ancestor cve-task-tiles-container">
              <div class="tile is-parent cve-task-left-tile">
                <article class="tile is-child cve-border-dark-blue">
                  <h3 class="title cve-task-tile-header">
                    Documents
                  </h3>
                  <ul class="tile-body cve-task-tile-list">
                    <li class="cve-task-tile-list-item">
                      <a href="https://github.com/CVEProject/cve-services/blob/dev/CONTRIBUTING.md" target="_blank">Contributing to CVE Services</a>
                    </li>
                    <li class="cve-task-tile-list-item">
                      <a href="https://github.com/CVEProject/automation-working-group/blob/master/AWG_Charter.md" target="_blank">AWG Charter</a>
                    </li>
                  </ul>
                </article>
              </div>
              <div class="tile is-parent">
                <article class="tile is-child cve-border-dark-blue">
                  <h3 class="title cve-task-tile-header">
                    Repositories and Projects
                  </h3>
                  <ul class="tile-body cve-task-tile-list">
                    <li class="cve-task-tile-list-item">
                      <a href="https://github.com/CVEProject/CVE-Services" target="_blank">CVE Services Project</a>
                    </li>
                    <li class="cve-task-tile-list-item">
                      <a href="https://github.com/CVEProject/cvelistV5" target="_blank">CVE List Bulk Downloads Repository</a>
                    </li>
                    <li class="cve-task-tile-list-item">
                      <a href="https://github.com/CVEProject/automation-working-group/tree/master/cve_json_schema" target="_blank">
                        CVE JSON Schema Project</a>
                    </li>
                    <li class="cve-task-tile-list-item">
                      <a href="https://github.com/CVEProject/automation-working-group" target="_blank">AWG GitHub Repository</a>
                    </li>
                  </ul>
                </article>
              </div>
            </div>
            <h2 :id="cvenavs['Program Organization']['submenu']['Working Groups']['items']['CNA Coordination Working Group (CNACWG)']['anchorId']"
              class="title">
              {{cvenavs['Program Organization']['submenu']['Working Groups']['items']['CNA Coordination Working Group (CNACWG)']['label']}}
            </h2>
            <p>
              The CNACWG is focused on providing a forum for more effective communication and participation by the CVE Numbering Authorities (CNAs).
            </p>
            <p>
              <span class="has-text-weight-bold">Membership Eligibility:</span>
              Per the CNACWG Charter, “Membership to the working group is limited to representatives of currently active CVE Numbering Authorities
              (CNAs), as designated by the Secretariat. There is no limit to the number of representatives a given CNA may have as members of the
              working group.”
            </p>
            <div class="tile is-ancestor cve-task-tiles-container">
              <div class="tile is-parent cve-task-left-tile">
                <article class="tile is-child cve-border-dark-blue">
                  <h3 class="title cve-task-tile-header">
                    Documents
                  </h3>
                  <ul class="tile-body cve-task-tile-list">
                    <li class="cve-task-tile-list-item">
                      <router-link to="/Resources/Roles/WorkingGroups/CNACWG/CNACWG-Charter.pdf" target="_blank">
                        CNACWG Charter (PDF, 0.1MB)
                      </router-link>
                    </li>
                  </ul>
                </article>
              </div>
              <div class="tile is-parent">
                <article class="tile is-child cve-border-dark-blue">
                  <h3 class="title cve-task-tile-header">
                    Repositories and Projects
                  </h3>
                  <ul class="tile-body cve-task-tile-list">
                    <li class="cve-task-tile-list-item">
                      <a href="https://docs.google.com/forms/d/e/1FAIpQLSfCM4Lp4VQgmVQ81XJU1gByF9qkeU7xdQBQh_Upe-kDaNHfFg/viewform" target="_blank">
                        CNA Mentoring Program
                      </a>
                    </li>
                  </ul>
                </article>
              </div>
            </div>
            <h2 :id="cvenavs['Program Organization']['submenu']['Working Groups']['items']['Outreach and Communications Working Group (OCWG)']
            ['anchorId']" class="title">
              {{cvenavs['Program Organization']['submenu']['Working Groups']['items']['Outreach and Communications Working Group (OCWG)']['label']}}
            </h2>
            <p>
              The OCWG is focused on promoting the CVE Program to achieve program adoption and coverage goals through increased community awareness.
            </p>
            <p>
              <span class="has-text-weight-bold">Membership Eligibility:</span>
              Per the OCWG Charter, “Any active member of a CVE-authorized program may participate in the OCWG, including Board members,
              CVE Numbering Authority (CNA) representatives, as designated by the Secretariat, and participants from the Secretariat’s
              organization. The OCWG is also open to participation from the public at large.”
            </p>
            <div class="tile is-ancestor cve-task-tiles-container">
              <div class="tile is-parent cve-task-left-tile">
                <article class="tile is-child cve-border-dark-blue">
                  <h3 class="title cve-task-tile-header">
                    Documents
                  </h3>
                  <ul class="tile-body cve-task-tile-list">
                    <li class="cve-task-tile-list-item">
                      <router-link to="/Resources/Roles/WorkingGroups/OCWG/OCWG-Charter.pdf" target="_blank">OCWG Charter (PDF, 0.1MB)</router-link>
                    </li>
                  </ul>
                </article>
              </div>
              <div class="tile is-parent">
                <article class="tile is-child cve-border-dark-blue">
                  <h3 class="title cve-task-tile-header">
                    Repositories and Projects
                  </h3>
                  <ul class="tile-body cve-task-tile-list">
                    <li class="cve-task-tile-list-item">
                      <router-link to="/Media/News/Podcasts">Podcasts</router-link>
                    </li>
                    <li class="cve-task-tile-list-item">
                      <router-link to="/Media/News/Blogs">Blogs</router-link>
                    </li>
                  </ul>
                </article>
              </div>
            </div>
            <h2 :id="cvenavs['Program Organization']['submenu']['Working Groups']['items']['Quality Working Group (QWG)']['anchorId']"
              class="title">
              {{cvenavs['Program Organization']['submenu']['Working Groups']['items']['Quality Working Group (QWG)']['label']}}
            </h2>
            <p>
              The QWG is focused on identifying areas where CVE content, rules, guidelines, and best practices must improve to
              better support stakeholder use cases.
            </p>
            <p>
              <span class="has-text-weight-bold">Membership Eligibility:</span> Per the QWG Charter, “Any active, CVE authorized
              program member may participate on the QWG. This includes Board members, CVE Numbering Authority (CNA) representatives,
              [and] participants from the Secretariat’s organization. There is no limit to the number of representatives a given
              CNA may have as members of the working group. The QWG is not open to participation from the public at large. On a
              case by case basis, the QWG can decide through consensus to allow a non-CVE program member to participate in the QWG.
              Their membership will be reviewed annually.”
            </p>
            <div class="tile is-ancestor cve-task-tiles-container">
              <div class="tile is-parent cve-task-left-tile">
                <article class="tile is-child cve-border-dark-blue">
                  <h3 class="title cve-task-tile-header">
                    Documents
                  </h3>
                  <ul class="tile-body cve-task-tile-list">
                    <li class="cve-task-tile-list-item">
                      <a href="https://github.com/CVEProject/quality-workgroup/blob/main/README.md" target="_blank">QWG Charter</a>
                    </li>
                  </ul>
                </article>
              </div>
              <div class="tile is-parent">
                <article class="tile is-child cve-border-dark-blue">
                  <h3 class="title cve-task-tile-header">
                    Repositories and Projects
                  </h3>
                  <ul class="tile-body cve-task-tile-list">
                    <li class="cve-task-tile-list-item">
                      <a href="https://github.com/CVEProject/cve-schema" target="_blank">CVE JSON 5.0 Schema</a>
                    </li>
                    <li class="cve-task-tile-list-item">
                      <a href="https://github.com/CVEProject/quality-workgroup" target="_blank">QWG GitHub Repository</a>
                    </li>
                  </ul>
                </article>
              </div>
            </div>
            <h2 :id="cvenavs['Program Organization']['submenu']['Working Groups']['items']['Strategic Planning Working Group (SPWG)']['anchorId']"
            class="title">
              {{cvenavs['Program Organization']['submenu']['Working Groups']['items']['Strategic Planning Working Group (SPWG)']['label']}}
            </h2>
            <p>
              The SPWG is focused on the long-term strategy (1-5 years) and goals of the CVE Program; will work closely with the
              <router-link to="/ProgramOrganization/Board">CVE Board</router-link> to determine goals and objectives and will act to achieve them.
            </p>
            <p>
              <span class="has-text-weight-bold">Membership Eligibility:</span> Per the SPWG Charter, “Any active, CVE authorized
              program member may participate on the SPWG. This includes Board members, CVE Numbering Authority (CNA) representatives,
              [and] participants from the Secretariat’s organization. There is no limit to the number of representatives a given CNA
              may have as members of the working group. The SPWG is not open to participation from the public at large. On a
              case-by-case basis, the SPWG can decide through consensus to allow a non-CVE Program member to participate in the SPWG.
              Their membership will be reviewed annually.”
            </p>
            <div class="tile is-ancestor cve-task-tiles-container">
              <div class="tile is-parent cve-task-left-tile">
                <article class="tile is-child cve-border-dark-blue">
                  <h3 class="title cve-task-tile-header">
                    Documents
                  </h3>
                  <ul class="tile-body cve-task-tile-list">
                    <li class="cve-task-tile-list-item">
                      <router-link to="/Resources/Roles/WorkingGroups/SPWG/SPWG-Charter.pdf" target="_blank">SPWG Charter (PDF, 0.2MB)</router-link>
                    </li>
                  </ul>
                </article>
              </div>
              <div class="tile is-parent">
                <article class="tile is-child cve-border-dark-blue">
                  <h3 class="title cve-task-tile-header">
                    Repositories and Projects
                  </h3>
                  <ul class="tile-body cve-task-tile-list">
                    <li class="cve-task-tile-list-item">
                      <a href="https://github.com/CVEProject/strategic-planning-working-group" target="_blank">SPWG GitHub Repository</a>
                    </li>
                  </ul>
                </article>
              </div>
            </div>
            <h2 :id="cvenavs['Program Organization']['submenu']['Working Groups']['items']['Tactical Working Group (TWG)']['anchorId']"
            class="title">
              {{cvenavs['Program Organization']['submenu']['Working Groups']['items']['Tactical Working Group (TWG)']['label']}}
            </h2>
            <p>
              The TWG is focused on managing, coordinating, and expediting CVE Program activities, communications, and processes for the benefit of
              program stakeholders.
            </p>
            <p>
              <span class="has-text-weight-bold">Membership Eligibility:</span> Membership is limited to active CVE Board members, CVE Working Groups
              chairs, and participants from the Secretariat’s organization.
            </p>
            <div class="tile is-ancestor cve-task-tiles-container">
              <div class="tile is-parent cve-task-left-tile">
                <article class="tile is-child cve-border-dark-blue">
                  <h3 class="title cve-task-tile-header">
                    Documents
                  </h3>
                  <ul class="tile-body cve-task-tile-list">
                    <li class="cve-task-tile-list-item">
                      TWG Charter - TBA
                    </li>
                  </ul>
                </article>
              </div>
              <div class="tile is-parent">
                <article class="tile is-child cve-border-dark-blue">
                  <h3 class="title cve-task-tile-header">
                    Repositories and Projects
                  </h3>
                  <ul class="tile-body cve-task-tile-list">
                    <li class="cve-task-tile-list-item">
                      <a href="https://github.com/CVEProject/Ideas" target="_blank">
                         CVE Program Ideas Repository
                      </a>
                    </li>
                  </ul>
                </article>
              </div>
            </div>
            <h2 :id="cvenavs['Program Organization']['submenu']['Working Groups']['items']
              ['Vulnerability Conference and Events Working Group (VCEWG)']['anchorId']"
              class="title">
              {{cvenavs['Program Organization']['submenu']['Working Groups']['items']['Vulnerability Conference and Events Working Group (VCEWG)']
              ['label']}}
            </h2>
            <p>
              The VCEWG was created to provide a forum for conference planning. VCEWG activities and discussions focus on planning for a
              comprehensive Vulnerability Conference being held in early 2024. The conference intends to bring together entities from across the
              vulnerability management space, including the CVE Program, NIST/NVD, CISA, FIRST, and others. While established for this specific
              conference, it is expected this will be a permanent WG that will focus on CVE Vulnerability related events.
            </p>
            <p>
              <span class="has-text-weight-bold">Membership Eligibility:</span> Per the VCEWG Charter, “Any active CVE-authorized program member
              may participate in the VCEWG. This includes CVE Board members, CVE Numbering Authority (CNA) representatives, Authorized Data Publishers
              (ADP), and participants from the Secretariat’s organization. This WG is also open to public membership, including members of
              corporate vulnerability management programs, as well as VM related standards, initiatives and associations, such as CVSS, CSAF,
              NIST NVD, and others.”
            </p>
            <div class="tile is-ancestor cve-task-tiles-container">
              <div class="tile is-parent cve-task-left-tile">
                <article class="tile is-child cve-border-dark-blue">
                  <h3 class="title cve-task-tile-header">
                    Documents
                  </h3>
                  <ul class="tile-body cve-task-tile-list">
                    <li class="cve-task-tile-list-item">
                      <router-link to="/Resources/Roles/WorkingGroups/VCEWG/VCEWG-Charter.pdf" target="_blank">
                        VCEWG Charter (PDF, 0.2MB)
                      </router-link>
                    </li>
                  </ul>
                </article>
              </div>
              <div class="tile is-parent">
                <article class="tile is-child cve-border-dark-blue">
                  <h3 class="title cve-task-tile-header">
                    Repositories and Projects
                  </h3>
                  <ul class="tile-body cve-task-tile-list">
                    <li class="cve-task-tile-list-item">TBA</li>
                  </ul>
                </article>
              </div>
            </div>
            <h2 :id="cvenavs['Program Organization']['submenu']['Working Groups']['items']['Meeting Schedule']['anchorId']"
              class="title">
              {{cvenavs['Program Organization']['submenu']['Working Groups']['items']['Meeting Schedule']['label']}}
            </h2>
            <div class="table-container">
            <table class="table cve-border-dark-blue is-striped is-hoverable cve-table cve-border-bottom-unset">
              <thead id="cve-meeting-table-head">
                <tr>
                  <th scope="col" style="width: 55%">Working Group</th>
                  <th scope="col">Meeting Time</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-label="Working Group" style="width: 55%">Automation Working Group (AWG)</td>
                  <td data-label="Meeting Time">Every Tuesday 4:00pm ET</td>
                </tr>
                <tr>
                  <td data-label="Working Group" style="width: 55%">CNA Coordination Working Group (CNACWG)</td>
                  <td data-label="Meeting Time">Every other Wednesday 2:00pm ET</td>
                </tr>
                <tr>
                  <td data-label="Working Group" style="width: 55%">Outreach and Communications Working Group (OCWG)</td>
                  <td data-label="Meeting Time">Monthly on Wednesday 9:00am ET</td>
                </tr>
                <tr>
                  <td data-label="Working Group" style="width: 55%">Quality Working Group (QWG)</td>
                  <td data-label="Meeting Time">Every other Thursday 4:00pm ET</td>
                </tr>
                <tr>
                  <td data-label="Working Group" style="width: 55%">Strategic Planning Working Group (SPWG)</td>
                  <td data-label="Meeting Time">Every Wednesday 4:00pm ET</td>
                </tr>
                <tr>
                  <td data-label="Working Group" style="width: 55%">Tactical Working Group (TWG)</td>
                  <td data-label="Meeting Time">Every Thursday 9:00am ET</td>
                </tr>
                <tr>
                  <td data-label="Working Group" style="width: 55%">Vulnerability Conference and Events Working Group (VCEWG)</td>
                  <td data-label="Meeting Time">TBA</td>
                </tr>
              </tbody>
            </table>
            </div> <!-- end table container -->
          </div> <!-- end content -->
        </main>
        <SurveyLinkComponent :surveyLink="cvenavs['Program Organization']['submenu']['Working Groups']['surveyLink']" />
      </div>
      <div class="column is-3 is-hidden-touch">
        <NavigationSidebar :nav="cvenavs['Program Organization']" />
      </div>
    </div>
  </div>
</template>

<script>
import NavigationSidebar from '@/components/NavigationSidebar.vue';
import SurveyLinkComponent from '@/components/SurveyLinkComponent.vue';

export default {
  name: 'WorkingGroups',
  components: {
    NavigationSidebar,
    SurveyLinkComponent,
  },
  props: {
    cvenavs: {
      type: Object,
      required: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../assets/style/globals.scss';
@import '../../assets/style/elements/cveTableStacked.scss';
</style>

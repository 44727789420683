<template>
  <aside class="menu cve-sidebar-menu mt-3" :class="isNavSidebarHiddenTouch ? 'is-hidden-touch' : ''">
    <p class="menu-label cve-capitalize-first-letter">{{nav.label}}</p>
    <ul class="menu-list cve-capitalize-first-letter">
      <li v-for="(submenuObj, submenuLabel) in nav.submenu" :key="submenuObj.id">
        <span class="is-flex" style="justify-content: space-between; width: inherit">
          <router-link v-if="submenuObj.hasOwnProperty('path')" :to="$sanitize(getAdjustedPath(submenuObj.path))">
            {{ submenuLabel }}
          </router-link>
          <span v-else class="cve-menu-shift">
            {{ submenuLabel }}
            <li v-for="(subsubmenuObj, subsubmenuLabel) in submenuObj.submenu" :key="subsubmenuObj.id">
              <span class="is-flex" style="justify-content: space-between; width: inherit">
                <router-link :to="$sanitize(getAdjustedPath(subsubmenuObj.path))">{{ subsubmenuLabel }}</router-link>
              </span>
            </li>
          </span>
          <button class="button is-text cve-button-submenu-toggle"
            @click="submenuObj.hasOwnProperty('items') && toggleSubmenuItem(`/${nav.path}/${submenuObj.path}`)"
            :class="{'is-hidden': !submenuObj.hasOwnProperty('items')}"
            :aria-expanded="selectedSubmenu.includes(`/${nav.path}/${submenuObj.path}`) ? 'true' : 'false'"
            :aria-controls="submenuObj.id + 'nav'">
            <span class="icon cve-icon-xxs">
              <p :id="'navExpandCollapseAltText' + submenuObj.id" class="is-hidden">
                {{!selectedSubmenu.includes(`/${nav.path}/${submenuObj.path}`) ? 'collapse' : 'expand'}}
              </p>
              <font-awesome-icon class="icon" :icon="selectedSubmenu.includes(`/${nav.path}/${submenuObj.path}`) ? 'minus' : 'plus'"
                aria-hidden="false" focusable="true" :aria-labelledby="'navExpandCollapseAltText' + submenuObj.id"/>
            </span>
          </button>
        </span>
        <ul v-show="selectedSubmenu.includes(`/${nav.path}/${submenuObj.path}`)" :id="submenuObj.id + 'nav'">
          <li v-for="(submenuItem, label) in submenuObj.items" :key="submenuObj.id + label">
            <router-link :to="$sanitize(submenuObj.path) + '#' + submenuItem.anchorId" >{{ submenuItem.label }}</router-link>
          </li>
        </ul>
    </li>
    </ul>
  </aside>
</template>

<script>
export default {
  props: {
    nav: {
      type: Object,
      required: true,
    },
    isNavSidebarHiddenTouch: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      selectedSubmenu: [this.$router.currentRoute.path],
    };
  },
  methods: {
    toggleSubmenuItem(path) {
      if (this.selectedSubmenu.includes(path)) {
        const index = this.selectedSubmenu.indexOf(path);
        this.selectedSubmenu.splice(index, 1);
      } else {
        this.selectedSubmenu.push(path);
      }
    },
    getAdjustedPath(path) {
      return (this.$router.currentRoute.path.includes('/Media/News/item')) ? `/Media/News/${path}` : path;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/assets/style/globals.scss';

.cve-menu-shift {
  font-weight: bold;
  padding-left: 12px;
}

a.router-link-exact-active{
  background: $theme-color-accent-cool-light;
}

</style>

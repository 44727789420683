<template>
  <div id="cve-secondary-page-main-container">
    <div class="columns is-centered">
      <div class="column is-12-desktop is-12-tablet cve-main-column-content-width">
        <main id="cve-main-page-content" role="main">
          <div class="content">
            <h1 class="title">Email Newsletter Sign Up</h1>
            <h2 class="title">Subscribe to the CVE<sup>®</sup> Program Newsletter</h2>
            <p class="content">
              <span class="has-text-weight-bold">CVE Announce</span> provides general news about the CVE Program such as new CNAs, new
              website features, events, etc. Messages are sent infrequently, once a month or less.
            </p>
            <div v-if="!submitted" class="cve-white-bg-gray-border-container">
              <form id="emailSignup" class="cve-top-margin" @submit.prevent="checkForm" novalidate="true">
                <div v-if="errors.length" class="notification is-danger">
                  <p id="dangerIcon" class="is-hidden">danger</p>
                  <font-awesome-icon style="flex: 0 0 40px;" size="1x"  icon="exclamation-circle" role="danger"
                   aria-labelledby="dangerIcon" aria-hidden="false" />
                  <span class="has-text-weight-bold"> Please correct the following error(s):</span>
                  <ul>
                    <li v-for="error in errors" :key=error>{{ error }}</li>
                  </ul>
                </div>
                <div class="field is-horizontal">
                  <label class="field-label cve-field-label">Email Address <span class="has-text-weight-normal">(Required)</span></label>
                  <div class="field-body control">
                    <input v-model.trim="userInfo.emailAddress" class="input" type="email" placeholder="Enter email address">
                  </div>
                </div> <!-- end of field -->
                <div class="field is-horizontal">
                  <label class="field-label cve-field-label">
                    What is {{ firstRandomNum }}  + {{ secondRandomNum }}? <span class="has-text-weight-normal">(Required)</span>
                  </label>
                  <div class="field-body control">
                    <input v-model="mathAnswerResponse" class="input" type="number"
                    :placeholder="`Enter sum of ${firstRandomNum} + ${secondRandomNum}`">
                  </div>
                </div> <!-- end of field -->
                <div class="field is-horizontal">
                  <label class="field-label cve-field-label">Job Function</label>
                  <div class="field-body control">
                    <select v-model="userInfo.jobFunction">
                      <option v-for="job in jobFunctionOptions" :key="job.id" :value="job.value" :disabled="job.disabled" :selected="job.selected">
                        {{ job.label }}</option>
                    </select>
                  </div>
                </div> <!-- end of field -->
                <div class="field is-horizontal">
                  <label class="field-label cve-field-label">Type of Organization</label>
                  <div class="field-body control">
                    <select v-model="userInfo.orgType">
                      <option v-for="orgType in orgTypeOptions" :key="orgType.id" :value="orgType.value" :disabled="orgType.disabled"
                        :selected="orgType.selected">{{ orgType.label }}</option>
                    </select>
                  </div>
                </div> <!-- end of field -->
                <div class="field is-horizontal">
                  <label class="field-label cve-field-label">Organization</label>
                  <div class="field-body control">
                    <input v-model.trim="userInfo.organization" class="input" type="text" placeholder="Organization name">
                  </div>
                </div> <!-- end of field -->
                <div class="field is-horizontal">
                  <label class="field-label cve-field-label">Country</label>
                  <div class="field-body control">
                    <input v-model.trim="userInfo.country" class="input" type="text" placeholder="Country">
                  </div>
                </div> <!-- end of field -->
                <div class="field is-horizontal">
                  <label class="field-label cve-field-label"></label>
                  <div class="field-body control">
                    <input type="submit" value="Subscribe" class="button cve-button cve-base-color">
                  </div>
                </div> <!-- end of field -->
              </form>
            </div> <!-- end of container -->
            <div v-else>
              <div class="cve-loading-message has-text-centered" v-if="isLoading">
              <span class="icon-text">
                <span class="icon">
                  <span class="loader is-loading"/>
                </span>
                <span>Please wait while we process your request...</span>
              </span>
            </div>
              <div v-else>
                <div v-if="subscribed" class="notification is-success">
                  <h2 class="cve-top-margin">
                    <p id="successIcon" class="is-hidden">success</p>
                    <font-awesome-icon style="flex: 0 0 40px;" size="1x"  icon="check-circle" role="success"
                    aria-labelledby="successIcon" aria-hidden="false" />
                    Success! You've been subscribed.
                  </h2>
                </div>
                <div v-if="!subscribed" class="notification is-danger">
                  <h2 class="cve-top-margin">
                    <p id="successIcon" class="is-hidden">success</p>
                    <font-awesome-icon style="flex: 0 0 40px;" size="1x"  icon="check-circle" role="danger"
                      aria-labelledby="successIcon" aria-hidden="false" />
                    Service is currently unavailable.
                  </h2>
                  <p>Please
                    <span class="icon-text">
                      <a href="https://cveform.mitre.org/" target="_blank">report the issue
                        <span class="icon is-size-7 cve-icon-xxs">
                          <p id="extenalLink1" class="is-hidden">external site</p>
                          <font-awesome-icon icon="external-link-alt" aria-labelledby="extenalLink1" aria-hidden="false" focusable="false"/>
                        </span>
                      </a>
                    </span>
                    and try again later. Sorry for the inconvenience.
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-2">View our <router-link to=/Legal/PrivacyPolicy>Privacy Policy</router-link></div>
          </div>
          <h2 class="title">How to Unsubscribe</h2>
          <p>
            <a href="mailto:lms@mitre.org?Subject=signoff%20cve-announce-list">Click here</a> to send email to
            <span class="has-text-weight-bold">lms@mitre.org</span> with
              the subject: "<span class="has-text-weight-bold">signoff cve-announce-list</span>". Alternatively, instructions on how to unsubscribe
              appear at the bottom of each newsletter.
          </p>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'NewsletterSignup',
  components: {
  },
  data() {
    return {
      errors: [],
      isLoading: false,
      submitted: false,
      subscribed: false,
      firstRandomNum: undefined,
      secondRandomNum: undefined,
      mathAnswer: undefined,
      mathAnswerResponse: undefined,
      userInfo: {
        emailAddress: null,
        jobFunction: '',
        orgType: '',
        organization: '',
        country: '',
      },
      jobFunctionOptions: [
        {
          value: '',
          label: '-- Select an option --',
          disabled: true,
          selected: true,
        },
        {
          value: 'Corporate/IS Management',
          label: 'Corporate/IS Management',
        },
        {
          value: 'Information/Data/Computer Security Staff',
          label: 'Information/Data/Computer Security Staff',
        },
        {
          value: 'Network/Communications Security Staff',
          label: 'Network/Communications Security Staff',
        },
        {
          value: 'IS Software Development',
          label: 'IS Software Development',
        },
        {
          value: 'Web Applications Development',
          label: 'Web Applications Development',
        },
        {
          value: 'Web Content/Design Development',
          label: 'Web Content/Design Development',
        },
        {
          value: 'Webmaster',
          label: 'Webmaster',
        },
        {
          value: 'System or Network Administrator',
          label: 'System or Network Administrator',
        },
        {
          value: 'Disaster Recovery/Business Continuity',
          label: 'Disaster Recovery/Business Continuity',
        },
        {
          value: 'Consultant (corporate or independent)',
          label: 'Consultant (corporate or independent)',
        },
        {
          value: 'Corporate/General Business Management',
          label: 'Corporate/General Business Management',
        },
        {
          value: 'Administrative Staff or Management',
          label: 'Administrative Staff or Management',
        },
        {
          value: 'Audit',
          label: 'Audit',
        },
        {
          value: 'Financial',
          label: 'Financial',
        },
        {
          value: 'Legal',
          label: 'Legal',
        },
        {
          value: 'Marketing',
          label: 'Marketing',
        },
        {
          value: 'Sales',
          label: 'Sales',
        },
        {
          value: 'Engineer/Scientist',
          label: 'Engineer/Scientist',
        },
        {
          value: 'Student',
          label: 'Student',
        },
        {
          value: 'Other',
          label: 'Other',
        },
      ],
      orgTypeOptions: [
        {
          value: '',
          label: '-- Select an option --',
          disabled: true,
          selected: true,
        },
        {
          value: 'Commercial vendor of security products or services',
          label: 'Commercial vendor of security products or services',
        },
        {
          value: 'Freeware vendor of security products or services',
          label: 'Freeware vendor of security products or services',
        },
        {
          value: 'Other commercial software vendor',
          label: 'Other commercial software vendor',
        },
        {
          value: 'Other freeware software vendor',
          label: 'Other freeware software vendor',
        },
        {
          value: 'Communication Carriers (ISP, Telecomm, Data Comm, TV/Cable)',
          label: 'Communication Carriers (ISP, Telecomm, Data Comm, TV/Cable)',
        },
        {
          value: 'Computer/Internet Related Services',
          label: 'Computer/Internet Related Services',
        },
        {
          value: 'Computer and Networking Manufacturing',
          label: 'Computer and Networking Manufacturing',
        },
        {
          value: 'VAR/OEM/Reseller',
          label: 'VAR/OEM/Reseller',
        },
        {
          value: 'Retailer/Wholesaler/Distributor',
          label: 'Retailer/Wholesaler/Distributor',
        },
        {
          value: 'Manufacturing (non-computer industry)',
          label: 'Manufacturing (non-computer industry)',
        },
        {
          value: 'Finance/Banking/Accounting/Insurance/Securities',
          label: 'Finance/Banking/Accounting/Insurance/Securities',
        },
        {
          value: 'Insurance/Legal/Real Estate',
          label: 'Insurance/Legal/Real Estate',
        },
        {
          value: 'Government - includes federal, state and municipal',
          label: 'Government - includes federal, state and municipal',
        },
        {
          value: 'Military',
          label: 'Military',
        },
        {
          value: 'Education - includes colleges, universities and other',
          label: 'Education - includes colleges, universities and other',
        },
        {
          value: 'Health/Medical/Dental',
          label: 'Health/Medical/Dental',
        },
        {
          value: 'Aerospace/Transportation',
          label: 'Aerospace/Transportation',
        },
        {
          value: 'Mining/Oil/Gas/Chemicals',
          label: 'Mining/Oil/Gas/Chemicals',
        },
        {
          value: 'Agriculture/Forestry/Fisheries',
          label: 'Agriculture/Forestry/Fisheries',
        },
        {
          value: 'Utilities - includes electric, gas and water',
          label: 'Utilities - includes electric, gas and water',
        },
        {
          value: 'Architecture/Construction/Engineering',
          label: 'Architecture/Construction/Engineering',
        },
        {
          value: 'Travel/Hospitality/Recreation',
          label: 'Travel/Hospitality/Recreation',
        },
        {
          value: 'Nonprofit/Religious',
          label: 'Nonprofit/Religious',
        },
        {
          value: 'Non-Profit/Trade Associations',
          label: 'Non-Profit/Trade Associations',
        },
        {
          value: 'Consulting/Research/Development Lab',
          label: 'Consulting/Research/Development Lab',
        },
        {
          value: 'Media/Marketing/Advertising/Entertainment',
          label: 'Media/Marketing/Advertising/Entertainment',
        },
        {
          value: 'Student',
          label: 'Student',
        },
        {
          value: 'Self-employed',
          label: 'Self-employed',
        },
        {
          value: 'Other',
          label: 'Other',
        },
      ],
    };
  },
  methods: {
    generateMathQuestion() {
      this.firstRandomNum = Math.floor(Math.random() * 9);
      this.secondRandomNum = Math.floor(Math.random() * 9);

      this.mathAnswer = this.firstRandomNum + this.secondRandomNum;
    },
    checkForm() {
      this.errors = [];
      if (!this.userInfo.emailAddress) {
        this.errors.push('Email required.');
      } else if (!this.validEmail(this.userInfo.emailAddress)) {
        this.errors.push('Valid email required.');
      }

      if (!this.mathAnswerResponse) {
        this.errors.push('Response to math question is required');
      } else if (this.mathAnswerResponse !== this.mathAnswer.toString()) {
        this.errors.push('Math answer is incorrect. Try again.');
      }

      if (!this.errors.length) {
        this.subscribe();
      }
    },
    validEmail(email) {
      const re = new RegExp(`${
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))/.source
      }${/@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.source}`);
      return re.test(email);
    },
    subscribe() {
      this.submitted = true;
      this.isLoading = true;
      this.subscribed = false;

      const instance = axios.create({
        baseURL: this.$store.state.API_BASE,
      });

      instance.post('restapi/newsletter', this.userInfo)
        .then((res) => {
          if (res.status === 200) {
            this.subscribed = true;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  created() {
    this.generateMathQuestion();
  },
};

</script>
<style scoped lang="scss">
@import '@/assets/style/globals.scss';

.cve-field-label {
  font-weight: bold;
}

.cve-top-margin {
  margin-top: 20px;
}
</style>

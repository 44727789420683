import Vue from 'vue';

import SanitizeHTML from 'sanitize-html';
import VueGtag from 'vue-gtag';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTwitter, faGithub, faLinkedin, faYoutube, faMedium, faLeanpub, faReadme, faMastodon,
} from '@fortawesome/free-brands-svg-icons';
import {
  faAngleLeft, faAngleRight, faArrowRight, faBlog, faCaretDown, faCaretUp, faInfoCircle, faCheckCircle, faExclamationCircle,
  faExclamationTriangle, faExternalLinkAlt, faLink, faMinus, faPassport, faPlus, faPodcast, faIdCard, faSearch, faHandshake, faUsersCog,
  faLaptopCode, faPoll, faTimes, faToolbox, faSitemap, faUser, faUserShield, faBullhorn, faWindowMaximize,
} from '@fortawesome/free-solid-svg-icons';
import { faArrowAltCircleRight, faClipboard, faNewspaper } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import Buefy from 'buefy';
import store from '@/store';
import router from '@/router';
import App from '@/App.vue';
import ExternalLinkMessage from '@/components/ExternalLinkMessage.vue';

library.add(faAngleLeft, faAngleRight, faArrowRight, faBlog, faCaretDown, faCaretUp, faInfoCircle, faCheckCircle, faExclamationCircle,
  faExclamationTriangle, faExternalLinkAlt, faLink, faMinus, faPassport, faPlus, faPodcast, faIdCard, faSearch, faHandshake, faUsersCog, faLaptopCode,
  faTimes, faToolbox, faSitemap, faUser, faUserShield, faTwitter, faGithub, faLinkedin, faYoutube, faMedium, faLeanpub, faArrowAltCircleRight,
  faClipboard, faNewspaper, faBullhorn, faWindowMaximize, faReadme, faPoll, faMastodon);

Vue.prototype.$sanitize = SanitizeHTML;
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(VueGtag, {
  config: { id: 'G-YNQP3SNVSD' },
  appName: 'CVE Website',
}, router);

Vue.use(Buefy, {
  defaultIconComponent: 'font-awesome-icon',
  defaultIconPack: 'fas',
  customIconPacks: {
    fas: {
      sizes: {
        default: 'lg',
        'is-small': '1x',
        'is-medium': '2x',
        'is-large': '3x',
      },
      iconPrefix: '',
    },
  },
});

Vue.config.productionTip = false;

Vue.component('ExternalLinkMessage', ExternalLinkMessage);

new Vue({
  router,
  store,
  beforeCreate() { this.$store.commit('initializeStore'); },
  render: (h) => h(App),
}).$mount('#app');

<template>
  <aside class="column is-3">
    <div class="content">
      <h3 class="title cve-news-title-padding">
        <router-link class="has-text-black" to="/Media/News/AllNews">News</router-link>
      </h3>
      <div>
        <table class="table is-narrow" role="presentation">
          <tbody>
            <tr v-for="newsItem in topFourNewsItem" :key="newsItem.id">
              <td style="width: 10%">
                <span class="icon">
                  <p :id="`blogIcon-${newsItem.id}`" class="is-hidden">blog</p>
                  <font-awesome-icon size="1x" icon="blog"  v-if="newsItem.newsType == 'blog'"
                    :aria-labelledby="`blogIcon-${newsItem.id}`" aria-hidden="false"/>
                  <p :id="`podcastIcon-${newsItem.id}`" class="is-hidden">podcast</p>
                  <font-awesome-icon size="1x" icon="podcast"  v-if="newsItem.newsType == 'podcast'"
                    :aria-labelledby="`podcastIcon-${newsItem.id}`" aria-hidden="false"/>
                  <p :id="`newsIcon-${newsItem.id}`" class="is-hidden">news</p>
                  <font-awesome-icon size="1x" :icon="['far', 'newspaper']"  v-if="newsItem.newsType == 'news'"
                    :aria-labelledby="`newsIcon-${newsItem.id}`" aria-hidden="false"/>
                  <p :id="`tweetIcon-${newsItem.id}`" class="is-hidden">tweet</p>
                  <font-awesome-icon size="1x" :icon="['fab', 'twitter']"  v-if="newsItem.newsType == 'tweet'"
                    :aria-labelledby="`tweetIcon-${newsItem.id}`" aria-hidden="false"/>
                  <font-awesome-icon size="1x" icon="bullhorn" v-if="newsItem.newsType == 'press-release'"
                    :aria-labelledby="`pressReleaseIcon${newsItem.id}`" aria-hidden="false"/>
                </span>
              </td>
              <td>
                <router-link :to="`/Media/News/item/${$sanitize(newsItem.newsType)}/${newsItem.itemUrl}`" v-html="$sanitize(newsItem.title)">
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="has-text-right" style="margin-top: -24px;">
          <button class="button is-ghost cve-is-borderless has-text-weight-bold has-text-grey-dark" @click="toggleNewsIconsMap">
            <span class="icon-text is-uppercase is-size-7" >
              news icons
              <font-awesome-icon size="1x" icon="info-circle" />
            </span>
          </button>
        </div>
        <div class="level" :class="{'cve-display-none': hideNewsIconsMap}">
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Blog</p>
              <span class="icon">
                <p id="blogIconDesc" class="is-hidden">blog icon</p>
                <font-awesome-icon size="1x" class="cve-news-icon" icon="blog"
                  aria-labelledby="blogIconDesc" aria-hidden="false"/>
              </span>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Podcast</p>
              <span class="icon">
                <p id="podcastIconDesc" class="is-hidden">podcast icon</p>
                <font-awesome-icon size="1x" class="cve-news-icon" icon="podcast"
                  aria-labelledby="podcastIconDesc" aria-hidden="false"/>
              </span>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">News</p>
              <span class="icon">
                <p id="newsIconDesc" class="is-hidden">news icon</p>
                <font-awesome-icon class="cve-news-icon" :icon="['far', 'newspaper']"
                  aria-labelledby="newsIconDesc" aria-hidden="false"/>
              </span>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Press Release</p>
              <span class="icon">
                <p id="pressReleaseIconDesc" class="is-hidden">press release icon</p>
                <font-awesome-icon size="1x" class="cve-press-release-icon" icon="bullhorn"
                  aria-labelledby="pressReleaseIconDesc" aria-hidden="false"/>
              </span>
            </div>
          </div>
        </div>

      <div class="cve-expansion-bar">
        <router-link class="cve-expansion-button" to="/Media/News/AllNews">More news</router-link>
      </div>
      </div>
      <h3 class="title cve-events-title-padding">
        <router-link class="has-text-black" to="/Media/Events">Events</router-link>
      </h3>
      <table class="table is-narrow" role="presentation">
        <tbody>
          <tr v-for="event in topFourEvents" :key="event.id">
            <td style="width: 10%">
              <span class="icon">
                <p :id="`publicOrPrivate-${event.id}`" class="is-hidden"> {{(event.permission == 'public') ?
                  'public event' : 'private event'}} </p>
                <font-awesome-icon class="cve-news-icon" :icon="event.permission == 'public' ? 'passport' : 'id-card'"
                  :aria-labelledby="`publicOrPrivate-${event.id}`" aria-hidden="false"/>
              </span>
            </td>
            <td>
              <p class="">{{event.title}}</p>
              <p class="is-capitalized cve-help-text">
                <span v-if="event.date.repeat">
                  <span v-if="event.date.repeat.recurrence == 'monthly'">Monthly on {{event.date.repeat.day}}</span>
                  <span v-else-if="event.date.repeat.recurrence == 'biweekly'">Every other {{event.date.repeat.day}}</span>
                  <span v-else>Every {{event.date.repeat.day}}</span>
                </span>
                <span v-else>
                  {{formatDate(event.date.start)}} {{(event.date.start !== event.date.end) ? '— ' + formatDate(event.date.end) : ''}}
                </span>
                <span> | {{event.location}}</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="has-text-right" style="margin-top: -16px;">
        <button class="button is-ghost cve-is-borderless has-text-weight-bold has-text-grey-dark" @click="toggleEventIconsMap">
          <span class="icon-text is-uppercase is-size-7 has-text-grey-dark" >
            event icons
            <font-awesome-icon size="1x" icon="info-circle" />
          </span>
        </button>
      </div>
      <div class="level" :class="{'cve-display-none': hideEventIconsMap}">
          <div class="level-item has-text-centered">
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Public</p>
              <span class="icon">
                <p id="publicIconDesc" class="is-hidden">public event icon</p>
                <font-awesome-icon class="cve-news-icon" icon="passport" aria-labelledby="publicIconDesc" aria-hidden="false"/>
              </span>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Private</p>
              <span class="icon">
                <p id="privateIconDesc" class="is-hidden">private event icon</p>
                <font-awesome-icon class="cve-news-icon" icon="id-card" aria-labelledby="privateIconDesc" aria-hidden="false"/>
              </span>
            </div>
          </div>
          <div class="level-item has-text-centered">
          </div>
        </div>
      <div class="cve-expansion-bar">
          <router-link class="cve-expansion-button" to="/Media/Events">More Events</router-link>
      </div>
    </div>
  </aside>
</template>

<script>
import newsData from '../assets/data/news.json';
import eventsData from '../assets/data/events.json';
import newsMixins from '../mixins/newsMixins';

export default {
  name: 'HomePageSidebar',
  data() {
    return {
      hideNewsIconsMap: true,
      hideEventIconsMap: true,
      newsList: newsData.currentNews,
      eventsList: eventsData.currentEvents,
      topFourNews: [],
    };
  },
  mixins: [newsMixins],
  computed: {
    topFourNewsItem() {
      let orderedNews = []; const manuallyOrderedNews = []; let
        dateOrderedNews = [];
      // first, get the manually assigned news item to display on homepage
      this.newsList.forEach((newsItem) => {
        if (Object.prototype.hasOwnProperty.call(newsItem, 'displayOnHomepageOrder')) {
          manuallyOrderedNews[newsItem.displayOnHomepageOrder - 1] = newsItem;
        } else {
          dateOrderedNews.push(newsItem);
        }
      });

      function orderByDate(a, b) {
        if (a.value > b.value) {
          return 1;
        }
        if (a.value < b.value) {
          return -1;
        }
        return 0;
      }
      // then order all the items by dat
      dateOrderedNews = dateOrderedNews.sort(orderByDate);

      if ((manuallyOrderedNews.length > 0) && (manuallyOrderedNews.length <= 4)) {
        const remainingNumofNews = 4 - manuallyOrderedNews.length;
        if (remainingNumofNews <= 3) {
          orderedNews = manuallyOrderedNews.concat(dateOrderedNews.splice(0, remainingNumofNews));
        } else {
          orderedNews = manuallyOrderedNews;
        }
      } else {
        orderedNews = dateOrderedNews.splice(0, 4);
      }

      orderedNews = orderedNews.map((newsItem) => ({ ...newsItem, itemUrl: this.createItemUrlDetail(newsItem) }));

      return orderedNews;
    },
    topFourEvents() {
      // make sure hours are set to 0 so an event today is in the top four
      const today = new Date(new Date().setHours(0, 0, 0, 0));
      const pinnedEvents = [];
      const recurringEvents = [];
      const otherEvents = [];
      this.eventsList.forEach((event) => {
        if (event?.displayOnHomepageOrder) {
          pinnedEvents[event.displayOnHomepageOrder - 1] = event;
        } else if (event.date.repeat) {
          recurringEvents.push(event);
        } else if (this.isUpcomingEvent(event.date, today)) {
          otherEvents.push(event);
        }
      });

      if (otherEvents.length > 3) {
        const recentFour = otherEvents.splice(0, 4);
        return this.sortDate(recentFour);
      }
      const endIndex = 4 - otherEvents.length;
      const sortedPinnedEvents = this.sortDate(pinnedEvents);
      const sortedOtherEvents = this.sortDate(otherEvents);
      const sortedReccuringEvents = this.sortReccuringEvents(recurringEvents);
      // return sortedOtherEvents.concat(sortedReccuringEvents.splice(0, endIndex));
      return [].concat(sortedPinnedEvents, sortedReccuringEvents, sortedOtherEvents).splice(0, endIndex);
    },
  },
  created() {
  },
  methods: {
    toggleNewsIconsMap() {
      this.hideNewsIconsMap = !this.hideNewsIconsMap;
    },
    toggleEventIconsMap() {
      this.hideEventIconsMap = !this.hideEventIconsMap;
    },
    isUpcomingEvent(eventDate, today) {
      let isWithin30days = false;
      const eventStart = eventDate.start.length > 0 ? eventDate.start.split('-') : today;
      const eventEnd = eventDate.end.length > 0 ? eventDate.end.split('-') : today;
      const eventStartDate = new Date(eventStart[0], eventStart[1] - 1, eventStart[2]);
      const eventEndDate = new Date(eventEnd[0], eventEnd[1] - 1, eventEnd[2]);

      const daysFromStartDate = (eventStartDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24);
      const daysFromEndDate = (eventEndDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24);
      if (((daysFromStartDate >= 0) && (daysFromStartDate < 30)) || ((daysFromEndDate >= 0) && (daysFromEndDate < 30))) isWithin30days = true;

      return isWithin30days;
    },
    sortDate(dates) {
      const sortedEvents = dates.sort((a, b) => {
        const date1 = new Date(a.date.start);
        const date2 = new Date(b.date.start);
        return date1 - date2;
      });

      return sortedEvents;
    },
    sortReccuringEvents(dates) {
      const days = {
        sunday: 0,
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6,
      };

      const today = new Date();
      const todayOrAfter = [];
      const beforeToday = [];

      dates.forEach((event) => {
        if (days[event.date.repeat.day.toLowerCase()] > today.getDay()) {
          todayOrAfter.push(event);
        } else {
          beforeToday.push(event);
        }
      });

      todayOrAfter.sort((a, b) => days[a.date.repeat.day.toLowerCase()] - days[[b.date.repeat.day.toLowerCase()]]);

      beforeToday.sort((a, b) => days[a.date.repeat.day.toLowerCase()] - days[[b.date.repeat.day.toLowerCase()]]);

      return todayOrAfter.concat(beforeToday);
    },
    formatDate(newsDate) {
      const [year, month, day] = newsDate.split('-');
      const formattedDate = new Date();
      formattedDate.setFullYear(parseInt(year, 10));
      formattedDate.setMonth(parseInt(month, 10) - 1, parseInt(day, 10));
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return formattedDate.toLocaleDateString(undefined, options);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../assets/style/globals.scss';

.cve-display-none {
  display: none;
}

.cve-expansion-bar {
    margin: 1em 0;
    height: 0;
    border-top: solid 3px #d4d4d3;
    text-align: center;
}

.cve-expansion-bar .cve-expansion-button {
    position: relative;
    top: -1em;
    background-color: #ffffff;
    border: none;
    padding: 0 1em;
    text-transform: uppercase;
    font-size: 14px;
}
.content {
  background-color: white;
  border-radius: $cve-border-radius;
}

.content p:not(:last-child) {
  margin-bottom: 0.2rem;
}

.cve-more-link {
  text-align: right;
}

.cve-news-title-padding {
  padding: 0.5rem 0 0 0.5rem;
}

.cve-events-title-padding {
  padding: 0rem 0 0 0.5rem;
}

.table td, .table th {
  border: none;
}
tr {
  text-align: left;
}

.cve-news-icon {
  color: $theme-color-primary-darker;
}

.cve-news-article {
  box-shadow: unset !important;
  padding-left: 5px;
}

.cve-icon-meaning-label:hover {
  background-color: unset !important;
}

.cve-news-article-container, .cve-news-article, .cve-event {
  padding: 0 0 5px 0;
}

.cve-event-first-column {
  background-color: $theme-color-base-lighter;
}

.subtitle {
  text-align: left;
}

.cve-news-first-column {
  padding: 10px 5px 5px 10px;
}

.tile.is-ancestor {
  margin: 0 8px 8px 8px;
}

.tile.is-ancestor.cve-news-events-tile {
  margin-right: unset;
}

.title {
  text-align: left;
  font-weight: unset;
}

.is-spaced {
  margin: 0 0 0px 10px;
}

p.subtitle.is-6{
  margin: 0 0 0 10px;
}

.cve-separator {
  border: 1px solid $theme-color-base-lighter;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
</style>

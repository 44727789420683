 <template>
  <div id="cve-secondary-page-main-container" class="container">
    <div class="columns is-centered">
      <div class="column is-8-desktop cve-main-column-content-width is-12-tablet">
        <main id="cve-main-page-content" role="main">
          <div class="content">
            <h1 :id="cvenavs['Program Organization']['submenu']['Program Relationship with Partners']['id']" class="title">
              {{cvenavs['Program Organization']['submenu']['Program Relationship with Partners']['label']}}</h1>
            <ExternalLinkMessage/>
            <p>
            The CVE Program partners with both internal and external partners.
            </p>
            <h2 :id="cvenavs['Program Organization']['submenu']['Program Relationship with Partners']['items']
              ['Internal Partners']['anchorId']" class="title">
              {{cvenavs['Program Organization']['submenu']['Program Relationship with Partners']['items']['Internal Partners']['label']}}
            </h2>
            <p>
              Internal partners are active members of the CVE community and directly contribute to the CVE Program.
            </p>
            <h3 class="titl">CVE Numbering Authorities (CNAs)</h3>
            <p>
              <router-link to="/ProgramOrganization/CNAs">Organizations</router-link> from around
              the world that partner with the CVE Program to assign CVE IDs and publish CVE Records
              for vulnerabilities within a specific scope.
            </p>
            <h3 class="titl">Roots</h3>
            <p>
              <router-link to="/ProgramOrganization/CNAs">Organizations</router-link> from around the world that partner with the CVE Program,
              within a specific scope, for the recruitment, training, and governance of one or more entities that are a CNA, CNA-LR, or another Root.
            </p>
            <h3 class="titl">CVE Working Groups</h3>
            <p>
              <router-link to="/ProgramOrganization/WorkingGroups">Members</router-link>
              of the CVE community
              (CNAs, Board members, and in some instances members of the general public) who
              donate time to focus on improving processes, workflows, and other aspects of
              the program.
            </p>
            <h3 class="titl">CVE Board</h3>
            <p>
              <router-link to="/ProgramOrganization/Board">Members</router-link> of cybersecurity
              and related organizations who donate time to ensure that the CVE Program meets
              the vulnerability identification needs of the global cybersecurity and technology
              community.
            </p>
            <h2 :id="cvenavs['Program Organization']['submenu']['Program Relationship with Partners']['items']
              ['External Partners']['anchorId']" class="title">
              {{cvenavs['Program Organization']['submenu']['Program Relationship with Partners']['items']['External Partners']['label']}}
            </h2>
            <p>
              External partners are members of the greater cybersecurity community that
              use, or enhance the use of, CVE content.
            </p>
            <h3 class="titl">U.S. National Vulnerability Database (NVD)</h3>
            <p>
              <a href="https://nvd.nist.gov/" target="_blank">NVD</a>, operated by
              <a href="https://www.nist.gov/" target="_blank">National Institute of Standards and Technology
              (NIST)</a>, is a vulnerability database built upon, and fully synchronized
              with, the CVE List. The <router-link to='/ResourcesSupport/Glossary?activeTerm=glossaryCVEList'>
              CVE List</router-link> feeds NVD, which then builds upon the information
              included in CVE Records to provide enhanced information for each record such
              as fix information, severity scores, and impact ratings.
            </p>
            <h3 class="titl">Common Vulnerability Scoring System (CVSS)</h3>
            <p>
              <a href="https://www.first.org/cvss/" target="_blank">CVSS</a>, operated by the <a href="https://www.first.org/" target="_blank">Forum
              of Incident Response and Security Teams (FIRST)</a>, is a separate program from CVE that offers a way to
              score the severity of software vulnerabilities, including those identified by
              <router-link to='/ResourcesSupport/Glossary?activeTerm=glossaryRecord'>CVE Records</router-link>. (In general, severity scores for CVE
              Records are provided by <a href="https://nvd.nist.gov/" target="_blank">NVD</a> using a CVSS calculator, but some CVE Records on the CVE
              List include severity ratings if the CNA publishing the record decides to include them.)
            </p>
            <h2 :id="cvenavs['Program Organization']['submenu']['Program Relationship with Partners']['items']
              ['Additional Resources']['anchorId']" class="title">
              {{cvenavs['Program Organization']['submenu']['Program Relationship with Partners']['items']['Additional Resources']['label']}}
            </h2>
            <ul>
              <li><router-link to="/PartnerInformation/Partner">Partner Information</router-link></li>
              <li><router-link to="/About/RelatedEfforts">Related Efforts</router-link></li>
            </ul>
          </div>
        </main>
        <SurveyLinkComponent :surveyLink="cvenavs['Program Organization']['submenu']['Program Relationship with Partners']['surveyLink']" />
      </div>
      <div class="column is-3 is-hidden-touch">
        <NavigationSidebar :nav="cvenavs['Program Organization']" />
      </div>
    </div>
  </div>
</template>

<script>
import NavigationSidebar from '@/components/NavigationSidebar.vue';
import SurveyLinkComponent from '@/components/SurveyLinkComponent.vue';

export default {
  name: 'ProgramRelationshipwithPartners',
  components: {
    NavigationSidebar,
    SurveyLinkComponent,
  },
  props: {
    cvenavs: {
      type: Object,
      required: true,
    },
  },
};

</script>

<style scoped lang="scss">
@import '../../assets/style/globals.scss';

</style>

<template>
  <div id="cve-tertiary-page-main-container" class="container">
    <div class="columns is-centered">
      <div class="column is-8">
        <main id="cve-main-page-content" role="main">
          <div class="content">
            <h1 class="title">Terms of Use</h1>
            <h2 class="title">License</h2>
            <p>
              <span class="has-text-weight-bold">Submissions:</span> For all materials you submit to the Common Vulnerabilities
              and Exposures (CVE<sup>®</sup>), you hereby grant to The MITRE Corporation (MITRE) and all
              CVE Numbering Authorities (CNAs) a perpetual, worldwide, non-exclusive, no-charge,
              royalty-free, irrevocable copyright license to reproduce, prepare derivative works
              of, publicly display, publicly perform, sublicense, and distribute such materials
              and derivative works. Unless required by applicable law or agreed to in writing,
              you provide such materials on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS
              OF ANY KIND, either express or implied, including, without limitation, any warranties
              or conditions of TITLE, NON-INFRINGEMENT, MERCHANTABILITY, or FITNESS FOR A PARTICULAR
              PURPOSE.
            </p>
            <p>
            <span class="has-text-weight-bold">CVE Usage:</span> MITRE hereby grants you a perpetual, worldwide,
            non-exclusive, no-charge, royalty-free, irrevocable copyright license
            to reproduce, prepare derivative works of, publicly display, publicly
            perform, sublicense, and distribute Common Vulnerabilities and Exposures
            (CVE<sup>®</sup>). Any copy you make for such purposes is authorized provided that
            you reproduce MITRE's copyright designation and this license in any such
            copy.
            </p>
            <h2 class="title">Disclaimers</h2>
            <p>
            ALL DOCUMENTS AND THE INFORMATION CONTAINED THEREIN PROVIDED BY MITRE
            ARE PROVIDED ON AN "AS IS" BASIS AND THE CONTRIBUTOR, THE ORGANIZATION
            HE/SHE REPRESENTS OR IS SPONSORED BY (IF ANY), THE MITRE CORPORATION,
            ITS BOARD OF TRUSTEES, OFFICERS, AGENTS, AND EMPLOYEES, DISCLAIM ALL
            WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY WARRANTY
            THAT THE USE OF THE INFORMATION THEREIN WILL NOT INFRINGE ANY RIGHTS OR
            ANY IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR
            PURPOSE.
            </p>
            <p class='cve-last-reviewed-date'>
              <span class="cve-last-reviewed-date-label">Page Last Updated or Reviewed: </span><time datetime='2017-12-15'>December 15, 2017</time>
            </p>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsOfUse',
};
</script>

<style scoped lang="scss">
@import '../../assets/style/globals.scss';
</style>

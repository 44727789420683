<template>
  <div id="cve-secondary-page-main-container" class="container">
    <div class="columns is-centered">
      <div class="column is-8-desktop cve-main-column-content-width is-12-tablet">
        <main id="cve-main-page-content" role="main">
          <div class="content">
            <h1 :id="cvenavs['Resources & Support']['submenu']['Glossary']" class="title">
            {{cvenavs['Resources & Support']['submenu']['Glossary']['label']}}</h1>
            <div class="table-container">
              <table aria-live="polite" class="table cve-border-dark-blue is-striped is-hoverable" >
                <thead>
                  <tr>
                    <th style="width: 30%">Term</th>
                    <th>Definition</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="width: 30%">
                      <a :href="$sanitize(currentTermLink)">
                        <span class="has-text-weight-bold" v-html="$sanitize(currentTerm)"></span>
                      </a>
                    </td>
                    <td><span v-html="$sanitize(currentDefinition)" class="cve-term-definition"></span></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr/>
            <div class="cve-glossary-terms">
              <div class="columns">
                <div class="column">
                  <a href="#" class="cve-term-link"
                    v-for="(entry, index) in glossaryEntries.slice(0,glossaryEntries.length/2)" :key="index"
                    :id="entry.id"
                    v-bind:class="{'cve-term-active': isActive == entry.id}"
                    v-on:click="setDefinitionForActiveTerm(entry.id)">{{entry.term}}
                  </a>
                </div>
                <div class="column">
                  <a href="#" class="cve-term-link"
                    v-for="(entry, index) in glossaryEntries.slice(glossaryEntries.length/2)" :key="index"
                    :id="entry.id"
                    v-bind:class="{'cve-term-active': isActive == entry.id}"
                    v-on:click="setDefinitionForActiveTerm(entry.id)">{{entry.term}}
                  </a>
                </div>
              </div>
            </div> <!-- end cve-glossary-terms -->
           </div>  <!-- end content -->
          <SurveyLinkComponent :surveyLink="cvenavs['Resources & Support']['submenu']['Glossary']['surveyLink']" />
          </main>
         </div> <!-- end is-8 -->
         <div class="column is-3 is-hidden-touch">
            <NavigationSidebar :nav="cvenavs['Resources & Support']" />
         </div>
      </div> <!-- end columns -->
    </div>
</template>

<script>
import NavigationSidebar from '@/components/NavigationSidebar.vue';
import SurveyLinkComponent from '@/components/SurveyLinkComponent.vue';
import GlossaryData from '../../assets/data/glossaryEntries.json';

export default {
  name: 'Glossary',
  components: {
    NavigationSidebar,
    SurveyLinkComponent,
  },
  props: {
    cvenavs: {
      type: Object,
      required: true,
    },
    activeTerm: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isActive: GlossaryData[0].id,
      currentDefinition: GlossaryData[0].definition,
      currentTerm: GlossaryData[0].term,
      currentTermLink: GlossaryData[0].termLink,
      glossaryEntries: GlossaryData,
    };
  },
  methods: {
    setDefinitionForActiveTerm(termId) {
      const entry = this.glossaryEntries.find((e) => e.id === termId);
      this.currentTerm = entry.term;
      this.currentTermLink = entry.termLink;
      this.currentDefinition = entry.definition;
      this.isActive = termId;
    },
  },
  mounted() {
    if (this.$route.query.activeTerm) {
      this.setDefinitionForActiveTerm(this.$route.query.activeTerm);
    }
  },
};
</script>

<!-- Cannot use "scoped" attribute with v-html above -->
<style lang="scss">
@import '../../assets/style/globals.scss';

.cve-glossary-terms {
  text-align: left;
}

.cve-term {
  font-weight: bold;
}

.cve-term-active {
  background-color: $theme-color-accent-cool-light;
  color: black;
  padding-left: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.cve-term-link {
  display:block;
}

.cve-term-definition-content {
  padding-bottom: 20px;
  text-align: left;
}

.cve-term-definition-list {
  margin-left: 20px;
  list-style-type: square;
}

.cve-term-reference {
  font-weight: bold;
}

</style>

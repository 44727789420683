<template>
  <div id="cve-secondary-page-main-container" class="container">
    <div class="columns is-centered">
      <div class="column is-8-desktop cve-main-column-content-width is-12-tablet">
        <main id="cve-main-page-content" role="main">
          <div class="content">
            <h1 :id="newsPageInfo.id" class="title">
              {{newsPageInfo.label}}
            </h1>
            <ExternalLinkMessage/>
            <p>
              Receive CVE news in your inbox by <router-link to="/Media/News/NewsletterSignup">signing up for our email newsletter</router-link>.
            </p>
            <div class="content" v-for="newsItem in pagination.currentNewsToDisplay" :key="`${newsItem.id}-${newsItem.newsType}`">
              <article class ="media">
                <div class ="media-content">
                  <div class ="content">
                    <h2 class="title mt-4 mb-1 is-size-4">
                      <router-link :to="`/Media/News/item/${$sanitize(newsItem.newsType)}/${newsItem.itemUrl}`" v-html="$sanitize(newsItem.title)">
                      </router-link>
                    </h2>
                    <span class ="level is-mobile has-text-grey-dark mb-1">
                      <div class ="level-left">
                        <span class ="level-item tag is-rounded has-text-grey-dark is-capitalized">{{newsItem.newsType}}</span>
                        <p class="level-item" v-if="newsItem.newsType == 'blog'">By {{newsItem.author.name}}
                          <span class="pl-1" v-if="newsItem.author.organization.name !== 'CVE Program'">from</span>
                          <span class="icon-text" v-if="newsItem.author.organization.name !== 'CVE Program'">
                            <a class="pl-1" :href="newsItem.author.organization.url">
                            {{newsItem.author.organization.name}}
                            <span class="icon is-size-7 cve-icon-xxs">
                              <p id="extenalLink1" class="is-hidden">external site</p>
                              <font-awesome-icon icon="external-link-alt" aria-labelledby="extenalLink1" aria-hidden="false" focusable="false"/>
                            </span>
                            </a>
                          </span>
                          <span class="pl-3">|</span>
                        </p>
                        <time class ="level-item" :datetime="newsItem.date">{{formatDate(newsItem.date)}}</time>
                      </div>
                    </span>
                    <div v-if="['blog', 'news', 'press-release'].includes(newsItem.newsType)">
                      <figure v-if="newsItem.description[0].contentnewsType === 'image'"  class="image is3by2">
                        <img :src="`/images${newsItem.description[0].href}`" :alt="newsItem.description[0].imgAltText"/>
                        <figcaption v-html="newsItem.description[0].captionText"></figcaption>
                      </figure>
                      <p v-if="newsItem.description[0].contentnewsType === 'paragraph'"
                        v-html="$sanitize(newsItem.description[0].content, {allowedTags: ['a', 'img']})">
                      </p>
                    </div>
                    <div class="is-flex-desktop" style="justify-content: left;"  v-if="newsItem.newsType == 'podcast'">
                      <figure class="media-left image is3by2">
                        <iframe class="has-ratio" :src="newsItem.url" frameborder="0" allowfullscreen>
                        </iframe>
                      </figure>
                      <p v-html="$sanitize(newsItem.description[0].content)"></p>
                    </div>
                  </div>
                </div>
              </article>
            </div>
            <nav class="pagination is-centered" aria-label="pagination">
              <button class="pagination-previous button cve-button cve-button-outline" v-bind:disabled="pagination.currentPage > 1 ? false : true"
                @click=getPreviousPage>
                <p id="leftAngleIcon" class="is-hidden">previous page button</p>
                <font-awesome-icon icon="angle-left" aria-labelledby="leftAngleIcon" aria-hidden="false"/>
              </button>
              <button class ="pagination-next button cve-button cve-button-outline"
                v-bind:disabled="pagination.currentPage >= pagination.totalPages ? true : false"
                @click=getNextPage>
                <p id="rightAngleIcon" class="is-hidden">next page button</p>
                <font-awesome-icon icon="angle-right" aria-labelledby="rightAngleIcon" aria-hidden="false"/>
              </button>
              <ul class ="pagination-list">
                <li v-for="(pageNumber, index) in pagination.pages" :key="index" class="mt-1">
                  <a v-bind:class="pageNumber == pagination.currentPage ? 'pagination-link cve-button cve-button-outline is-current' :
                    'pagination-link cve-button cve-button-outline'" @click=updatePagination(pageNumber)
                    v-bind:aria-label="'Goto page ' + pageNumber"
                    v-bind:aria-current="(pageNumber == pagination.currentPage) ? 'page' : false">{{pageNumber}}</a>
                </li>
              </ul>
            </nav>
          </div>
        </main>
        <SurveyLinkComponent v-if="newsPageInfo.newsType == 'all'" :surveyLink="cvenavs['AllNews']['surveyLink']" />
        <SurveyLinkComponent v-else :surveyLink="cvenavs['AllNews']['submenu']['Recent']['submenu'][newsPageInfo.label]['surveyLink']" />
      </div>
      <div class="column is-3" :class="isNavSidebarHiddenTouch ? 'is-hidden-touch' : ''">
        <NavigationSidebar :nav="cvenavs['AllNews']" :isNavSidebarHiddenTouch="isNavSidebarHiddenTouch"/>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationSidebar from '@/components/NavigationSidebar.vue';
import SurveyLinkComponent from '@/components/SurveyLinkComponent.vue';
import newsData from '@/assets/data/news.json';
import newsMixins from '@/mixins/newsMixins';

export default {
  name: 'NewsModule',
  components: {
    NavigationSidebar,
    SurveyLinkComponent,
  },
  data() {
    return {
      newsList: newsData.currentNews,
      pagination: {
        totalItems: 0,
        currentPage: 1,
        pageSize: 10,
        totalPages: 0,
        startIndex: 0,
        endIndex: 0,
        maxPages: 10,
        pages: [],
        currentNewsToDisplay: [],
      },
      isNavSidebarHiddenTouch: false,
    };
  },
  mixins: [newsMixins],
  created() {
    // initialize data
    this.getNewsByTypeAndAddUrlDetail(this.newsPageInfo.newsType);
    this.paginate();
  },
  methods: {
    getNewsByTypeAndAddUrlDetail(newsType) {
      if (newsType === 'all') {
        const transformedNewsList = this.newsList.map((newsItem) => {
          const tempNewsItem = newsItem;
          tempNewsItem.itemUrl = this.createItemUrlDetail(newsItem);
          return tempNewsItem;
        });

        this.newsList = transformedNewsList.filter((newsItem) => newsItem.newsType !== 'press-release');
      } else {
        const cloneNewsList = this.newsList;
        this.newsList = [];

        cloneNewsList.forEach((newsItem) => {
          const tempNewsItem = newsItem;
          if (tempNewsItem.newsType === newsType) {
            tempNewsItem.itemUrl = this.createItemUrlDetail(tempNewsItem);
            this.newsList.push(tempNewsItem);
          }
        });
      }
    },
    paginate() {
      // calculate total pages

      this.pagination.totalItems = this.newsList.length;
      const totalPages = Math.ceil(this.pagination.totalItems / this.pagination.pageSize);

      // ensure current page isn't out of range
      if (this.pagination.currentPage < 1) {
        this.pagination.currentPage = 1;
      } else if (this.pagination.currentPage > totalPages) {
        this.pagination.currentPage = totalPages;
      }

      let startPage; let
        endPage;
      if (totalPages <= this.pagination.maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = totalPages;
      } else {
        // total pages more than max so calculate start and end pages
        const maxPagesBeforeCurrentPage = Math.floor(this.pagination.maxPages / 2);
        const maxPagesAfterCurrentPage = Math.ceil(this.pagination.maxPages / 2) - 1;
        if (this.pagination.currentPage <= maxPagesBeforeCurrentPage) {
          // current page near the start
          startPage = 1;
          endPage = this.pagination.maxPages;
        } else if (this.pagination.currentPage + maxPagesAfterCurrentPage >= totalPages) {
          // current page near the end
          startPage = totalPages - this.pagination.maxPages + 1;
          endPage = totalPages;
        } else {
          // current page somewhere in the middle
          startPage = this.pagination.currentPage - maxPagesBeforeCurrentPage;
          endPage = this.pagination.currentPage + maxPagesAfterCurrentPage;
        }
      }

      // calculate start and end item indexes
      this.pagination.startIndex = (this.pagination.currentPage - 1) * this.pagination.pageSize;
      this.pagination.endIndex = Math.min(this.pagination.startIndex + this.pagination.pageSize - 1, this.pagination.totalItems - 1);

      // create an array of pages to loop through in the pager control
      this.pagination.pages = Array.from(Array((endPage + 1) - startPage).keys()).map((i) => startPage + i);
      this.pagination.totalPages = totalPages;

      this.pagination.currentNewsToDisplay = this.newsList.slice(this.pagination.startIndex, this.pagination.endIndex + 1);
    },
    updatePagination(pageNumber) {
      this.pagination.currentPage = pageNumber;
      this.paginate();
    },
    getPreviousPage() {
      this.pagination.currentPage -= 1;
      this.paginate();
    },
    getNextPage() {
      this.pagination.currentPage += 1;
      this.paginate();
    },
    formatDate(newsDate) {
      const [year, month, day] = newsDate.split('-');
      const formattedDate = new Date();
      formattedDate.setFullYear(parseInt(year, 10));
      formattedDate.setMonth(parseInt(month, 10) - 1, parseInt(day, 10));
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return formattedDate.toLocaleDateString(undefined, options);
    },
  },
  props: {
    newsPageInfo: {
      type: Object,
      required: true,
    },
    cvenavs: {
      type: Object,
      required: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/assets/style/globals.scss';

.is-active {
  color: $black;
}

</style>

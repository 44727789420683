<template>
  <div id="cve-secondary-page-main-container" class="container">
    <div class="columns is-centered">
      <div class="column is-3 is-hidden-touch">
          <aside class="menu is-hidden-touch cve-sidebar-menu mt-3">
            <p class="menu-label cve-capitalize-first-letter message-label">All Questions</p>
            <ul class="menu-list cve-capitalize-first-letter">
              <li v-for="(submenuObj, index) in accordianList" :key="submenuObj.sectionId">
                <span class="is-flex" style="justify-content: space-between; width: inherit">
                  <router-link :to="'#' + $sanitize(submenuObj.sectionId)">{{ submenuObj.sectionTitle }}</router-link>
                  <button class="button is-text cve-button-submenu-toggle"
                    @click="toggleSubmenuItem(submenuObj.sectionId)"
                    :aria-expanded="selectedSubmenu.includes(submenuObj.sectionId) ? 'true' : 'false'"
                    :aria-controls="submenuObj.sectionId + 'nav'">
                    <span class="icon cve-icon-xxs">
                      <p :id="'navExpandCollapseAltText' + index" class="is-hidden">
                        {{selectedSubmenu.includes(submenuObj.sectionId) ? 'collapse' : 'expand'}}
                      </p>
                      <font-awesome-icon class="icon" :icon="selectedSubmenu.includes(submenuObj.sectionId) ? 'minus' : 'plus'"
                        aria-hidden="false" focusable="true" :aria-labelledby="'navExpandCollapseAltText' + index"/>
                    </span>
                  </button>
                </span>
                <ul v-show="selectedSubmenu.includes(submenuObj.sectionId)" :id="submenuObj.sectionId + 'nav'">
                  <li v-for="submenuItem in submenuObj.sectionQuestions" :key="submenuObj.sectionId + submenuItem.questionId">
                    <router-link :to="'#' + $sanitize(submenuObj.sectionId + submenuItem.questionId)">{{ submenuItem.questionText }}?</router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </aside>
      </div>
      <div class="column is-8-desktop cve-main-column-content-width is-12-tablet">
        <main id="cve-main-page-content" role="main">
          <div class="content">
            <h1 class="title">Frequently Asked Questions (FAQs)</h1>
            <ExternalLinkMessage/>
            <div class="buttons mb-0" style="justify-content: flex-end;">
              <button class="button cve-button" @click="setAccordian('sectionId', 'questionId')">{{isExpandAll ? 'Expand' : 'Collapse'}} all</button>
            </div>
            <section class="cve-accordian">
              <div class="message" v-for="item in accordianList" :key="item.sectionId">
                <h2 :id="item.sectionId" class="title">{{item.sectionTitle}}</h2>
                <div v-for="qAndA in item.sectionQuestions" :key="item.sectionId + qAndA.questionId">
                  <div class="message-header cve-base-light-gray-borders-bg">
                    <p :id="item.sectionId + qAndA.questionId" class="mt-2 mb-2">{{qAndA.questionText}}?</p>
                    <button class="button message-header-button" @click="toggleAccordianItem(item.sectionId + qAndA.questionId)"
                      :aria-expanded="accordian[item.sectionId + qAndA.questionId] ? 'false' : 'true'"
                      :aria-controls="item.sectionId + qAndA.questionId + 'response'">
                      <span class="icon is-small">
                        <p :id="'expandCollapseAltText' + item.sectionId + qAndA.questionId" class="is-hidden">
                          {{accordian[item.sectionId + qAndA.questionId] ? 'collapse' : 'expand'}}
                        </p>
                        <font-awesome-icon :icon="accordian[item.sectionId + qAndA.questionId] ? 'plus' : 'minus'"
                          aria-hidden="false" focusable="true" :aria-labelledby="'expandCollapseAltText' + item.sectionId + qAndA.questionId"/>
                      </span>
                    </button>
                  </div>
                  <div :id="item.sectionId + qAndA.questionId + 'response'" class="message-body"
                    :class="{'is-hidden': accordian[item.sectionId + qAndA.questionId]}">
                    <div v-if="qAndA.questionId == 'search_cve'" class="pb-3">
                      <p>The following methods are available:</p>
                      <strong>CVE ID Lookup</strong>
                      <ul>
                        <li>Search for a specific CVE Record by its CVE ID using the
                          <router-link :to="{ hash: '#cve-secondary-navbar' }">CVE ID lookup</router-link> at the top of this page.
                        </li>
                      </ul>
                    </div>
                    <p v-html="$sanitize(responsePara)" v-for="(responsePara, index) in qAndA.questionResponseParagraphs"
                      :key="item.sectionId + qAndA.questionId + index"></p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import faqsData from '@/assets/data/faqs.json';
import Vue from 'vue';

export default {
  name: 'FAQs',
  data() {
    return {
      accordian: {},
      accordianList: faqsData,
      selectedSubmenu: [],
      isExpandAll: true,
    };
  },
  created() {
    this.setAccordian('sectionId', 'questionId');
  },
  methods: {
    setAccordian(parentId, childId) {
      this.isExpandAll = !this.isExpandAll;
      this.accordianList.forEach((item) => {
        item.sectionQuestions.forEach((qAndA) => {
          Vue.set(this.accordian, (parentId === '') ? qAndA[childId] : `${item[parentId]}${qAndA[childId]}`, this.isExpandAll);
        });
      });
    },
    toggleAccordianItem(id) {
      Vue.set(this.accordian, id, !this.accordian[id]);
    },
    toggleSubmenuItem(id) {
      if (this.selectedSubmenu.includes(id)) {
        const index = this.selectedSubmenu.indexOf(id);
        this.selectedSubmenu.splice(index, 1);
      } else {
        this.selectedSubmenu.push(id);
      }
    },
  },
};

</script>

<style scoped lang="scss">
@import '../../assets/style/globals.scss';
.message {
  background-color: unset;
}
</style>

<template>
  <div id="cve-tertiary-page-main-container" class="container">
    <div class="columns is-centered">
      <div class="column is-3 is-hidden-touch">
        <TableOfContentsSidebar :nav="cvePageNavs"/>
      </div>
      <div class="column is-8-desktop cve-main-column-content-width is-12-tablet">
        <main id="cve-main-page-content" role="main">
          <h1 class="title">CVE Numbering Authority (CNA) Rules</h1>
          <div id="cve-versionInformation">
            <p class="has-text-weight-bold">Document Version: <span>{{versionNum}}</span></p>
            <p class="has-text-weight-bold">CVE Board Approval: <span>{{versionApprovalDate}}</span></p>
            <p class="has-text-weight-bold">Effective Date: <span>{{versionDate}}</span></p>
            <p><router-link to="/Resources/Roles/Cnas/CNA_Rules_v3.0.pdf" target="_blank">View as PDF (0.5MB)</router-link></p>
          </div>
          <hr>
          <!-- Top level section -->
          <div class="content">
            <ol class="ml-0">
              <li v-for="(section, index) of cnaRulesSections" :key="index">
                <h2 class="title" :id="section.sectionId">
                  {{ section.sectionTitle }}
                </h2>
                <p v-for="(para, index) of section.sectionParagraphs"
                  v-html="$sanitize(para,{allowedTags: $sanitize.defaults.allowedTags.concat([ 'img' ])})" :key="index"></p>
                <!-- Sub section -->
                <ol class="ml-0">
                  <li v-for="(subSection, index) of section.subSections" :key="index">
                    <h3 class="title" :id="subSection.subSectionId">
                      {{ subSection.subSectionTitle }}
                    </h3>
                    <p v-for="(para, index) of subSection.subSectionParagraphs"
                      v-html="$sanitize(para,{allowedTags: $sanitize.defaults.allowedTags.concat([ 'img' ])})" :key="index"></p>
                  </li>
                </ol>  <!-- end page sub section -->
              </li>
            </ol> <!-- end page section -->
            <ul class="ml-0 cve-list-no-bullet">
              <li v-for="(section, index) of cnaRulesAppendices" :key="index">
                <h2 class="title" :id="section.sectionId">
                  {{ section.sectionTitle }}
                </h2>
                <p v-for="(para, index) of section.sectionParagraphs" v-html="$sanitize(para)" :key="index"></p>
                <!-- Sub section -->
                <ul class="ml-0 cve-list-no-bullet">
                  <li v-for="(subSection, index) of section.subSections" :key="index">
                    <h3 class="title" :id="subSection.subSectionId">
                       {{ subSection.subSectionTitle }}
                    </h3>
                    <p v-for="(para, index) of subSection.subSectionParagraphs" v-html="$sanitize(para)" :key="index"></p>
                    <ul class="ml-0 cve-list-no-bullet"> <!-- Sub Sub section -->
                      <li v-for="(subSubSection, index) of subSection.subSubSections" :key="index">
                        <h4 class="title" :id="subSubSection.subSubSectionId">
                          {{ subSubSection.subSubSectionTitle }}
                        </h4>
                        <p v-for="(para, index) of subSubSection.subSubSectionParagraphs" v-html="$sanitize(para)" :key="index"></p>
                      </li>
                    </ul> <!-- end apendices sub sub section -->
                  </li>
                </ul>  <!-- end appendices sub section -->
              </li>
            </ul> <!-- end appendicessection -->
         </div>
        </main>
      </div>
    </div>             <!-- end columns -->
  </div>
</template>

<script>
import TableOfContentsSidebar from '@/components/TableOfContentsSidebar.vue';
import cnaRulesData from '../../../assets/data/cnaRules.json';

export default {
  name: 'CNARules',
  components: {
    TableOfContentsSidebar,
  },
  data() {
    return {
      versionNum: cnaRulesData.versionNumber,
      versionApprovalDate: cnaRulesData.versionApprovalDate,
      versionDate: cnaRulesData.versionDate,
      cnaRulesSections: cnaRulesData.pageSections,
      cnaRulesAppendices: cnaRulesData.appendices,
      cvePageNavs: cnaRulesData,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../assets/style/globals.scss';

ol { counter-reset: item; }
ol li {display: block;}
ol li p, ul li p {
  margin-bottom: 16px;
}
ol li h2::before, ol li h3::before {
            content: counters(item, ".");
            counter-increment: item;
}

::v-deep .cve-acronym,
::v-deep .cve-acronym-definition {
  display: block;
}
</style>

<template>
  <div id="cve-secondary-page-main-container">
    <div class="columns is-centered">
      <div class="column is-12-desktop is-11-tablet cve-main-column-content-width">
        <main id="cve-main-page-content" role="main">
          <h1 class="title">Search CVE Site</h1>
          <div role="alert" class="notification is-info is-light">
            <div class="is-flex" style="justify-content: center;">
              <p id="infoSearchTip" class="is-hidden">info</p>
              <font-awesome-icon style="flex: 0 0 40px;" size="1x"  icon="info-circle" role="info"
                aria-labelledby="infoSearchTip" aria-hidden="false" />
              <div>
                <p class="has-text-weight-bold">Search Tips:</p>
                <ul>
                  <li>To search CVE Records, use the <span class="has-text-weight-bold">CVE ID lookup</span> in the website banner.</li>
                  <li>To search for CNAs, use the
                    <span class="has-text-weight-bold"><router-link to="/PartnerInformation/ListofPartners">List of Partners</router-link></span>
                    search.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            To search this website, enter keywords in the search bar below:
          </div>
          <div class="gcse-searchbox"></div>
          <ExternalLinkMessage/>
          <div class="gcse-searchresults"></div>
        </main>
        <SurveyLinkComponent :surveyLink=surveyLink />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import LoadScript from 'vue-plugin-load-script';
import SurveyLinkComponent from '@/components/SurveyLinkComponent.vue';

Vue.use(LoadScript);

export default {
  components: {
    SurveyLinkComponent,
  },
  mounted() {
    Vue.loadScript('https://cse.google.com/cse.js?cx=3f60a0cc494b9b030');
  },
  destroyed() {
    Vue.unloadScript('https://cse.google.com/cse.js?cx=3f60a0cc494b9b030');
  },
  props: {
    surveyLink: {
      type: String,
      default: 'https://forms.office.com/Pages/ResponsePage.aspx?id='
      + 'SNwgxlAdUkmLOd9NVNdNgksLeUGJ_vlDujipnPAHzqlUMjhBNUtDUjlNTFNVWlJXSkdRVk9GTkFJNyQlQCN0PWcu',
    },
  },
};

</script>

<style scoped lang="scss">
@import '../assets/style/globals.scss';
#cve-secondary-page-main-container {
  background-color: white;
}

li {
  list-style-type: square;
  margin-left: 1rem;
}
</style>

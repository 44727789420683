<template>
<div class="cve-feedback-link">
  <a :href="$sanitize(surveyLink)" target="_blank">
    Provide feedback for this page
    <span class="icon cve-icon-xxs">
      <p id="externalSurveyLink" class="is-hidden">
        Survey opens in a new tab or window depending on browser settings
      </p>
      <font-awesome-icon icon="external-link-alt" aria-labelledby="extenalSurveyLink" aria-hidden="false"/>
    </span>
  </a>
</div>
</template>

<script>
export default {
  props: {
    surveyLink: {
      type: String,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../assets/style/globals.scss';

</style>

<template>
  <div id="cve-secondary-page-main-container">
    <div class="columns is-centered">
      <div class="column is-8-desktop cve-main-column-content-width is-12-tablet">
        <main id="cve-main-page-content" role="main">
          <div class="content">
            <h1 class="title">Metrics</h1>
            <p>Use the scroll bar to navigate the data tables. </p>
            <div class="cve-white-bg-gray-border-container pt-2">
              <h2 :id="cvenavs['About']['submenu']['Metrics']['items']['Published CVE Records']['anchorId']" class="title">
                {{cvenavs['About']['submenu']['Metrics']['items']['Published CVE Records']['label']}}
              </h2>
              <p>Comparison of published <router-link to="/ResourcesSupport/Glossary?activeTerm=glossaryRecord">CVE Records
                </router-link> by quarter for all years from 1999 to present.
              </p>
              <p class="cve-help-text">
                A CVE Record contains descriptive data, (i.e., a brief description and at least one reference) about a
                <router-link to="/ResourcesSupport/Glossary?activeTerm=glossaryVulnerability">vulnerability</router-link> associated with
                a <router-link to="/ResourcesSupport/Glossary?activeTerm=glossaryCVEID">CVE ID</router-link>. CVE Records are published by
                <router-link to="/ResourcesSupport/Glossary?activeTerm=glossaryCNA">CVE Numbering Authorities (CNAs)</router-link>.
              </p>
              <div class="field is-grouped is-grouped-right">
                <label class="label">Sort by:</label>
                <div class="control">
                  <div class="select">
                    <select name="partner" v-model="publishedRecordsSortByYearSelectedOption" aria-label="select a sort by direction">
                      <option v-bind:value='"desc"'>Year (newest to oldest)</option>
                      <option v-bind:value='"asc"'>Year (oldest to newest)</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="cve-scrollx-table-container">
                <table class="table is-striped is-hoverable cve-border-dark-blue">
                  <thead>
                    <th>Year</th>
                    <th v-for="year in publishedCveRecords.years" :key="year">{{year}}</th>
                  </thead>
                  <tbody>
                    <tr v-for="quarter in ['4', '3', '2', '1']" :key="`published-${quarter}`">
                      <th>Qtr{{quarter}}</th>
                      <td v-for="value in publishedCveRecords.quarters[quarter]" :key="value.key">{{value}}</td>
                    </tr>
                    <tr>
                      <th>TOTAL</th>
                      <td v-for="total in publishedCveRecords.total" :key="total.key">{{total}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="cve-white-bg-gray-border-container pt-2">
              <h2 :id="cvenavs['About']['submenu']['Metrics']['items']['Reserved CVE IDs']['anchorId']" class="title">
                {{cvenavs['About']['submenu']['Metrics']['items']['Reserved CVE IDs']['label']}}
              </h2>
              <p>
                Comparison of Reserved <router-link to="/ResourcesSupport/Glossary?activeTerm=glossaryCVEID">CVE IDs</router-link> by year from
                1999 to present.
              </p>
              <p class="cve-help-text">
                A “Reserved” CVE ID is the initial state for a <router-link to="/ResourcesSupport/Glossary?activeTerm=glossaryRecord">
                CVE Record</router-link>; when the associated CVE ID is reserved by a CNA.
              </p>
              <div class="field is-grouped is-grouped-right">
                <label class="label">Sort by:</label>
                <div class="control">
                  <div class="select">
                    <select name="partner" v-model="reservedIdsSortByYearSelectedOption" aria-label="select a sort by direction">
                      <option v-bind:value='"desc"'>Year (newest to oldest)</option>
                      <option v-bind:value='"asc"'>Year (oldest to newest)</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="cve-scrollx-table-container">
                <table class="table is-striped is-hoverable cve-border-dark-blue">
                  <thead>
                    <th>Year</th>
                    <th v-for="year in reservedIds.years" :key="year">{{year}}</th>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Count</th>
                      <td v-for="count in reservedIds.counts['all']" :key="count.key">{{count}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- <div class="cve-white-bg-gray-border-container pt-2">
              <h2 :id="cvenavs['About']['submenu']['Metrics']['items']['Reserved but Public (RBP) CVE IDs']['anchorId']"
                class="title">
                {{cvenavs['About']['submenu']['Metrics']['items']['Reserved but Public (RBP) CVE IDs']['label']}}
              </h2>
              <p>
                Comparison of <router-link to="/ResourcesSupport/Glossary?activeTerm=glossaryRBP">Reserved but Public (RBP)</router-link> CVE IDs
                by quarter for all years from 2017 to present.
              </p>
              <p class="cve-help-text">
                An “RBP” is a <router-link to="/ResourcesSupport/Glossary?activeTerm=glossaryCVEID">CVE ID</router-link> in the Reserved state that
                is referenced in one or more public resources, but for which the details have not be published in a
                <router-link to="/ResourcesSupport/Glossary?activeTerm=glossaryRecord">CVE Record</router-link>.
              </p>
              <div class="cve-scrollx-table-container">
                <table class="table is-striped is-hoverable cve-border-dark-blue">
                  <thead>
                    <th>Year</th>
                    <th>2021</th>
                    <th>2020</th>
                    <th>2019</th>
                    <th>2018</th>
                    <th>2017</th>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Qtr4</th>
                      <td>N/A</td>
                      <td>553</td>
                      <td>1,465</td>
                      <td>2,934</td>
                      <td>3,653</td>
                    </tr>
                    <tr>
                      <th>Qtr3</th>
                      <td>350</td>
                      <td>690</td>
                      <td>2,432</td>
                      <td>2,716</td>
                      <td>4,011</td>
                    </tr>
                    <tr>
                      <th>Qtr2</th>
                      <td>550</td>
                      <td>565</td>
                      <td>2,422</td>
                      <td>2,991</td>
                      <td>4,114</td>
                    </tr>
                    <tr>
                      <th>Qtr1</th>
                      <td>498</td>
                      <td>603</td>
                      <td>2,962</td>
                      <td>3,585</td>
                      <td>4,326</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> -->
            <div class="cve-white-bg-gray-border-container pt-2">
              <h2 :id="cvenavs['About']['submenu']['Metrics']['items']['CVE Record Publications by All CNAs Combined Versus CNA-LRs']['anchorId']"
                class="title">
                {{cvenavs['About']['submenu']['Metrics']['items']['CVE Record Publications by All CNAs Combined Versus CNA-LRs']['label']}}
              </h2>
              <p>
                Comparison of <router-link to="/ResourcesSupport/Glossary?activeTerm=glossaryRecord">CVE Records</router-link> published by all
                <router-link to="/ResourcesSupport/Glossary?activeTerm=glossaryCNA">CNAs</router-link> combined versus CVE Records published by the
                two <router-link to="/ResourcesSupport/Glossary?activeTerm=glossaryCNALR">CNAs of Last Resort (CNA-LRs)</router-link>
                (<router-link to="/PartnerInformation/ListofPartners/partner/CISA">CISA</router-link><sup>*</sup> and
                <router-link to="/PartnerInformation/ListofPartners/partner/mitre"> MITRE</router-link>)
                from 1999 to present.
              </p>
              <div class="field is-grouped is-grouped-right">
                <label class="label">Sort by:</label>
                <div class="control">
                  <div class="select">
                    <select name="partner" v-model="recordPublicationsSortByYearSelectedOption" aria-label="select a sort by direction">
                      <option v-bind:value='"desc"'>Year (newest to oldest)</option>
                      <option v-bind:value='"asc"'>Year (oldest to newest)</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="cve-scrollx-table-container">
                <table class="table is-striped is-hoverable cve-border-dark-blue">
                    <thead>
                      <tr>
                        <th>Year</th>
                        <th v-for="year in recordPublications.years" :key="year.key">{{year}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>All CNAs</th>
                        <td v-for="percentage in recordPublications.countsByCnaType['All CNAs']" :key="percentage.key">{{percentage}}%</td>
                      </tr>
                      <tr>
                        <th>CNA-LRs</th>
                        <td v-for="percentage in recordPublications.countsByCnaType['CNA-LRs']" :key="percentage.key">{{percentage}}%</td>
                      </tr>
                  </tbody>
                </table>
                <p class="cve-help-text mt-3">*Note: CISA became a CNA-LR in calendar year 2020.</p>
              </div>
            </div>
          </div>
            <div class="cve-white-bg-gray-border-container pt-2">
              <h2 :id="cvenavs['About']['submenu']['Metrics']['items']['CNA Partners Added by Year']['anchorId']" class="title">
                {{cvenavs['About']['submenu']['Metrics']['items']['CNA Partners Added by Year']['label']}}
              </h2>
              <p>
                Comparison of
                <router-link to="/ResourcesSupport/Glossary?activeTerm=glossaryCNA">
                  CVE Numbering Authority (CNA)
                </router-link>
                partners added by year from 1999 to present.
              </p>
              <p class="cve-help-text">
                Currently, there are
                <router-link to="/PartnerInformation/ListofPartners">
                  {{parseInt(this.$store.state.partnerCounts.role.CNA) + parseInt(this.$store.state.partnerCounts.role['CNA-LR'])}} CNAs
                </router-link>
                ({{this.$store.state.partnerCounts.role.CNA}} CNAs and {{this.$store.state.partnerCounts.role['CNA-LR']}} CNA-LRs) from
                <router-link to="/ProgramOrganization/CNAs#CNAProgramGrowth#map">
                  {{this.$store.state.partnerCounts.countriesCount}} countries
                </router-link>
                and 1 no country affiliation participating in the CVE Program.
              </p>
              <div class="field is-grouped is-grouped-right">
                <label class="label">Sort by:</label>
                <div class="control">
                  <div class="select">
                    <select name="partner" v-model="partnerAddedSortByYearSelectedOption" aria-label="select a sort by direction">
                      <option v-bind:value='"desc"'>Year (newest to oldest)</option>
                      <option v-bind:value='"asc"'>Year (oldest to newest)</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="cve-scrollx-table-container">
                <table class="table is-striped is-hoverable cve-border-dark-blue">
                  <thead>
                    <th>Year</th>
                    <th v-for="year in cnasAddedByYear.years" :key="year">{{year}}</th>
                  </thead>
                  <tbody>
                    <tr v-for="month in [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October',
                      'November', 'December']" :key="`added-${month}`">
                      <th>{{month}}</th>
                      <td v-for="value in cnasAddedByYear.months[month]" :key="value.key">{{value}}</td>
                    </tr>
                    <tr>
                      <th>TOTAL</th>
                      <td v-for="total in cnasAddedByYear.total" :key="total.key">{{total}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          <SurveyLinkComponent :surveyLink="cvenavs['About']['submenu']['Metrics']['surveyLink']" />
        </main>
      </div>
      <div class="column is-3 is-hidden-touch">
        <NavigationSidebar :nav="cvenavs['About']" />
      </div>
    </div>
  </div>
</template>

<script>
import NavigationSidebar from '@/components/NavigationSidebar.vue';
import SurveyLinkComponent from '@/components/SurveyLinkComponent.vue';
import MetricsData from '@/assets/data/metrics.json';

export default {
  name: 'Metrics',
  components: {
    NavigationSidebar,
    SurveyLinkComponent,
  },
  props: {
    cvenavs: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      publishedRecordsSortByYearSelectedOption: 'desc',
      reservedIdsSortByYearSelectedOption: 'desc',
      recordPublicationsSortByYearSelectedOption: 'desc',
      partnerAddedSortByYearSelectedOption: 'desc',
      publishedCveRecords: {},
      reservedIds: {},
      recordPublications: {},
      cnasAddedByYear: {},
    };
  },
  created() {
    this.getSortedPublishedCveRecords('desc');
    this.getSortedReseredCveIds('desc');
    this.getSortedRecordPublications('desc');
    this.getSortedPartnerAddedByYear('desc');
  },
  watch: {
    publishedRecordsSortByYearSelectedOption() {
      this.getSortedPublishedCveRecords();
    },
    reservedIdsSortByYearSelectedOption() {
      this.getSortedReseredCveIds();
    },
    recordPublicationsSortByYearSelectedOption() {
      this.getSortedRecordPublications();
    },
    partnerAddedSortByYearSelectedOption() {
      this.getSortedPartnerAddedByYear();
    },
  },
  methods: {
    sortMetricsDataByYear(data, sortDirection) {
      const sortModifier = sortDirection === 'asc' ? 1 : -1;
      data.sort((annualData1, annualData2) => {
        if (annualData1.year < annualData2.year) return -1 * sortModifier;
        if (annualData1.year > annualData2.year) return 1 * sortModifier;

        return 0;
      });
    },
    getSortedPublishedCveRecords() {
      const publishedCveRecords = { years: [], quarters: {}, total: [] };
      const metrics = MetricsData.publishedCveRecords;
      this.sortMetricsDataByYear(metrics, this.publishedRecordsSortByYearSelectedOption);

      metrics.forEach((annualData) => {
        publishedCveRecords.years.push(annualData.year);

        let total = 0;
        annualData.data.forEach((data) => {
          if (!Object.prototype.hasOwnProperty.call(publishedCveRecords.quarters, data.quarter)) {
            publishedCveRecords.quarters[data.quarter] = [];
          }
          publishedCveRecords.quarters[data.quarter].push(data.value);

          total += /\d/.test(data.value) ? parseInt(data.value.replace(/,/, ''), 10) : 0;
        });
        publishedCveRecords.total.push(Intl.NumberFormat('en-US').format(total));
      });

      this.publishedCveRecords = publishedCveRecords;
    },
    getSortedReseredCveIds() {
      const sortedReservedIdsByYear = { years: [], counts: {} };
      const metrics = MetricsData.reservedCveIds;
      this.sortMetricsDataByYear(metrics, this.reservedIdsSortByYearSelectedOption);

      metrics.forEach((annualData) => {
        sortedReservedIdsByYear.years.push(annualData.year);

        annualData.data.forEach((data) => {
          if (!Object.prototype.hasOwnProperty.call(sortedReservedIdsByYear.counts, data.quarter)) {
            sortedReservedIdsByYear.counts[data.quarter] = [];
          }
          sortedReservedIdsByYear.counts[data.quarter].push(data.value);
        });
      });

      this.reservedIds = sortedReservedIdsByYear;
    },
    getSortedRecordPublications() {
      const sortedRecordPublications = { years: [], countsByCnaType: {} };
      const metrics = MetricsData.cveRecordPublications;
      this.sortMetricsDataByYear(metrics, this.recordPublicationsSortByYearSelectedOption);

      metrics.forEach((annualData) => {
        if (annualData.year > 2015) {
          sortedRecordPublications.years.push(annualData.year);

          annualData.data.forEach((data) => {
            if (!Object.prototype.hasOwnProperty.call(sortedRecordPublications.countsByCnaType, data.heading)) {
              sortedRecordPublications.countsByCnaType[data.heading] = [];
            }
            sortedRecordPublications.countsByCnaType[data.heading].push(data.percentage);
          });
        }
      });

      if (this.recordPublicationsSortByYearSelectedOption === 'asc') {
        sortedRecordPublications.years[0] = '1999-2016';
      } else {
        sortedRecordPublications.years[sortedRecordPublications.years.length - 1] = '2016-1999';
      }

      this.recordPublications = sortedRecordPublications;
    },
    getSortedPartnerAddedByYear() {
      const cnasAddedByYear = { years: [], months: {}, total: [] };
      const metrics = MetricsData.cnasAddedByYear;
      this.sortMetricsDataByYear(metrics, this.partnerAddedSortByYearSelectedOption);

      metrics.forEach((annualData) => {
        cnasAddedByYear.years.push(annualData.year);

        let total = 0;
        annualData.data.forEach((data) => {
          if (!Object.prototype.hasOwnProperty.call(cnasAddedByYear.months, data.month)) {
            cnasAddedByYear.months[data.month] = [];
          }
          cnasAddedByYear.months[data.month].push(data.value);

          total += /\d/.test(data.value) ? parseInt(data.value.replace(/,/, ''), 10) : 0;
        });
        cnasAddedByYear.total.push(Intl.NumberFormat('en-US').format(total));
      });

      this.cnasAddedByYear = cnasAddedByYear;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../assets/style/globals.scss';
//Equally space data cells in metrics tables
td {
  width: auto !important;
}
</style>

<template>
  <div id="cve-secondary-page-main-container" class="container">
    <div class="columns is-centered">
      <div class="column is-8-desktop cve-main-column-content-width is-12-tablet">
        <main id="cve-main-page-content" role="main">
          <div class="content">
            <h1 class="title">
              News Article Archives
            </h1>
            <p>News articles from 2020 and earlier are archived below. There is one PDF for each calendar year. For the latest news articles, go to
              the <router-link to="/Media/News/AllNews">News</router-link> page.</p>
            <h2 class="title mt-4 mb-1 is-size-4">By Year</h2>
            <div class="content">
              <ul>
                <li v-for="archive in archivedNews" :key="archive.id">
                  <router-link :to="archive.url" target="_blank">{{archive.year}}</router-link>
                </li>
              </ul>
            </div>
            <SurveyLinkComponent :surveyLink="cvenavs['AllNews']['submenu']['Archives']['submenu']['News Articles']['surveyLink']" />
          </div>
        </main>
      </div>
      <div class="column is-3"  :class="isNavSidebarHiddenTouch ? 'is-hidden-touch' : ''">
        <NavigationSidebar :nav="cvenavs['AllNews']" :isNavSidebarHiddenTouch="isNavSidebarHiddenTouch"/>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationSidebar from '@/components/NavigationSidebar.vue';
import SurveyLinkComponent from '@/components/SurveyLinkComponent.vue';
import newsData from '@/assets/data/news.json';

export default {
  name: 'NewsArchives',
  components: {
    NavigationSidebar,
    SurveyLinkComponent,
  },
  data() {
    return {
      archivedNews: newsData.archiveNews,
      isNavSidebarHiddenTouch: false,
    };
  },
  props: {
    cvenavs: {
      type: Object,
      required: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/assets/style/globals.scss';

.is-active {
  color: $black;
}

</style>

<template>
  <div id="cve-secondary-page-main-container">
    <div class="columns is-centered">
      <div class="column is-8-desktop cve-main-column-content-width is-12-tablet">
        <main id="cve-main-page-content" role="main">
          <div class="content">
            <h1 class="title">{{partner.organizationName}}</h1>
            <ExternalLinkMessage/>
            <div class="stepsToReport">
              <p class="is-size-5 has-text-weight-bold"> Steps to Report a Vulnerability or Request a CVE ID</p>
              <nav class="level cve-border-dark-blue">
                <div class="level-item has-text-centered">
                  <div>
                    <p class="mb-1">Step 1: Read disclosure policy</p>
                    <ul class="tile-body cve-task-tile-list mt-1 mb-0 ml-3 pb-1 pl-0" v-if="partner.disclosurePolicy.length > 0">
                      <li class="cve-list-no-bullet" v-for="(disclosurePolicy, index) in partner.disclosurePolicy"
                        :key="partner.shortName + 'disclosurePolicy' + index">
                        <a v-if="((disclosurePolicy.url).length) > 0" :href="disclosurePolicy.url" target="_blank">
                          View {{disclosurePolicy.label}}
                        </a>
                        <span class="pb-2 mt-1" v-else>{{disclosurePolicy.label}}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="level-item has-text-centered">
                  <div>
                    <p class="mb-1">Step 2: Contact</p>
                    <div v-for="(contact, index) in partner.contact" :key="partner.shortName+'contact'+index">
                      <ul class="tile-body cve-task-tile-list mt-1 mb-0 ml-3 pb-1 pl-0" v-if="contact.email.length > 0">
                        <li class="cve-list-no-bullet" v-for="(email, index) in contact.email" :key="partner.shortName + 'email' + index">
                          <span v-if="email.label == email.emailAddr">
                            Email <a :href="'mailto:' + $sanitize(email.emailAddr)"> {{email.label}}</a>
                          </span>
                          <span v-else><a :href="'mailto:' + $sanitize(email.emailAddr)"> {{email.label}}</a></span>
                        </li>
                      </ul>
                      <ul class="tile-body cve-task-tile-list mt-1 mb-0 ml-3 pb-1 pl-0" v-if="contact.form.length > 0">
                        <li class="cve-list-no-bullet" v-for="(form, index) in contact.form" :key="partner.shortName + 'form' + index">
                          <a :href="form.url" target="_blank"> {{form.label}}</a>
                        </li>
                      </ul>
                      <ul class="tile-body cve-task-tile-list mt-1 mb-0 ml-3 pb-1 pl-0" v-if="contact.contact.length > 0">
                        <li class="cve-list-no-bullet" v-for="(contactPage, index) in contact.contact"
                          :key="partner.shortName + 'contactPage' + index">
                          <a :href="contactPage.url" target="_blank"> {{contactPage.label}}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
          <table class="table cve-border-dark-blue is-striped is-hoverable mb-1">
            <caption class="is-hidden">{{partner.organizationName}}</caption>
            <tbody>
              <tr>
                <th style="width: 20%">Scope</th>
                <td><span v-html="$sanitize(partner.scope)"></span></td>
              </tr>
              <tr v-if="partner.CNA.roles.length > 0">
                <th style="width: 20%">Program Role</th>
                <td>
                  <ul class="ml-0 pl-0">
                    <li class="cve-list-no-bullet" v-for="role in partner.CNA.roles" :key="role">
                      {{role.role}} <span v-if="role.helpText.length > 0">({{role.helpText}})</span>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr v-if="partner.CNA.root.organizationName.toLowerCase() != 'n/a'">
                <th style="width: 20%">Root</th>
                <td>
                  <router-link :to="`/PartnerInformation/ListofPartners/partner/${partner.CNA.root.shortName}`">
                    {{partner.CNA.root.organizationName}}
                  </router-link>
                </td>
              </tr>
              <tr v-if="partner.CNA.TLR.organizationName.toLowerCase() != 'n/a'">
                <th style="width: 20%">Top-Level Root</th>
                <td>
                  <span v-if="partner.CNA.TLR.organizationName.toLowerCase() == 'none'">None</span>
                  <router-link v-else :to="`/PartnerInformation/ListofPartners/partner/${partner.CNA.TLR.shortName}`">
                    {{partner.CNA.TLR.organizationName}}
                  </router-link>
                </td>
              </tr>
              <tr v-if="partner.securityAdvisories.hasOwnProperty('alerts') || partner.securityAdvisories.hasOwnProperty('advisories')">
                <th style="width: 20%">Security Advisories</th>
                <td>
                  <span v-if="partner.securityAdvisories.hasOwnProperty('alerts')">
                    <ul v-if="partner.securityAdvisories.alerts.length > 0" class="ml-0 pl-0 mt-3">
                      <li class="cve-list-no-bullet" v-for="(alert, index) in partner.securityAdvisories.alerts"
                        :key="partner.shortName + 'alert' + index">
                        <a :href="alert.url" target="_blank">View {{alert.label}}</a>
                      </li>
                    </ul>
                  </span>
                  <span v-if="partner.securityAdvisories.hasOwnProperty('advisories')">
                    <ul v-if="Object.keys(partner.securityAdvisories.advisories).length > 0" class="ml-0 pl-0 mt-3"
                      :class="{'mt-0': (partner.securityAdvisories.alerts.length) ? true : false}">
                      <li class="cve-list-no-bullet" v-for="(advisories, index) in partner.securityAdvisories.advisories"
                        :key="partner.shortName + 'advisories' + index">
                        <a v-if="Object.keys(advisories.url).length > 0" :href="advisories.url" target="_blank">View {{advisories.label}}</a>
                        <span v-else>{{advisories.label}}</span>
                      </li>
                    </ul>
                  </span>
                </td>
              </tr>
              <tr v-if="partner.CNA.type.length > 0">
                <th style="width: 20%">Organization Type</th>
                <td>
                  <ul class="ml-0 pl-0 mt-3" v-if="partner.CNA.type.length > 0">
                    <li class="cve-list-no-bullet" v-for="type in partner.CNA.type" :key="type">{{type}}</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <th style="width: 20%">Country*</th>
                <td>{{partner.country == 'n/a' ? 'No country affiliation' : partner.country}}</td>
              </tr>
            </tbody>
          </table>
          <p class="mb-2 cve-help-text">* Self-identified by CNA</p>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import partnersList from '@/assets/data/CNAsList.json';

export default {
  computed: {
    partner() {
      const { shortName } = this.$route.params;
      return partnersList.find((partner) => partner.shortName === shortName);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/style/globals.scss';
@import 'bulma';
@import 'bulma-timeline';

.heading {
  text-transform: none;
  font-size: 18px;
}

.level {
  align-items: flex-start;
}
.level-item {
  padding-top: 8px;
}

.tile-body {
  text-align: center;
}
</style>

<template>
  <div id="cve-secondary-page-main-container" class="container">
    <div class="columns is-centered">
      <div class="column is-8-desktop cve-main-column-content-width">
        <main id="cve-main-page-content" role="main">
          <h1 :id="cvenavs['Partner Information']['submenu']['List of Partners']['id']" class="title">List of Partners</h1>
          <div class="content">
            <ExternalLinkMessage/>
            <p>
              Contact and other information for Top-Level Roots, Roots, CNAs, and CNA-LRs are below. Select the partner’s name to view its
              information.
            </p>
            <section class="cve-accordian">
              <div class="message">
                <div class="message-header cve-base-light-gray-borders-bg">
                  <p class="message-label has-text-weight-bold" style="margin-top: auto; margin-bottom: auto;">
                    Request a CVE ID / Update a CVE Record
                  </p>
                  <button class="button" @click="toggleAccordianItem('report')"
                    :aria-expanded="!accordian['report'] ? 'true' : 'false'"
                    aria-controls="report">
                    <span class="icon is-small">
                      <p id="record-alttext" class="is-hidden">
                        {{accordian['report'] ? 'collapse' : 'expand'}}
                      </p>
                      <font-awesome-icon :icon="accordian['report'] ? 'plus' : 'minus'"
                        aria-hidden="false" focusable="true" aria-labelledby="record-alttext"/>
                    </span>
                  </button>
                </div>
                <div class="message-body" :class="{'is-hidden': accordian['report']}" id="report">
                  <div class="block" id="report">
                    <ol class="ml-4">
                      <li class="pb-1">To request a CVE ID from a CNA or CNA-LR, follow the
                        <router-link to="/ReportRequest/ReportRequestForNonCNAs#RequestCVEID">Request a CVE ID</router-link>
                        instructions.
                      </li>
                      <li class="pb-1">To request an update to a CVE Record by a CNA, follow the
                        <router-link to="/ReportRequest/ReportRequestForNonCNAs#UpdateCVERecord">Update a CVE Record</router-link>
                        instructions.
                      </li>
                    </ol>
                    <p>
                      View the <router-link to="/About/Process">CVE Process</router-link> page to learn
                      about the CVE Record Lifecycle.
                    </p>
                  </div>
                </div>
              </div>
              <div class="message">
                <div class="message-header cve-base-light-gray-borders-bg">
                  <p class="message-label has-text-weight-bold" style="margin-top: auto; margin-bottom: auto;">Program Roles / Organization Types</p>
                  <button class="button" @click="toggleAccordianItem('programRoles')"
                    :aria-expanded="!accordian['programRoles'] ? 'true' : 'false'"
                    aria-controls="programRoles">
                    <span class="icon is-small">
                      <p id="programRolesalttext" class="is-hidden">
                        {{accordian['programRoles'] ? 'collapse' : 'expand'}}
                      </p>
                      <font-awesome-icon :icon="accordian['programRoles'] ? 'plus' : 'minus'"
                        aria-hidden="false" focusable="true" aria-labelledby="programRolesalttext"/>
                    </span>
                  </button>
                </div>
                <div class="message-body" :class="{'is-hidden': accordian['programRoles']}" id="programRoles">
                  <div class="block">
                    <p class="subtitle">Program Roles</p>
                    <ul>
                      <li class="pb-2" v-for="cnaRoles in partners.roles" :key="cnaRoles.term">
                        <span class="has-text-weight-bold">{{cnaRoles.term}}:</span> <span v-html="$sanitize(cnaRoles.definition)"/>
                      </li>
                    </ul>
                    <p class="subtitle">Organization Types</p>
                    <ul>
                      <li class="pb-2" v-for="org in partners.orgType" :key="org.id">
                        <span class="has-text-weight-bold">{{org.term}}:</span> <span v-html="$sanitize(org.definition)"/>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </div><!-- end content -->
          <h2 class="title">Search</h2>
          <div class="field has-addons is-grouped-right">
            <div class="control is-expanded has-icons-right">
              <input v-model="searchTerms" class="input cve-search-input" type="text" placeholder="Enter search terms" @keyup.enter="search">
            </div>
            <div class="field is-grouped is-grouped-right pl-4">
              <button class="button cve-button-accent-warm cve-button-ghost" :class="{'is-loading': isSearching, 'disabled': searchTerms.length < 1}"
                @click="search" :aria-disabled="searchTerms.length < 1">
                <span class="icon is-small is-left">
                  <p id="searchIcon" class="is-hidden">search</p>
                  <font-awesome-icon icon="search" aria-labelledby="searchIcon" aria-hidden="false" />
                </span>
              </button>
              <button class="button is-light control ml-2" :class="{'is-loading': isSearching}" @click="clearSearch" v-show="searchTerms.length > 0">
                <span class="icon is-small is-left">
                  <p id="loadingIcon" class="is-hidden">loading</p>
                  <font-awesome-icon icon="times" aria-labelledby="loadingIcon" aria-hidden="false" />
                </span>
              </button>
            </div>
          </div> <!-- end search field groups -->
          <div class="cve-accordian mt-1">
            <button href="/SiteSearch" @click="toggleAccordianItem('searchTips')" class="button cve-button-outline cve-base-light-gray-borders-bg">
              <span class="is-size-7 has-text-weight-bold">Search Tips</span>
              <span class="icon cve-icon-xxs">
                <font-awesome-icon :icon="accordian['searchTips'] ? 'plus' : 'minus'"
                  aria-hidden="false" focusable="true" aria-labelledby="record-alttext"/>
              </span>
            </button>
            <div class="message pt-1 pr-3 pb-1 pl-3" :class="{'is-hidden': accordian['searchTips']}" id="report">
              <p>
                Search for a CNA partner by <b>name</b>, <b>scope</b> keyword(s), <b>program role</b>, or <b>organization type</b>.<br/>
                For example: “Top-Level Root”, “Root”, or “CNA-LR”, finds partners by their Program Role.
              </p>
              <p class="mt-2">
                Use <b>“Sort by”</b> to refine your search.
              </p>
            </div>
          </div>
          <hr/>
          <div class="mt-3">
            <p v-if="searchTerms.length < 1" class="has-text-weight-bold">
              {{CNAsList.length}} partners
            </p>
            <p v-else>
              {{CNAsList.length}} result{{CNAsList.length > 1 ? 's': ''}}
            </p>
          </div>
          <div class="field is-grouped is-grouped-right">
            <label class="label">Show:</label>
            <div class="control">
              <div class="select">
                <select v-model="showPartnersCount" aria-label="select how many partners to show">
                  <option>10</option>
                  <option>All</option>
                </select>
              </div>
            </div>
            <label class="label">Sort by:</label>
            <div class="control">
              <div class="select">
                <select name="partner" v-model="sortBy" aria-label="select a sort by direction">
                  <option v-bind:value='{field: "organizationName", direction: "asc"}'>Partner (A to Z)</option>
                  <option v-bind:value='{field: "organizationName", direction: "desc"}'>Partner (Z to A)</option>
                </select>
              </div>
            </div>
          </div>
          <div class="table-container" v-show="!emptySearchResults">
            <table class="table is-striped is-hoverable cve-border-dark-blue is-fullwidth mb-1">
              <thead>
                <tr>
                  <th scope="col" class="is-hidden-desktop" >Partner</th>
                  <th scope="col" class="is-hidden-touch" style="width: 30%">Partner</th>
                  <th scope="col">Scope</th>
                  <th scope="col">
                    Program Role
                  </th>
                  <th scope="col">
                    Organization Type
                  </th>
                  <th scope="col">
                    Country*
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(CNA, index) in pagination.currentCNAstoDisplay" :key="index">
                  <th class="is-hidden-desktop has-background-white" style="border: unset !important; background: transparent !important;" scope="col"
                    data-label="Partner">
                    <router-link tabindex="0" :to="'/PartnerInformation/ListofPartners/partner/'+ $sanitize(CNA.shortName)">
                      {{CNA.organizationName}}
                    </router-link>
                  </th>
                  <th class="is-hidden-touch has-background-white" style="width: 30%;" scope="row" data-label="Partner">
                    <router-link tabindex="0" :to="'/PartnerInformation/ListofPartners/partner/'+ $sanitize(CNA.shortName)">
                      {{CNA.organizationName}}
                    </router-link>
                  </th>
                  <td data-label="Scope" v-html="$sanitize(CNA.scope)"></td>
                  <td data-label="Program Role">
                    <span v-for="(role, index) in CNA.CNA.roles" :key="role+index">
                      {{role.role}}{{index  == CNA.CNA.roles.length-1 ? '' : ', '}}
                    </span>
                  </td>
                  <td data-label="Organization Type">{{CNA.CNA.type.join(', ')}}</td>
                  <td data-label="Country*">{{CNA.country == 'n/a' ? 'No country affiliation' : CNA.country}}</td>
                </tr>
              </tbody>
            </table>
            <p class="mb-2 cve-help-text">* Self-identified by CNA</p>
            <nav class="pagination is-centered" aria-label="pagination">
              <button class="pagination-previous button cve-button cve-button-outline"
                :class="{'disabled': pagination.currentPage > 1 ? false : true}" :aria-disabled="pagination.currentPage > 1 ? false : true"
                @click=getPreviousPage>
                <span class="icon">
                  <p id="previousIcon" class="is-hidden">previous page button</p>
                  <font-awesome-icon icon="angle-left" aria-labelledby="previousIcon" aria-hidden="false"/>
                </span>
              </button>
              <button class ="pagination-next button cve-button cve-button-outline"
                :class="{'disabled': pagination.currentPage >= pagination.totalPages ? true : false}"
                :aria-disabled="pagination.currentPage >= pagination.totalPages ? true : false" @click=getNextPage>
                <span class="icon">
                  <p id="nextIcon" class="is-hidden">next page button</p>
                  <font-awesome-icon icon="angle-right" aria-labelledby="nextIcon" aria-hidden="false" />
                </span>
              </button>
              <ul class ="pagination-list" >
                <li v-for="pageNumber in pagination.pages" :key="pageNumber" class="mt-1">
                  <a tabindex="0" :class="pageNumber == pagination.currentPage ? 'pagination-link cve-button cve-button-outline is-current' :
                    'pagination-link cve-button cve-button-outline'" @click=updatePagination(pageNumber) @keyup.enter=updatePagination(pageNumber)
                    :aria-label="'Goto page ' + pageNumber" :aria-current="(pageNumber == pagination.currentPage) ? 'page' : false">{{pageNumber}}</a>
                </li>
              </ul>
            </nav>
          </div>
          <p class="has-text-centered is-size-5" v-show="emptySearchResults">
            No results found for <span class="has-text-weight-bold">{{escapedSearchTerms}}</span>
          </p>
        </main>
        <SurveyLinkComponent :surveyLink="cvenavs['Partner Information']['submenu']['List of Partners']['surveyLink']" />
      </div>
      <div class="column is-3 is-hidden-touch">
        <NavigationSidebar :nav="cvenavs['Partner Information']" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import NavigationSidebar from '@/components/NavigationSidebar.vue';
import SurveyLinkComponent from '@/components/SurveyLinkComponent.vue';
import CNAData from '@/assets/data/CNAsList.json';
import GlossaryData from '@/assets/data/glossaryEntries.json';
import PartnerRolesOrgTypes from '@/assets/data/partnersRolesOrgTypes.json';

export default {
  name: 'ListOfParners',
  components: {
    NavigationSidebar,
    SurveyLinkComponent,
  },
  props: {
    cvenavs: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      CNAsList: CNAData,
      searchTerms: '',
      escapedSearchTerms: '',
      isSearching: false,
      emptySearchResults: false,
      filters: {
        organizationName: true,
        scope: true,
        role: true,
        type: true,
      },
      sortBy: {
        field: 'organizationName',
        direction: 'asc',
      },
      showPartnersCount: 10,
      pagination: {
        totalItems: 0,
        currentPage: 1,
        pageSize: 10,
        pageSizeMin: 10,
        pageSizeMax: 1000,
        totalPages: 0,
        startIndex: 0,
        endIndex: 0,
        maxPages: 17,
        pages: [],
        currentCNAstoDisplay: [],
      },
      partners: PartnerRolesOrgTypes,
      isPartnerRoleVisible: false,
      isParterOrgType: false,
      isFocus: false,
      accordian: { report: true, programRoles: true, searchTips: true },
    };
  },
  created() {
    this.sortedCNAs();
    this.paginate();
    this.getPartnerRolesDefinition();
  },
  watch: {
    sortBy() {
      this.sortedCNAs();
    },
    searchTerms() {
      this.search();
    },
    showPartnersCount() {
      this.pagination.pageSize = this.showPartnersCount === 'All' ? this.pagination.pageSizeMax : this.pagination.pageSizeMin;
      this.paginate();
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.CNAsList.length / this.pagination.pageSize);
    },
  },
  methods: {
    search() {
      this.isSearching = true;
      try {
        this.escapedSearchTerms = this.searchTerms.split(' ').map((word) => this.$sanitize(word, { disallowedTagsMode: 'escape' }));
        const splitTermsList = this.escapedSearchTerms.filter((term) => term.length > 0);

        const regexExp = new RegExp(splitTermsList.join('|'), 'gi');

        this.CNAsList = CNAData.filter((partner) => {
          const roles = [];
          partner.CNA.roles.forEach((roleObj) => {
            roles.push(roleObj.role);
          });

          return (partner.organizationName.toLowerCase().match(regexExp)
          || partner.scope.toLowerCase().match(regexExp) || this.isSearchTermFound(roles, regexExp)
          || this.isSearchTermFound(partner.CNA.type, regexExp) || partner.country.toLowerCase().match(regexExp));
        });

        if (this.CNAsList.length > 0) {
          this.sortedCNAs();
          this.emptySearchResults = false;
        } else {
          this.escapedSearchTerms = this.escapedSearchTerms.join(' ');
          this.emptySearchResults = true;
        }
      } catch (error) {
        this.escapedSearchTerms = this.escapedSearchTerms.join(' ');
        this.emptySearchResults = true;
      }

      this.isSearching = false;
    },
    isSearchTermFound(list, regexExp) {
      let found = false;
      list.forEach((element) => {
        if (element.toLowerCase().match(regexExp)) found = true;
      });

      return found;
    },
    clearSearch() {
      this.searchTerms = '';
      this.CNAsList = CNAData;
      this.paginate();
    },
    sortedCNAs() {
      const sortModifier = (this.sortBy.direction === 'asc') ? 1 : -1;
      this.CNAsList.sort((CNA1, CNA2) => {
        const upperCasedValue1 = CNA1[this.sortBy.field].toUpperCase();
        const upperCasedValue2 = CNA2[this.sortBy.field].toUpperCase();
        if (upperCasedValue1 < upperCasedValue2) {
          return -1 * sortModifier;
        }
        if (upperCasedValue1 > upperCasedValue2) {
          return 1 * sortModifier;
        }
        return 0;
      });

      this.paginate();
    },
    paginate() {
      // calculate total pages
      this.pagination.totalItems = this.CNAsList.length;
      const totalPages = Math.ceil(this.pagination.totalItems / this.pagination.pageSize);

      // ensure current page isn't out of range
      if (this.pagination.currentPage < 1) {
        this.pagination.currentPage = 1;
      } else if (this.pagination.currentPage > totalPages) {
        this.pagination.currentPage = totalPages;
      }

      let startPage; let
        endPage;
      if (totalPages <= this.pagination.maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = totalPages;
      } else {
        // total pages more than max so calculate start and end pages
        const maxPagesBeforeCurrentPage = Math.floor(this.pagination.maxPages / 2);
        const maxPagesAfterCurrentPage = Math.ceil(this.pagination.maxPages / 2) - 1;
        if (this.pagination.currentPage <= maxPagesBeforeCurrentPage) {
          // current page near the start
          startPage = 1;
          endPage = this.pagination.maxPages;
        } else if (this.pagination.currentPage + maxPagesAfterCurrentPage >= totalPages) {
          // current page near the end
          startPage = totalPages - this.pagination.maxPages + 1;
          endPage = totalPages;
        } else {
          // current page somewhere in the middle
          startPage = this.pagination.currentPage - maxPagesBeforeCurrentPage;
          endPage = this.pagination.currentPage + maxPagesAfterCurrentPage;
        }
      }

      // calculate start and end item indexes
      this.pagination.startIndex = (this.pagination.currentPage - 1) * this.pagination.pageSize;
      this.pagination.endIndex = Math.min(this.pagination.startIndex + this.pagination.pageSize - 1, this.pagination.totalItems - 1);

      // create an array of pages to loop through in the pager control
      this.pagination.pages = Array.from(Array((endPage + 1) - startPage).keys()).map((i) => startPage + i);
      this.pagination.totalPages = totalPages;

      this.pagination.currentCNAstoDisplay = this.CNAsList.slice(this.pagination.startIndex, this.pagination.endIndex + 1);
    },
    updatePagination(pageNumber) {
      this.pagination.pageSize = 10;
      this.pagination.currentPage = pageNumber;
      this.paginate();
    },
    getPreviousPage() {
      this.pagination.currentPage -= 1;
      this.paginate();
    },
    getNextPage() {
      this.pagination.currentPage += 1;
      this.paginate();
    },
    getPartnerRolesDefinition() {
      const cnaRoles = ['CVE Numbering Authority (CNA)', 'CVE Numbering Authority of Last Resort (CNA-LR)', 'Root',
        'Top-Level Root (TL-Root)'];
      const htmlFilterRegex = /(<([^>]+)>)/ig;
      this.partners.roles = GlossaryData.filter((term) => {
        if (cnaRoles.includes(term.term)) {
          const termCopy = term;
          termCopy.definition = term.definition.replace(htmlFilterRegex, '');
          return termCopy;
        }
        return null;
      });
    },
    toggleAccordianItem(id) {
      Vue.set(this.accordian, id, !this.accordian[id]);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/style/globals.scss';
@import '../../assets/style/elements/cveTableStacked.scss';

.disabled {
  background-color: #dbdbdb;
  border-color: #dbdbdb;
  box-shadow: none;
  color: #7a7a7a;
  opacity: 0.5;
  cursor: not-allowed;
}

.collapse {
  display: inline;
  margin-left: 5px;
}
</style>

<template>
  <div id="cve-secondary-page-main-container" class="container">
    <div class="columns is-centered">
      <div class="column is-8-desktop cve-main-column-content-width is-12-tablet">
        <main id="cve-main-page-content" role="main">
          <div class="content">
            <h1 :id="cvenavs['Partner Information']['submenu']['Partner']['id']" class="title">Partner</h1>
            <p>
              The CVE Program actively partners with community members worldwide to help grow CVE content and expand its use. See below to learn more
              about the roles of organizations participating as <a href='/ResourcesSupport/Glossary?activeTerm=glossaryCNA'>CVE Numbering Authorities
              (CNAs)</a>, <a href='/ResourcesSupport/Glossary?activeTerm=glossaryRoot'>Roots</a>, and
              <a href='/ResourcesSupport/Glossary?activeTerm=glossaryTLRoot'> Top-Level Roots</a>.
            </p>

            <h2 :id="cvenavs['Partner Information']['submenu']['Partner']['items']['HowToBecomeAPartner']['anchorId']"
            class="title">
              {{cvenavs['Partner Information']['submenu']['Partner']['items']['HowToBecomeAPartner']['label']}}
            </h2>
            <p>
              Contact the CVE Program:
              <ul>
                <li>
                  Access the CVE Program Request forms using the "Become a Partner" button below.
                </li>
                <li>
                  For any partner type: Select “Request information on the CVE Numbering Authority (CNA) Program” from the dropdown menu.
                </li>
              </ul>
            </p>
            <div class="buttons is-centered pt-4">
              <a id="cve-partner-button" href="https://cveform.mitre.org/" target="_blank" class="button cve-button">
                <span>Become a Partner</span>
                <span class="icon">
                  <font-awesome-icon icon="external-link-alt" aria-labelledby="extenalLinkBecomeAPartner"/>
                </span>
              </a>
            </div>
            <p class="is-italic">
              Ask questions by accessing the
              <span class="icon-text">
                <a href="https://cveform.mitre.org/" target="_blank">CVE Program Request forms
                <span class="icon is-size-7 cve-icon-xxs">
                  <font-awesome-icon icon="external-link-alt" aria-labelledby="extenalLinkCVERequestForm1" aria-hidden="false" focusable="false"/>
                </span>
                </a>
              </span>
              and selecting “Other” from the dropdown menu.
            </p>

            <h2 :id="cvenavs['Partner Information']['submenu']['Partner']['items']['CNA']['anchorId']" class="title">
              {{cvenavs['Partner Information']['submenu']['Partner']['items']['CNA']['label']}}
            </h2>
            <p>
              Organizations from around the world partner with the CVE Program as <a href='/ResourcesSupport/Glossary?activeTerm=glossaryCNA'>CVE
              Numbering Authorities (CNAs)</a> to assign CVE IDs and publish CVE Records for vulnerabilities within their distinct, agreed upon scope.
              Types of organizations include vendors, researchers, open source, CERTs, hosted services, bug bounty providers, and consortiums, but
              others are also welcome.
            </p>
            <h3 class="title"></h3>
            <div class="tile is-ancestor cve-task-tiles-container">
              <div class="tile is-parent">
                <article class="tile is-child cve-border-dark-blue">
                  <h3 class="title cve-task-tile-header">
                    Benefits
                  </h3>
                  <ul class="tile-body cve-task-tile-list">
                    <li class="cve-task-tile-list-item">
                      Demonstrate mature vulnerability management practices and a commitment to cybersecurity to current and potential customers.
                    </li>
                    <li class="cve-task-tile-list-item">
                      Communicate value-added vulnerability information to your customer base.
                    </li>
                    <li class="cve-task-tile-list-item">
                      Control the CVE publication release process for vulnerabilities in your scope.
                    </li>
                    <li class="cve-task-tile-list-item">
                      Assign CVE IDs without having to share embargoed information with another CNA.
                    </li>
                    <li class="cve-task-tile-list-item">
                      Streamline vulnerability disclosure processes.
                    </li>
                  </ul>
                </article>
              </div>
            </div>
            <div class="tile is-parent cve-task-left-tile">
              <div class="tile is-parent cve-task-left-tile">
                <article class="tile is-child cve-border-dark-blue">
                  <h3 class="title cve-task-tile-header">
                    Cost
                  </h3>
                  <ul class="tile-body cve-task-tile-list">
                    <li class="cve-task-tile-list-item">
                      There is no monetary fee.
                    </li>
                    <li class="cve-task-tile-list-item">
                      CNAs volunteer their own time for their own benefit.
                    </li>
                    <li class="cve-task-tile-list-item">
                      There is no contract to sign.
                    </li>
                  </ul>
                </article>
              </div>
              <div class="tile is-parent cve-task-left-tile">
                <article class="tile is-child cve-border-dark-blue">
                  <h3 class="title cve-task-tile-header">
                    Requirements
                  </h3>
                  <ul class="tile-body cve-task-tile-list">
                    <li class="cve-task-tile-list-item">
                      Have a public vulnerability disclosure policy.
                    </li>
                    <li class="cve-task-tile-list-item">
                      Have a public source for new vulnerability disclosures.
                    </li>
                    <li class="cve-task-tile-list-item">
                      Agree to the <router-link to='/Legal/TermsOfUse'>CVE Program Terms of Use</router-link>.
                    </li>
                  </ul>
                </article>
              </div>
            </div>

            <h2 :id="cvenavs['Partner Information']['submenu']['Partner']['items']['Root']['anchorId']" class="title">
              {{cvenavs['Partner Information']['submenu']['Partner']['items']['Root']['label']}}
            </h2>
            <p>
              Organizations wishing to partner with the CVE Program as a <router-link to='/ResourcesSupport/Glossary?activeTerm=glossaryRoot'>Root
              </router-link> or <router-link to='/ResourcesSupport/Glossary?activeTerm=glossaryTLRoot'>Top-Level Root</router-link> should
              <router-link to='/PartnerInformation/Partner#HowToBecomeAPartner'>contact</router-link> the CVE Program to learn more.
            </p>

            <h2 :id="cvenavs['Partner Information']['submenu']['Partner']['items']['TopRoot']['anchorId']" class="title">
              {{cvenavs['Partner Information']['submenu']['Partner']['items']['TopRoot']['label']}}
            </h2>
            <p>
              Organizations wishing to partner with the CVE Program as a
              <router-link to='/ResourcesSupport/Glossary?activeTerm=glossaryTLRoot'>Top-Level Root</router-link> or
              <router-link to='/ResourcesSupport/Glossary?activeTerm=glossaryRoot'>Root</router-link> should
              <router-link to='/PartnerInformation/Partner#HowToBecomeAPartner'>contact</router-link> the CVE Program to learn more.
            </p>

            <h2 class="title"
              :id="cvenavs['Partner Information']['submenu']['Partner']['items']['OnboardingProcess']['anchorId']">
              {{cvenavs['Partner Information']['submenu']['Partner']['items']['OnboardingProcess']['label']}}
            </h2>
            <div class="cve-round-edges-black-border cve-dark-purple-bg">
              <figure class="image cve-no-margin">
                <img src="@/assets/images/CNAOnboardingInfographic.png" alt="CNA onboarding infographic: contact, register, meet requirements,
                onboard, and join." />
              </figure>
            </div>

            <div class="timeline">
              <header class="timeline-header">
                <span class="tag is-medium">Start</span>
              </header>
              <div class="timeline-item">
                <div class="timeline-marker">1</div>
                <div class="timeline-content">
                  <h3 class="title">Contact</h3>
                  <p>Prospective partner <router-link to='/PartnerInformation/Partner#HowToBecomeAPartner'>contacts</router-link> the CVE Program.</p>
                </div>
              </div>
              <div class="timeline-item">
                <div class="timeline-marker is-3">2</div>
                <div class="timeline-content">
                  <h3 class="title">Register</h3>
                  <p>Prospective partner completes registration form.</p>
                  <p>Details collected on the form include basic organizational information such as name, country, business sector,
                  representative’s contact details, and communications preferences. Also collected are estimated annual number of CVE IDs needed,
                  expected scope of coverage, disclosure policy and security advisory links, and availability for a one-hour guidance and program
                  orientation meeting.</p>
                </div>
              </div>
              <div class="timeline-item">
                <div class="timeline-marker">3</div>
                <div class="timeline-content">
                  <h3 class="title">Meet Requirements</h3>
                  <p>Prospective partner meets requirements.</p>
                  <section class="cve-accordian">
                    <div class="message">
                      <div class="message-header">
                        <h4 class="title">Requirements</h4>
                        <button class="button cve-process-list-btn" @click="toggleAccordianItem('requirements')"
                          :aria-expanded="!accordian['requirements'] ? 'true' : 'false'"
                          aria-controls="requirements">
                          <span class="icon is-small">
                            <p id="requirements-alttext" class="is-hidden">
                              {{accordian['requirements'] ? 'collapse' : 'expand'}}
                            </p>
                            <font-awesome-icon :icon="accordian['requirements'] ? 'plus' : 'minus'"
                            aria-hidden="false" focusable="true" aria-labelledby="requirements-alttext"/>
                          </span>
                        </button>
                      </div>
                      <div class="message-body" :class="{'is-hidden': accordian['requirements']}" id="requirements">
                        <div class="block">
                          <p>
                            <ul>
                              <li>CNA
                                <ul>
                                  <li>Have a public vulnerability disclosure policy.</li>
                                  <li>Have a public source for new vulnerability disclosures.</li>
                                  <li>Agree to the <router-link to='/Legal/TermsOfUse'>CVE Terms of Use</router-link>.</li>
                                </ul>
                              </li>
                              <li>Root
                                <ul>
                                  <li>Be an active CNA and meet all CNA requirements.</li>
                                  <li>Have organizational experience in the specific technical or geographical scope.</li>
                                  <li>Ability to successfully manage one or more CNAs or other Roots.</li>
                                  <li>Ability to participate in CVE Program and Root coordination activities.</li>
                                </ul>
                              </li>
                              <li>Top-Level Root
                                <ul>
                                  <li>Be an active CNA or Root and meet all CNA or Root requirements.</li>
                                  <li>Ability to maintain funding and resources to support scope. </li>
                                </ul>
                              </li>
                            </ul>
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div class="timeline-item is-5">
                <div class="timeline-marker">4</div>
                <div class="timeline-content">
                  <h3 class="title">Onboard</h3>
                  <p>Organization is onboarded.</p>
                  <section class="cve-accordian">
                    <div class="message">
                      <div class="message-header">
                        <h4 class="title">Onboarding</h4>
                        <button class="button cve-process-list-btn" @click="toggleAccordianItem('onboarding')"
                          :aria-expanded="!accordian['onboarding'] ? 'true' : 'false'"
                          aria-controls="onboarding">
                          <span class="icon is-small">
                            <p id="onboarding-alttext" class="is-hidden">
                              {{accordian['onboarding'] ? 'collapse' : 'expand'}}
                            </p>
                            <font-awesome-icon :icon="accordian['onboarding'] ? 'plus' : 'minus'"
                            aria-hidden="false" focusable="true" aria-labelledby="onboarding-alttext"/>
                          </span>
                        </button>
                      </div>
                      <div class="message-body" :class="{'is-hidden': accordian['onboarding']}" id="onboarding">
                        <div class="block">
                          <p>
                            <ul>
                              <li>CNA
                                <ul>
                                  <li>View <router-link to='/ResourcesSupport/Resources#cnaOnboarding'>guidance videos</router-link>.</li>
                                  <li>Attend an introductory session.</li>
                                  <li>Successfully complete practice examples.</li>
                                </ul>
                              </li>
                              <li>Root
                                <ul><li>Attend coordination sessions.</li></ul>
                              </li>
                              <li>Top-Level Root
                                <ul><li>Attend coordination sessions.</li></ul>
                              </li>
                            </ul>
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div class="timeline-item">
                <div class="timeline-marker">5</div>
                <div class="timeline-content">
                  <h3 class="title">Join</h3>
                  <p>
                    Organization is added to the <router-link to='/PartnerInformation/ListofPartners'>List of Partners</router-link> and announced as
                    a new CVE Program partner on the CVE website, in program newsletters, on partner mail lists, and on social media.
                  </p>
                </div>
              </div>
              <footer class="timeline-header">
                <span class="tag is-medium">End</span>
              </footer>
            </div>
          </div>
          <SurveyLinkComponent :surveyLink="cvenavs['Partner Information']['submenu']['Partner']['surveyLink']" />
        </main>
      </div>

      <div class="column is-3 is-hidden-touch">
        <NavigationSidebar :nav="cvenavs['Partner Information']" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import NavigationSidebar from '@/components/NavigationSidebar.vue';
import SurveyLinkComponent from '@/components/SurveyLinkComponent.vue';

export default {
  name: 'Partner',
  components: {
    NavigationSidebar,
    SurveyLinkComponent,
  },
  props: {
    cvenavs: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      accordian: { requirements: true, onboarding: true },
    };
  },
  methods: {
    toggleAccordianItem(id) {
      Vue.set(this.accordian, id, !this.accordian[id]);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../assets/style/globals.scss';
@import 'bulma';
@import 'bulma-timeline';

.button.cve-process-list-btn {
  border-color: white;
}

.button.cve-process-list-btn:focus {
  outline: none;
}

.button.cve-process-list-btn:hover {
  border: none;
}

#cve-partner-button {
  background-color: $theme-color-accent-warm;
  font-weight: bold;
  border-width: 1px;
  border-style: solid;
  color: #162e51;
  border-color: #162e51;
}
#cve-partner-button:hover{
  border-color: #b5b5b5;
  color: #363636;
}

</style>

<template>
  <aside class="menu">
    <p class="menu-label cve-capitalize-first-letter">Table of Contents</p>
    <ul class="menu-list cve-capitalize-first-letter">
      <li class="mb-0" v-for="(sectionObj, pageSectionIndex) in nav.pageSections" :key="pageSectionIndex">
        <span class="is-flex" style="justify-content: left; align-items:baseline; align-content: flex-start !important; width: inherit">
          <span>{{pageSectionIndex + 1}} </span>
          <router-link :to="'#' + $sanitize(sectionObj.sectionId)">{{ sectionObj.sectionTitle }}</router-link>
        </span>
        <ul>
          <li v-for="(subSectionObj, index) in sectionObj.subSections" :key="index">
            <span class="is-flex" style="justify-content: left; align-items:baseline; align-content: flex-start !important; width: inherit">
              <span>{{pageSectionIndex + 1}}.{{index + 1}} </span>
              <router-link class="has-text-left" :to="'#' + $sanitize(subSectionObj.subSectionId)">{{ subSectionObj.subSectionTitle }}</router-link>
              <button class="button is-text cve-button-submenu-toggle"
                @click="toggleSubmenuItem(subSectionObj.subSectionId)"
                :class="{'is-hidden': subSectionObj.subSubSections.length < 1}">
                <span class="icon cve-icon-xxs">
                  <font-awesome-icon :icon="selectedSubmenu.includes(subSectionObj.subSectionId) ? 'minus' : 'plus'"/>
                </span>
              </button>
            </span>

            <ul v-show="selectedSubmenu.includes(subSectionObj.subSectionId)">
              <li v-for="(subSubSectionObj, index) in subSectionObj.subSubSections" :key="index">
                  <span class="is-flex" style="justify-content: left; align-items:baseline; align-content: flex-start !important; width: inherit">
                    <span>{{pageSectionIndex + 1}}.{{index + 1}}.{{index + 1}} </span>
                    <router-link :to="'#' + $sanitize(subSubSectionObj.subSubSectionId)">{{ subSubSectionObj.subSubSectionTitle }}</router-link>
                  </span>
              </li>
            </ul> <!-- end page subSubSection list -->

          </li>
        </ul> <!-- end page subSection list -->
      </li>
    </ul> <!-- end page section list -->
    <ul class="menu-list cve-capitalize-first-letter">
      <li v-for="(sectionObj, index) in nav.appendices" :key="index">
        <router-link class="pl-0" :to="'#' + $sanitize(sectionObj.sectionId)">{{ sectionObj.sectionTitle }}</router-link>
        <ul>
          <li v-for="(subSectionObj, index) in sectionObj.subSections" :key="index">
            <span class="is-flex" style="justify-content: left; align-items:baseline; align-content: flex-start !important; width: inherit">
              <router-link class="has-text-left" :to="'#' + $sanitize(subSectionObj.subSectionId)">{{ subSectionObj.subSectionTitle }}</router-link>
              <button class="button is-text cve-button-submenu-toggle"
                @click="toggleSubmenuItem(subSectionObj.subSectionId)"
                :class="{'is-hidden': subSectionObj.subSubSections.length < 1}">
                <span class="icon cve-icon-xxs">
                  <font-awesome-icon :icon="selectedSubmenu.includes(subSectionObj.subSectionId) ? 'minus' : 'plus'"/>
                </span>
              </button>
            </span>

            <ul v-show="selectedSubmenu.includes(subSectionObj.subSectionId)">
              <li v-for="(subSubSectionObj, index) in subSectionObj.subSubSections" :key="index">
                <span class="is-flex" style="justify-content: left; align-items:baseline; align-content: flex-start !important; width: inherit">
                  <span>{{index + 1}} </span>
                  <router-link class="has-text-left" :to="'#' + $sanitize(subSubSectionObj.subSubSectionId)">
                    {{ subSubSectionObj.subSubSectionTitle }}
                  </router-link>
                </span>
              </li>
            </ul> <!-- end appendix subSubSection list -->
          </li>
        </ul> <!-- end appendix subSection list -->
      </li>
    </ul> <!-- end appendix section list -->
  </aside>
</template>

<script>
import Vue from 'vue';

export default {
  props: {
    nav: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isActive: true,
      isOpen: -1,
      accordian: {},
      accordianList: [],
      selectedSubmenu: [],
    };
  },
  created() {
    this.setAccordian(false, '', 'subSectionId', 'subSections', ['pageSections', 'appendices']);
  },
  methods: {
    setAccordian(isHidden, parentId, childId, subSectionPropertyName, topLevelProperties) {
      Object.keys(this.nav).forEach((topLevelSection) => {
        if (topLevelProperties.includes(topLevelSection)) {
          this.nav[topLevelSection].forEach((item) => {
            item[subSectionPropertyName].forEach((subSection) => {
              Vue.set(this.accordian, (parentId === '') ? subSection[childId] : `${item[parentId]}${subSection[childId]}`, isHidden);
            });
          });
        }
      });
    },
    toggleAccordianItem(id) {
      Vue.set(this.accordian, id, !this.accordian[id]);
    },
    toggleSubmenuItem(id) {
      if (this.selectedSubmenu.includes(id)) {
        const index = this.selectedSubmenu.indexOf(id);
        this.selectedSubmenu.splice(index, 1);
      } else {
        this.selectedSubmenu.push(id);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../assets/style/globals.scss';

</style>

<template>
  <div id="cve-secondary-page-main-container" class="container">
    <div class="columns is-centered">
      <div class="column is-8-desktop cve-main-column-content-width is-12-tablet">
        <main id="cve-main-page-content" role="main">
          <div class="content">
            <h1 :id="cvenavs['Resources & Support']['submenu']['Resources']['id']" class="title">
              {{cvenavs['Resources & Support']['submenu']['Resources']['label']}}</h1>
            <ExternalLinkMessage/>
            <h2 :id="cvenavs['Resources & Support']['submenu']['Resources']['items']['CVE Program Idea Tracker']['anchorId']"
              class="title">
              {{cvenavs['Resources & Support']['submenu']['Resources']['items']['CVE Program Idea Tracker']['label']}}
            </h2>
            <div class="columns">
              <div class="column pl-3 is-two-thirds">
                <div class="tile is-ancestor cve-task-tiles-container">
                  <div class="tile is-parent cve-task-left-tile">
                    <article class="tile is-child cve-border-dark-blue">
                      <h3 class="title cve-task-tile-header">
                        Innovative Ideas &amp; New Feature Requests
                      </h3>
                      <ul class="mt-0 tile-body cve-task-tile-list">
                        <li>
                        <a href="https://github.com/CVEProject/Ideas" target="_blank">CVE Program Ideas Repository on GitHub.com</a>
                          <br/><span class="cve-help-text">The CVE Program welcomes innovative ideas and new feature requests from the community
                          </span>
                        </li>
                      </ul>
                    </article>
                  </div>
                </div>
              </div>
            </div>
            <h2 :id="cvenavs['Resources & Support']['submenu']['Resources']['items']['CVE ID Requesters/Vulnerability Researchers']['anchorId']"
              class="title">
              {{cvenavs['Resources & Support']['submenu']['Resources']['items']['CVE ID Requesters/Vulnerability Researchers']['label']}}
            </h2>
            <div class="columns">
              <div class="column pl-3 is-two-thirds">
                <div class="tile is-ancestor cve-task-tiles-container">
                  <div class="tile is-parent cve-task-left-tile">
                    <article class="tile is-child cve-border-dark-blue">
                      <h3 class="title cve-task-tile-header">
                        Requester/Researcher Resources
                      </h3>
                      <ul class="mt-0 tile-body cve-task-tile-list">
                        <li><router-link to="/Resources/General/Key-Details-Phrasing.pdf"
                          target="_blank">Key Details Phrasing (PDF, 0.3MB)</router-link>
                          <br/><span class="cve-help-text">How to write a description for a CVE Record</span>
                        </li>
                        <li><router-link to="/Resources/General/End-of-Life-EOL-Assignment-Process.pdf" target="_blank">
                          End-of-Life (EOL) Assignment Process (PDF, 0.3MB)</router-link>
                          <br/><span class="cve-help-text">Establishes the policy for the EOL CVE assignment process</span>
                        </li>
                        <li>
                          <router-link to="/Resources/General/Policies/CVE-Record-Dispute-Policy.pdf" target="_blank">
                          CVE Record Dispute Policy (PDF, 0.3MB)
                          </router-link>
                          <br/><span class="cve-help-text">CVE Program policy and procedure for disputing a CVE Record</span>
                        </li>
                        <li>
                          <router-link to="/Media/News/item/podcast/2022/05/03/Researchers-and-PSIRTs-Working-Well">Researchers and PSIRTs Working
                          Well Together (Podcast)</router-link>
                          <br/><span class="cve-help-text">What to expect when reporting vulnerabilities to a Product Security Incident Response Team
                          (PSIRT), how to get the best outcome, supported versus EOL products, CNA scopes, timing of patch versus publication of CVE
                          Record, and more</span>
                      </li>
                      </ul>
                    </article>
                  </div>
                </div>
              </div>
            </div>
            <h2 :id="cvenavs['Resources & Support']['submenu']['Resources']['items']['CVE Numbering Authorities (CNAs)']['anchorId']"
              class="title">
              {{cvenavs['Resources & Support']['submenu']['Resources']['items']['CVE Numbering Authorities (CNAs)']['label']}}
            </h2>
            <div class="tile is-ancestor cve-task-tiles-container">
              <div class="tile is-parent cve-task-left-tile">
                <article class="tile is-child cve-border-dark-blue">
                  <h3 class="title cve-task-tile-header">
                    Become a CNA
                  </h3>
                  <ul class="mt-0 tile-body cve-task-tile-list">
                    <li><router-link to="/ProgramOrganization/CNAs">Overview</router-link></li>
                    <li><router-link to="/PartnerInformation/Partner">Business Benefits</router-link></li>
                    <li><router-link to="/PartnerInformation/Partner">Requirements</router-link></li>
                    <li><router-link to="/PartnerInformation/Partner">How to Join</router-link></li>
                  </ul>
                </article>
              </div>
              <div class="tile is-parent">
                <article class="tile is-child cve-border-dark-blue">
                  <h3 class="title cve-task-tile-header">
                    Best Practices
                  </h3>
                  <ul class="mt-0 tile-body cve-task-tile-list">
                    <li>
                      <router-link to="/Resources/Roles/Cnas/CVE-Record-Management-Guidelines.pdf" target="_blank">
                        CVE Record Management Guidelines (PDF, 0.9MB)
                      </router-link>
                      <br/><span class="cve-help-text">Protecting the integrity of CVE Records is important. This document provides best practices for
                      CNAs when modifying CVE Records
                      </span>
                    </li>
                  </ul>
                </article>
              </div>
            </div>
            <div class="tile is-ancestor cve-task-tiles-container" id="RulesPolicies">
              <div class="tile is-parent cve-task-left-tile">
                <article class="tile is-child cve-border-dark-blue">
                  <h3 class="title cve-task-tile-header">
                    Rules &amp; Policies
                  </h3>
                <ul class="mt-0 tile-body cve-task-tile-list">
                  <li>
                    <router-link to="/ResourcesSupport/AllResources/CNARules">
                      CVE Numbering Authority (CNA) Rules, Version 3.0
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/Resources/General/Policies/CVE-Record-Dispute-Policy.pdf" target="_blank">
                      CVE Record Dispute Policy (PDF, 0.3MB)
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/Resources/General/Policies/EOL-Assignment-Policy.pdf" target="_blank">
                      EOL Assignment Policy (PDF, 0.4MB)
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/Resources/General/Policies/Inactive-CNA-Policy.pdf" target="_blank">
                      Inactive CNA Policy (PDF, 0.4MB)
                    </router-link>
                  </li>
                </ul>
                </article>
              </div>
              <div class="tile is-parent">
                <article class="tile is-child cve-border-dark-blue">
                  <h3 class="title cve-task-tile-header">
                    ID Reservation/Record Publishing
                  </h3>
                  <ul class="mt-0 tile-body cve-task-tile-list">
                    <li>
                      <router-link to="/ReportRequest/ReserveIDsPublishRecordsForCNAs">Self-Service Automation</router-link>
                    </li>
                    <li>
                      <router-link to="/ResourcesSupport/FAQs#pc_cnaretrieve_reserve_cve_ids_list">
                        How to Retrieve a List of Your Organization’s RESERVED CVE IDs
                      </router-link>
                    </li>
                  </ul>
                </article>
              </div>
            </div>
            <div class="tile is-ancestor cve-task-tiles-container" id="CVEServices">
              <div class="tile is-parent cve-task-left-tile">
                <article class="tile is-child cve-border-dark-blue">
                  <h3 class="title cve-task-tile-header">
                    CVE Services
                  </h3>
                  <ul class="mt-0 tile-body cve-task-tile-list">
                    <li>
                     <router-link to="/AllResources/CveServices">
                      CVE Services Overview &amp; Status
                     </router-link>
                    </li>
                    <li>
                      <router-link to="/AllResources/CveServices#current-status">
                        CVE Services – Known Issues
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/AllResources/CveServices#obtaining-credentials">
                        How to Obtain Credentials
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/AllResources/CveServices#cve-record-workflow">
                        Workflow Tutorial
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/AllResources/CveServices#using-cve-services-clients">
                        Using CVE Services Clients
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/AllResources/CveServices#build-your-own-client">
                        Test Environment
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/Downloads#current-format">
                        CVE JSON 5.0 Records Bulk Downloads
                      </router-link>
                    </li>
                  </ul>
                </article>
              </div>
              <div class="tile is-parent">
                <article class="tile is-child cve-border-dark-blue">
                  <h3 class="title cve-task-tile-header">
                    CVE Services Videos
                  </h3>
                  <ul class="mt-0 tile-body cve-task-tile-list">
                    <li>
                      <a href="https://youtu.be/K2OoRpDhzss" target="_blank">Introduction to CVE Services</a>
                    </li>
                    <li>
                      <a href="https://youtu.be/Hu5KZ6X1WS4" target="_blank">CVE Services (2023)</a>
                    </li>
                    <li>
                      <a href="https://youtu.be/YWZECqzRI7M" target="_blank">CVE JSON 5.0: Introduction/Tips/Q&amp;A</a>
                    </li>
                    <li>
                      <a href="https://youtu.be/ok_ZMTCVmhw" target="_blank">CVE JSON 5.0 Guidance (2023)</a>
                    </li>
                    <li>
                      <a href="https://youtu.be/dcEJ2t2jwhE" target="_blank">CVE JSON 5.0 Experiences (2023)</a>
                    </li>
                    <li>
                      <a href="https://youtu.be/KSNvidMTKNA" target="_blank">Obtaining Account Credentials</a>
                    </li>
                    <li>
                      <a href="https://youtu.be/k6eRdnzgk9E" target="_blank">CVE Record Workflow Overview</a>
                    </li>
                    <li>
                      <a href="https://youtu.be/o3V-fmQpC0o" target="_blank">Vulnogram Demo</a>
                    </li>
                    <li>
                      <a href="https://youtu.be/kqeM4noRnsg" target="_blank">cveClient Demo</a>
                    </li>
                    <li>
                      <a href="https://youtu.be/qOCd4S8JMa0" target="_blank">cvelib Demo</a>
                    </li>
                  </ul>
                </article>
              </div>
            </div>
            <div class="tile is-ancestor cve-task-tiles-container" id="Workshops">
              <div class="tile is-parent cve-task-left-tile" style="text-align: center !important;">
                <article class="tile is-child cve-border-dark-blue">
                  <h3 class="title cve-task-tile-header">
                    Workshops
                  </h3>
                  <ul class="mt-0 tile-body cve-task-tile-list">
                    <li>
                      <router-link to="/Resources/General/CVE-Workshop-Presentations-Fall-2023.pdf" target="_blank">
                        CVE Program Workshop Presentations Fall 2023 (PDF, 2.5MB)
                      </router-link>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/playlist?list=PLWfD9RQVdJ6cWmIHqUIiJ_A5i1DrtG0wI" target="_blank">
                        CVE Program Workshop Fall 2023 (7 Videos)
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/playlist?list=PLWfD9RQVdJ6etGbopVxE5Nb-8TzjY5cl9" target="_blank">
                        CVE Services Workshop Fall 2022 (10 Videos)
                      </a>
                    </li>
                  </ul>
                </article>
              </div>
              <div class="tile is-parent">
                <article class="tile is-child cve-border-dark-blue">
                  <h3 class="title cve-task-tile-header">
                    Additional Resources
                  </h3>
                  <ul class="mt-0 tile-body cve-task-tile-list">
                    <li>
                      <router-link to="/Media/News/item/blog/2022/10/18/The-CNA-Mentoring-Program">
                        The CNA Mentoring Program
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/Resources/General/End-of-Life-EOL-Assignment-Process.pdf" target="_blank">
                        EOL Assignment Process (PDF, 0.3MB)
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/ResourcesSupport/Resources#cnaOnboarding">
                        Onboarding Videos for CNAs
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/Media/News/item/blog/2022/10/25/CNAs-from-Around-the-World">
                        “Our CVE Story” blogs by CNAs
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/Media/News/Podcasts">
                        Podcasts for CNAs
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/PartnerInformation/ListofPartners">
                        List of all CNA Partners
                      </router-link>
                    </li>
                  </ul>
                </article>
              </div>
            </div>
            <h3 id="cnaOnboarding">CNA Onboarding</h3>
            <p>
              Please review the following documents in the order that they appear.
            </p>
            <p>
              Videos are located on the <a href="https://www.youtube.com/channel/UCUHd2XFDsKH8kjMZQaSKpDQ" target="_blank">CVE Program Channel</a>
              on YouTube. PowerPoint slides are available in English, Japanese, and Spanish.
            </p>
            <div class="table-container">
              <table class="table is-striped is-hoverable cve-border-dark-blue cve-border-bottom-unset">
                <thead>
                  <tr>
                    <th style="width: 40%">Title</th>
                    <th>Description</th>
                    <th>Slides</th>
                    <th>Video</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-label="Title" style="width: 40%" class="has-text-weight-bold">CVE Program Overview</td>
                    <td data-label="Description">
                      An introduction to the CVE Program, including what is CVE,
                      goals of the program, who operates the program, and program organization.
                    </td>
                    <td data-label="Slides">
                      <p class="mb-0"><a href="/Resources/Roles/Cnas/CVE_Program_Overview.pptx" download>English</a></p>
                      <p class="mb-0"><a href="/Resources/Roles/Cnas/CVE_Program_Overview_ja.pptx" download>Japanese</a></p>
                      <p class="mb-0"><a href="/Resources/Roles/Cnas/CVE_Program_Overview_sp.pptx" download>Spanish</a></p>
                    </td>
                    <td data-label="Video">
                      <a class="cve-social-media-icon" href="https://youtu.be/rrNYEUNsXOY" target="_blank">
                        <span class="icon">
                          <p id="youTubeIcon3" class="is-hidden">YouTube video CVE Program Overview</p>
                          <font-awesome-icon size="lg" :icon="['fab', 'youtube']" aria-labelledby="youTubeIcon3" aria-hidden="false" />
                        </span>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td data-label="Title" style="width: 40%" class="has-text-weight-bold">Becoming a CNA</td>
                    <td data-label="Description">
                      An introduction to becoming a CVE Numbering Authority (CNA) with an overview of what
                      defines a CNA, how the CVE Program is organized, how to organize your CNA program, how to define the scope of
                      what you will cover, internal CNA processes, CNA resources, and ways to get involved in the CNA community.
                    </td>
                    <td data-label="Slides">
                      <p class="mb-0"><a href="/Resources/Roles/Cnas/Becoming_a_CNA.pptx" download>English</a></p>
                      <p class="mb-0"><a href="/Resources/Roles/Cnas/Becoming_a_CNA_ja.pptx" download>Japanese</a></p>
                      <p class="mb-0"><a href="/Resources/Roles/Cnas/Becoming_a_CNA_sp.pptx" download>Spanish</a></p>
                    </td>
                    <td data-label="Video">
                      <a class="cve-social-media-icon" href="https://youtu.be/13b5cuZR7CQ" target="_blank">
                        <span class="icon">
                          <p id="youTubeIcon4" class="is-hidden">YouTube video becoming a CNA</p>
                          <font-awesome-icon size="lg" :icon="['fab', 'youtube']" aria-labelledby="youTubeIcon4" aria-hidden="false" />
                        </span>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td data-label="Title" style="width: 40%" class="has-text-weight-bold">Assigning CVE IDs</td>
                    <td data-label="Description">Describes in detail how CNAs assign CVE IDs to vulnerabilities.</td>
                    <td data-label="Slides">
                      <p class="mb-0"><a href="/Resources/Roles/Cnas/Assigning_CVE_IDs.pptx" download>English</a></p>
                      <p class="mb-0"><a href="/Resources/Roles/Cnas/Assigning_CVE_IDs_ja.pptx" download>Japanese</a></p>
                      <p class="mb-0"><a href="/Resources/Roles/Cnas/Assigning_CVE_IDs_sp.pptx" download>Spanish</a></p>
                    </td>
                    <td data-label="Video">
                      <a class="cve-social-media-icon" href="https://youtu.be/JQYq-mxLo-U" target="_blank">
                        <span class="icon">
                          <p id="youTubeIcon6" class="is-hidden">YouTube video assigning a CVE ID</p>
                          <font-awesome-icon size="lg" :icon="['fab', 'youtube']"  aria-labelledby="youTubeIcon6" aria-hidden="false" />
                        </span>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td data-label="Title" style="width: 40%" class="has-text-weight-bold">CVE Record Creation</td>
                    <td data-label="Description">
                      Once a CNA has assigned a CVE ID(s), performed coordination to fix the vulnerability, and published the
                      vulnerability information, the next step is to populate the CVE Record (previously “CVE Entry”). This video
                      details how CNAs create CVE Records.</td>
                    <td data-label="Slides">
                      <p class="mb-0"><a href="/Resources/Roles/Cnas/CVE_Record_Creation.pptx" download>English</a></p>
                      <p class="mb-0"><a href="/Resources/Roles/Cnas/CVE_Record_Creation_ja.pptx" download>Japanese</a></p>
                      <p class="mb-0"><a href="/Resources/Roles/Cnas/CVE_Record_Creation_sp.pptx" download>Spanish</a></p>
                    </td>
                    <td data-label="Video">
                      <a class="cve-social-media-icon" href="https://youtu.be/se-yM_LureQ" target="_blank">
                        <span class="icon">
                          <p id="youTubeIcon7" class="is-hidden">YouTube video CVE Record (previously CVE Entry) Creation</p>
                          <font-awesome-icon size="lg" :icon="['fab', 'youtube']" aria-labelledby="youTubeIcon7" aria-hidden="false" />
                        </span>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> <!-- table container -->
            <h2 :id="cvenavs['Resources & Support']['submenu']['Resources']['items']['CVE Working Groups']['anchorId']"
              class="title">
              {{cvenavs['Resources & Support']['submenu']['Resources']['items']['CVE Working Groups']['label']}}
            </h2>
            <div class="tile is-ancestor cve-task-tiles-container">
              <div class="tile is-parent cve-task-left-tile">
                <article class="tile is-child cve-border-dark-blue">
                  <h3 class="title cve-task-tile-header">
                    Charters
                  </h3>
                  <ul class="mt-0 tile-body cve-task-tile-list">
                    <li>
                      <a href="https://github.com/CVEProject/automation-working-group/blob/master/AWG_Charter.md" target="_blank">
                        Automation Working Group (AWG) Charter
                      </a>
                    </li>
                    <li>
                      <router-link to="/Resources/Roles/WorkingGroups/SPWG/SPWG-Charter.pdf" target="_blank">
                        Strategic Planning Working Group (SPWG) Charter (PDF, 0.2MB)
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/Resources/Roles/WorkingGroups/CNACWG/CNACWG-Charter.pdf" target="_blank">
                        CNA Coordination Working Group (CNACWG) Charter (PDF, 0.1MB)
                      </router-link>
                    </li>
                    <li>
                      <a href="https://github.com/CVEProject/quality-workgroup/blob/main/README.md" target="_blank">
                        Quality Working Group (QWG) Charter
                      </a>
                    </li>
                    <li>
                      <router-link to="/Resources/Roles/WorkingGroups/OCWG/OCWG-Charter.pdf" target="_blank">
                        Outreach and Communications Working Group (OCWG) Charter (PDF, 0.1MB)
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/Resources/Roles/WorkingGroups/VCEWG/VCEWG-Charter.pdf" target="_blank">
                        Vulnerability Conference and Events Working Group (VCEWG) Charter (PDF, 0.2MB)
                      </router-link>
                    </li>
                  </ul>
                </article>
              </div>
              <div class="tile is-parent cve-task-left-tile">
                <article class="tile is-child cve-border-dark-blue">
                  <h3 class="title cve-task-tile-header">
                    Other Resources
                  </h3>
                  <ul class="mt-0 tile-body cve-task-tile-list">
                    <li>
                      <router-link to="/Media/News/item/podcast/2021/09/07/CVE-Working-Groups-What-They">CVE
                      Working Groups, What They Are and How They Improve CVE (Podcast)</router-link>
                    </li>
                    <li>
                      <router-link to="/ProgramOrganization/WorkingGroups">How to Join a Working Group</router-link>
                    </li>
                    <li>
                      <router-link to="/Resources/Roles/WorkingGroups/CVE-Working-Group-Operations-Handbook-v1-0.pdf" target="_blank">
                        CVE WG OperationsHandbook (PDF, 0.1MB)
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/ProgramOrganization/WorkingGroups#AutomationWorkingGroupAWG">AWG Repositories &amp; Projects</router-link>
                    </li>
                    <li>
                      <router-link to="/ProgramOrganization/WorkingGroups#QualityWorkingGroupQWG">QWG Repositories &amp; Projects</router-link>
                    </li>
                    <li>
                      <router-link to="/ProgramOrganization/WorkingGroups#StrategicPlanningWorkingGroupSPWG">SPWG Repository</router-link>
                    </li>
                  </ul>
                </article>
              </div>
            </div>
            <h2 :id="cvenavs['Resources & Support']['submenu']['Resources']['items']['CVE Board']['anchorId']"
              class="title">
              {{cvenavs['Resources & Support']['submenu']['Resources']['items']['CVE Board']['label']}}
            </h2>
            <div class="columns">
              <div class="column pl-3 is-two-thirds">
                <div class="tile is-ancestor cve-task-tiles-container">
                  <div class="tile is-parent cve-task-left-tile">
                    <article class="tile is-child cve-border-dark-blue">
                      <h3 class="title cve-task-tile-header">
                        CVE Board Resources
                      </h3>
                      <ul class="mt-0 tile-body cve-task-tile-list">
                        <li>
                          <a target='_blank' href="/Resources/Roles/Board/General/Board-Charter.pdf">
                            CVE Board Charter, Version 3.4
                          </a>
                        </li>
                        <li>
                          <a target='_blank' href="/Resources/Roles/Board/General/Board-Nomination-Form.docx">
                            CVE Board Nomination Form
                          </a>
                        </li>
                      </ul>
                    </article>
                  </div>
                </div>
              </div>
            </div>
            <h2 :id="cvenavs['Resources & Support']['submenu']['Resources']['items']['CVE List Data Feeds']['anchorId']"
              class="title">
              {{cvenavs['Resources & Support']['submenu']['Resources']['items']['CVE List Data Feeds']['label']}}
            </h2>
            <div class="columns">
              <div class="column pl-3 is-two-thirds">
                <div class="tile is-ancestor cve-task-tiles-container">
                  <div class="tile is-parent cve-task-left-tile">
                    <article class="tile is-child cve-border-dark-blue">
                      <h3 class="title cve-task-tile-header">
                        CVE Data Feeds
                      </h3>
                      <p class="mb-0 ml-4 has-text-weight-bold">Feed of newly published CVE Records:</p>
                      <ul class="mt-0 mb-0 tile-body cve-task-tile-list">
                        <li><a href="https://twitter.com/CVEnew/" target="_blank">@CVEnew Twitter</a></li>
                        <li><a href='https://github.com/CVEProject/cvelistV5' target='_blank'>CVE List downloads (updated hourly)</a>
                        cvelistV5 repository on GitHub</li>
                      </ul>
                      <p class="mb-0 ml-4 has-text-weight-bold">External Feeds of CVE List content:</p>
                      <ul class="mt-0 tile-body cve-task-tile-list">
                        <li><a href="https://nvd.nist.gov/developers/vulnerabilities"
                          target="_blank">U.S. National Vulnerability Database (NVD) CVE API</a>  allows users to stay up to date with NVD
                          content</li>
                        <li><a href="https://cassandra.cerias.purdue.edu/CVE_changes/"
                          target="_blank">CVE Change Logs</a>  free tool from CERIAS/Purdue University that
                          provides daily and monthly changes to CVE Records</li>
                      </ul>
                    </article>
                  </div>
                </div>
              </div>
            </div>
            <h2 :id="cvenavs['Resources & Support']['submenu']['Resources']['items']['General']['anchorId']"
              class="title">
              {{cvenavs['Resources & Support']['submenu']['Resources']['items']['General']['label']}}
            </h2>
            <div class="columns">
            <div class="tile is-ancestor cve-task-tiles-container">
              <div class="tile is-parent cve-task-left-tile">
                <article class="tile is-child cve-border-dark-blue">
                     <h3 class="title cve-task-tile-header">
                       Code of Conduct
                     </h3>
                     <ul class="mt-0 tile-body cve-task-tile-list">
                       <li>
                         <router-link to="/ResourcesSupport/AllResources/ProfessionalCodeOfConduct">
                           CVE Program Professional Code of Conduct
                         </router-link>
                       </li>
                     </ul>
                   </article>
                 </div>
              <div class="tile is-parent cve-task-left-tile">
                <article class="tile is-child cve-border-dark-blue">
                  <h3 class="title cve-task-tile-header">
                    CVE Logo Guidelines
                  </h3>
                  <ul class="mt-0 tile-body cve-task-tile-list">
                    <li>
                      <router-link to="/Resources/Media/LogoGuidelines/CVE-Logo-Usage-Guidelines.pdf" target="_blank">
                        CVE Logo Usage Guidelines (PDF, 0.1MB)
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/Resources/Media/LogoGuidelines/cveLogo.png" target="_blank">CVE Logo (PNG, 19KB)</router-link>
                    </li>
                    <li>
                      <router-link to="/Resources/Media/LogoGuidelines/cveLogo.svg" target="_blank">CVE Logo (SVG, 2KB)</router-link>
                    </li>
                  </ul>
                </article>
               </div>
              </div>
            </div>
          </div>
        </main>
        <SurveyLinkComponent :surveyLink="cvenavs['Resources & Support']['submenu']['Resources']['surveyLink']" />
        </div>
      <div class="column is-3 is-hidden-touch">
        <NavigationSidebar :nav="cvenavs['Resources & Support']" />
      </div>
    </div>
  </div>
</template>

<script>
import NavigationSidebar from '@/components/NavigationSidebar.vue';
import SurveyLinkComponent from '@/components/SurveyLinkComponent.vue';

export default {
  name: 'Resources',
  components: {
    NavigationSidebar,
    SurveyLinkComponent,
  },
  props: {
    cvenavs: {
      type: Object,
      required: true,
    },
  },
};

</script>

<style scoped lang="scss">
@import '../../assets/style/globals.scss';
@import '../../assets/style/elements/cveTableStacked.scss';
</style>

<template>
  <div id="cve-secondary-page-main-container" class="container">
    <div class="columns is-centered">
      <div class="column is-8-desktop cve-main-column-content-width is-12-tablet">
        <main id="cve-main-page-content" role="main">
          <div class="content">
             <h1 :id="cvenavs['About']['submenu']['Related Efforts']['id']" class="title">
              {{cvenavs['About']['submenu']['Related Efforts']['label']}}
             </h1>
            <ExternalLinkMessage/>
             <h2 :id="cvenavs['About']['submenu']['Related Efforts']['items']['National Vulnerability Database (NVD)']['anchorId']"
              class="title">
               {{cvenavs['About']['submenu']['Related Efforts']['items']['National Vulnerability Database (NVD)']['label']}}</h2>
               <p>
                 CVE and NVD are separate programs. The <a href="https://nvd.nist.gov/" target="_blank">U.S. National Vulnerability
                 Database (NVD)</a> was launched by the <a href="https://www.nist.gov/" target="_blank">National Institute of
                 Standards and Technology (NIST)</a> in 2005, while the
                 <router-link to='/ResourcesSupport/Glossary?activeTerm=glossaryCVEList'>CVE List</router-link> was launched by
                 <router-link to="/ResourcesSupport/FAQs#pc_introMITRE_role_in_cve">The MITRE Corporation</router-link> as a community
                 effort in 1999.
               </p>
               <p>
                 NVD is a vulnerability database built upon — and fully synchronized with — the CVE List so that any updates to CVE appear
                 immediately in NVD.
               </p>
               <p>
                 The CVE List <span class="is-italic">feeds</span> NVD, which then builds upon the information
                 included in CVE Records to provide enhanced information for each CVE Record such as:
                 <ul>
                   <li>Severity scores and impact ratings</li>
                   <li>Common Platform Enumeration (CPE) information</li>
                   <li>Fix information</li>
                   <li>Searching by OS; by vendor name, product name, and/or version number; and by
                      vulnerability type, severity, related exploit range, and impact</li>
                   <li>CVE API for content updates</li>
                 </ul>
              </p>
              <p>
                While separate, both CVE and NVD are sponsored by the <a href="https://www.dhs.gov/" target="_blank">
                U.S. Department of Homeland Security(DHS)</a> &nbsp;
                 <a href="https://www.dhs.gov/cisa/cybersecurity-division/" target="_blank">Cybersecurity and Infrastructure
                Security Agency(CISA)</a>, and output from both programs are free for public use.
              </p>
            <h2 :id="cvenavs['About']['submenu']['Related Efforts']['items']['Common Vulnerability Scoring System (CVSS)']['anchorId']"
              class="title">
              {{cvenavs['About']['submenu']['Related Efforts']['items']
                ['Common Vulnerability Scoring System (CVSS)']['label']}}
            </h2>
            <p>
              The <a href="https://www.first.org/cvss/" target="_blank">CVSS</a> standard operated by the
              <a href="https://www.first.org/" target="_blank">Forum of Incident
              Response and Security Teams (FIRST)</a>, which is a separate program from CVE, can be used to score the severity of software
              vulnerabilities identified by CVE Records. In general, severity scores for CVE Records are provided by NVD, but some CVE Records
              on the CVE List include severity ratings if the CNA publishing the record decides to include them.
            </p>
            <p>
              CVSS Version 3.0 provides “a way to capture the principal characteristics of a vulnerability, and
              produce a numerical score reflecting its severity, as well as a textual representation of that
              score. The numerical score can then be translated into a qualitative representation (such as low,
              medium, high, and critical) to help organizations properly assess and prioritize their vulnerability
              management processes.”
            </p>
            <p>
              CVE Records are cited in the CVSS specification and documentation to identify individual vulnerabilities used as examples, but
              they are not required for using CVSS.
            </p>
            <h3 class="title">CVSS Calculator for CVE Records</h3>
            <p>
              Severity rating scoring and prioritization for CVE Records is available through a
              <a href="https://nvd.nist.gov/vuln-metrics/cvss" target="_blank">CVSS calculator</a> provided by the
              <a href="https://nvd.nist.gov/" target="_blank">U.S. National Vulnerability Database (NVD)</a>.
            </p>
            <p>
              According to the NVD website, which is operated by the <a href="https://www.nist.gov/" target="_blank">National Institute of
              Standards and
              Technology (NIST)</a>, NVD’s CVSS calculator for CVE Records supports both the CVSS 2.0 and CVSS 3.0 standards and provides
              qualitative severity rankings for CVE Records using each version. In addition, NVD’s CVSS calculator also allows users to add
              two additional types of score data into their severity scoring: (1) temporal, for “metrics that change over time due to events
              external to the vulnerability,” and (2) environmental, for “scores customized to reflect the impact of the vulnerability on
              your organization.” For details and help, visit <a href="https://nvd.nist.gov/vuln-metrics/cvss" target="_blank">
              NVD’s CVSS Calculator for CVE Records</a> on the NVD website.
            </p>
            <h2 :id="cvenavs['About']['submenu']['Related Efforts']['items']['Known Exploited Vulnerabilities (KEV) Catalog']
              ['anchorId']" class="title">
              {{cvenavs['About']['submenu']['Related Efforts']['items']['Known Exploited Vulnerabilities (KEV) Catalog']['label']}}
            </h2>
            <p>
              Managed by the
              <a href="https://www.dhs.gov/cisa/cybersecurity-division/" target="_blank">Cybersecurity and Infrastructure Security Agency (CISA)</a>,
              the
              <a href="https://www.cisa.gov/known-exploited-vulnerabilities-catalog" target="_blank">Known Exploited Vulnerabilities (KEV) Catalog</a>
              is a database of security flaws in software applications and weaknesses that have been exposed and leveraged by attackers. Each
              vulnerability listed in KEV is identified by, and links to, a CVE Record.
            </p>
            <h2 :id="cvenavs['About']['submenu']['Related Efforts']['items']['Common Weakness Enumeration (CWE)']['anchorId']"
              class="title">
              {{cvenavs['About']['submenu']['Related Efforts']['items']['Common Weakness Enumeration (CWE)']['label']}}
            </h2>
            <p>
              <a href="https://cwe.mitre.org/" target="_blank">CWE™</a> is a community-developed taxonomy of
              common software and
              hardware security weaknesses that serves as a common language, a measuring stick for security tools, and as a baseline for
              weakness identification, mitigation, and prevention efforts. Weaknesses in software and hardware may become exploitable
              vulnerabilities if not eliminated prior to software or hardware deployment.
            </p>
            <p>
              The CWE/CAPEC Program also has special interest groups (SIGs) and working groups (WGs) for community participation, including the
              <a href="https://cwe.mitre.org/community/working_groups.html#ics_ot_sig" target="_blank">CWE-CAPEC Industrial Control Systems
              (ICS)/Operational Technology (OT) SIG</a>,
              <a href="https://cwe.mitre.org/community/working_groups.html#hw_sig" target="_blank">Hardware CWE SIG</a>,
              <a href="https://cwe.mitre.org/community/working_groups.html#rest_api_wg" target="_blank">CWE/CAPEC REST API WG</a>, and
              <a href="https://cwe.mitre.org/community/working_groups.html#ue_wg" target="_blank">CWE/CAPEC User Experience WG</a>.
            </p>

            <h2 :id="cvenavs['About']['submenu']['Related Efforts']['items']['Common Attack Pattern Enumeration and Classification (CAPEC)']
              ['anchorId']" class="title">
              {{cvenavs['About']['submenu']['Related Efforts']['items']['Common Attack Pattern Enumeration and Classification (CAPEC)']['label']}}
            </h2>
            <p>
              <a href="https://capec.mitre.org/" target="_blank">CAPEC™</a> is a comprehensive dictionary and
              classification taxonomy of common attack patterns against software and hardware weaknesses. By understanding how weaknesses can
              be exploited, analysts, developers, testers, and educators are better positioned to identify and eliminate weaknesses prior to
              them becoming exploitable vulnerabilities.
            </p>
            <h2 :id="cvenavs['About']['submenu']['Related Efforts']['items']['ATT&CK']
              ['anchorId']" class="title">
              {{cvenavs['About']['submenu']['Related Efforts']['items']['ATT&CK']['label']}}
            </h2>
            <p>
              MITRE <a href="https://attack.mitre.org/" target="_blank">ATT&CK<sup>®</sup></a> is a globally accessible knowledge base of
              adversary tactics and techniques based on real-world observations. The ATT&CK knowledge base is used as a foundation for the
              development of specific threat models and methodologies in the private sector, in government, and in the cybersecurity product
              and service community.
            </p>
            <SurveyLinkComponent :surveyLink="cvenavs['About']['submenu']['Related Efforts']['surveyLink']" />
         </div>
        </main>
      </div>
      <div class="column is-3 is-hidden-touch">
        <NavigationSidebar :nav="cvenavs['About']" />
      </div>
    </div>
  </div>
</template>

<script>
import NavigationSidebar from '@/components/NavigationSidebar.vue';
import SurveyLinkComponent from '@/components/SurveyLinkComponent.vue';

export default {
  name: 'RelatedEfforts',
  components: {
    NavigationSidebar,
    SurveyLinkComponent,
  },
  props: {
    cvenavs: {
      type: Object,
      required: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../assets/style/globals.scss';

</style>

<template>
  <div id="cve-secondary-page-main-container" class="container">
    <div class="columns is-centered">
      <div class="column is-8-desktop cve-main-column-content-width is-12-tablet">
        <main id="cve-main-page-content" role="main">
          <div class="content">
            <h1 :id="pagePath['id']" class="title">
              {{pagePath['alternatePageName']}}</h1>
            <div id="self-service-automation" class="content">
              <p>
                Web forms and open-source tools enable CNAs to reserve a number of sequential or non-sequential CVE IDs in real time, as needed,
                and to publish CVE Records to the CVE List. <router-link to="/AllResources/CveServices">Learn more</router-link>.
              </p>
              <p>
                <router-link to="/AllResources/CveServices#obtaining-credentials">Credentials</router-link> are required for CNAs to request CVE IDs
                and publish CVE Records.
              </p>
              <p>
                The following options are available:
                <ul>
                  <li>
                    <span class="has-text-weight-bold">
                      <a href='https://vulnogram.github.io/cve5/#editor' target='_blank'>Vulnogram web form
                        <span class="icon cve-icon-xxs">
                          <p id="Vulnogram" class="is-hidden">
                            Vulnogram web form opens in a new tab or window depending on browser settings
                          </p>
                          <font-awesome-icon icon="external-link-alt" aria-labelledby="Vulnogram" aria-hidden="false"/>
                        </span>
                      </a>
                    </span>&mdash;Use Vulnogram
                    (watch videos:
                    <a href='https://youtu.be/o3V-fmQpC0o' target='_blank'>
                      demo #1
                      <span class="icon cve-icon-xxs">
                        <p id="VulnogramDemo1" class="is-hidden">
                          Vulnogram web form demo #1 opens in a new tab or window depending on browser settings
                        </p>
                        <font-awesome-icon icon="external-link-alt" aria-labelledby="VulnogramDemo1" aria-hidden="false"/>
                      </span>
                    </a> |
                    <a href='https://youtu.be/6LF98w8xtQ8' target='_blank'>
                      demo #2
                      <span class="icon cve-icon-xxs">
                        <p id="VulnogramDemo1Demo2" class="is-hidden">
                          Vulnogram web form demo #2 opens in a new tab or window depending on browser settings
                        </p>
                        <font-awesome-icon icon="external-link-alt" aria-labelledby="VulnogramDemo1Demo2" aria-hidden="false"/>
                      </span>
                    </a>)
                    to:
                    <ul>
                      <li>Reserve 1, 5, or 10 CVE IDs</li>
                      <li>Populate the details of one CVE Record at a time</li>
                      <li>Manually publish one CVE Record at a time</li>
                    </ul>
                  </li>
                  <li>
                    <span class="has-text-weight-bold">Choose a Client</span>&mdash;To automate CVE ID reservations and CVE Record publishing in your
                    environment, install a <router-link to="/AllResources/CveServices#using-cve-services-clients">CVE Services client</router-link>
                    (i.e., Vulnogram, cveClient, or cvelib). Watch demos and learn more about CVE Services clients
                    <router-link to="/AllResources/CveServices#using-cve-services-clients">here</router-link>.
                  </li>
                  <li>
                    <span class="has-text-weight-bold">Build Your Own Client</span>&mdash;CNAs are also welcome to build their own CVE Services
                    clients. For API documentation and test environment information, click
                    <router-link to="/AllResources/CveServices#build-your-own-client">here</router-link>.
                  </li>
                </ul>
              </p>
              </div>
            <SurveyLinkComponent :surveyLink="cvenavs['All Resources'].submenu['Reserve IDs & Publish Records']['surveyLink']" />
          </div>
        </main>
      </div>
      <div class="column is-3 is-hidden-touch">
        <NavigationSidebar :nav="cvenavs['Right Menu']['Report/Request']" />
      </div>
    </div>
  </div>
</template>

<script>
import NavigationSidebar from '@/components/NavigationSidebar.vue';
import SurveyLinkComponent from '@/components/SurveyLinkComponent.vue';

export default {
  name: 'ReserveIDsPublishRecordsForCNAs',
  components: {
    NavigationSidebar,
    SurveyLinkComponent,
  },
  props: {
    cvenavs: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pagePath: this.cvenavs['Right Menu']['Report/Request'].submenu.CNAs,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '@/assets/style/globals.scss';

</style>

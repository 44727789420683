<template>
  <div class="field mt-1" style="">
    <div class="field has-addons mb-1">
      <div class="control is-expanded">
        <input v-model="cveId" @keyup.enter="onKeyUpEnter" @keyup="validateCveId" @blur="removeHelpText" type="text" class="input cve-id-input"
          placeholder="Enter CVE ID (CVE-YYYY-NNNN)"/>
      </div>
      <div class="control">
        <button class="button cve-button cve-button-accent-warm" :class="{'is-loading': this.$store.state.isSearching, 'disabled': disabled}"
          :aria-disabled="disabled" @click="startLookup">
          Find {{this.$store.state.WEBSITE_ENVIRONMENT === 'test' ? 'a Test CVE Record/ID' : 'a Test CVE Record/ID'}}
        </button>
      </div>
    </div>
    <div class="notification is-warning" role="alert" v-if="errorMessage.length > 0">
      <div class="is-flex" style="justify-content: flex-start;">
        <p id="alertIcon" class="is-hidden">alert</p>
        <font-awesome-icon style="flex: 0 0 40px; margin-top:3px" size="lg"  icon="exclamation-triangle" role="alert"
          aria-labelledby="alertIcon" aria-hidden="false" />
          <p class="cve-help-text">
          <ul v-for="errorMsg in errorMessage" :key="errorMsg.key" class="pl-4" style="list-style: square">
            <li class="cve-help-text" v-html="errorMsg"></li>
          </ul>
        <router-link to="/About/Process#request"> Learn more</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default ({
  data() {
    return {
      cveId: this.$store.state.cveId,
      showHelpText: false,
      errorMessage: [],
      disabled: true,
      getIdStatusCode: undefined,
    };
  },
  created() {
    const fullPathArr = this.$route.fullPath.split('id=');
    if (fullPathArr.length === 2) {
      const cveId = fullPathArr[1];
      this.$store.commit('updateState', { cveId: this.$sanitize(cveId, { disallowedTagsMode: 'escape' }) });
      this.cveId = this.cveIdToUpperCase(cveId);
      this.validateCveId();
      if (!this.disabled) {
        this.lookupId();
      } else {
        this.$store.commit('updateState', { showHelpText: true });
      }
    } else {
      this.$store.commit('updateState', { showHelpText: true });
    }
  },
  watch: {
    $route(to) {
      if (Object.prototype.hasOwnProperty.call(to.query, 'id')) {
        this.$store.commit('updateState', { cveId: this.$sanitize(to.query.id, { disallowedTagsMode: 'escape' }) });
        this.cveId = this.cveIdToUpperCase(to.query.id);
        this.validateCveId();
        if (!this.disabled) {
          this.lookupId();
        } else {
          this.resetStates();
          this.$store.commit('updateState', { showHelpText: true });
        }
      }
    },
    cveId() {
      this.cveId = this.cveId.trim().toUpperCase();
    },
  },
  computed: {
    API_BASE() { return this.$store.state.API_BASE; },
  },
  methods: {
    removeHelpText() {
      if (this.cveId.length === 0) {
        this.errorMessage = [];
      }
    },
    cveIdToUpperCase(cveId) {
      return cveId.toUpperCase();
    },
    validateCveId() {
      this.errorMessage = [];

      const [cve, year, digits] = this.cveId.split('-');
      const firstElContainsCve = new RegExp(/^(cve)/, 'i').test(cve);
      const idStartsWithCve = new RegExp(/^(cve-)/, 'i').test(this.cveId);
      const isElFilledIn = cve && year && digits;

      if (!firstElContainsCve) {
        this.errorMessage.push('Required CVE ID format: <b>CVE-YYYY-NNNN</b>');
        this.errorMessage.push('<b>YYYY</b> must be a year starting from 1999');
        this.errorMessage.push('<b>NNNN</b> must be 4 digits or greater');
      } else if (!idStartsWithCve && !new RegExp(/^(cve-)\d{4}(-)/, 'i').test(this.cveId)) {
        if (!idStartsWithCve) this.errorMessage.push('CVE ID must start with <b>CVE-</b>');
      }

      if (year && year.length > 0 && (parseInt(year, 10) < 1999 || !new RegExp(/^\d{4}$/, 'i').test(year))) {
        this.errorMessage.push('CVE-<b>YYYY</b>-NNNN must be a year starting from 1999');
      } else if (year && year.length > 0 && (!new RegExp(/^(cve-)\d{4}(-)/, 'i').test(this.cveId) && isElFilledIn === undefined)) {
        this.errorMessage.push('Hyphen (-) must follow the year <b>(YYYY)</b>');
        this.errorMessage.push('Required CVE ID format: <b>CVE-YYYY-NNNN</b>');
      }

      if (digits && digits.length > 0 && !new RegExp(/^\d{4,}$/).test(digits)) {
        this.errorMessage.push('CVE-YYYY-<b>NNNN</b> must be 4 digits or greater');
      }

      if (this.errorMessage.length === 0 && new RegExp(/^(cve)-\d{4}-\d{4,}$/, 'i').test(this.cveId)) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    resetStates() {
      this.$store.commit('updateState', { serverError: false });
      this.$store.commit('updateState', { isIdOrRecordFound: true });
      this.$store.commit('updateState', { showHelpText: false });
      this.$store.commit('updateState', { isSearching: false });
      this.$store.commit('updateState', { showJsonRecord: false });
      this.$store.commit('updateState', { recordData: {} });
      this.$store.commit('updateState', { idData: {} });
      this.$store.commit('updateState', { isPublished: false });
      this.$store.commit('updateState', { isReserved: false });
      this.$store.commit('updateState', { isRejected: false });
      this.$store.commit('updateState', { isArecord: undefined });
    },
    startLookup() {
      this.$store.commit('updateState', { cveId: this.$sanitize(this.cveId, { disallowedTagsMode: 'escape' }) });

      const currentPath = this.$route.fullPath;
      if (this.$route.name !== 'CVERecord' || this.$store.state.cveId.length > 0) {
        const newPath = `/CVERecord?id=${this.$store.state.cveId}`;
        if (currentPath !== newPath) {
          this.resetStates();
          this.$router.push(newPath);
        } else if (this.$store.state.serverError) {
          this.lookupId();
        }
      }
    },
    async lookupId() {
      this.resetStates();
      this.$store.commit('updateState', { isSearching: true });
      this.disabled = true;

      try {
        this.getRecordData();
      } catch (error) {
        const regex = /4\d{2}/g; // 4xx error, e.g.: 400, 404, etc
        const errorToStr = error.toString() || '';
        if (!errorToStr.match(regex)) {
          this.$store.commit('updateState', { isPublished: false });
          this.$store.commit('updateState', { isReserved: false });
          this.$store.commit('updateState', { isRejected: false });
          this.handleServerError();
        }
      } finally {
        if (!this.$store.state.serverError && !this.$store.state.isPublished && !this.$store.state.isReserved && !this.$store.state.isRejected) {
          this.getIdData();
        }
      }
    },
    async getIdData() {
      this.$store.commit('updateState', { serverError: false });
      this.$store.commit('updateState', { isArecord: undefined });

      const getIdUrl = `/api/cve-id/${this.$store.state.cveId}`;
      try {
        axios.defaults.baseURL = `https://cveawg-test.mitre.org`;
        const idData = await axios.get(getIdUrl);
        this.getIdStatusCode = 200;
        if (idData.status === 200 && idData?.data?.error === undefined) {
          this.$store.commit('updateState', { idData: idData.data });
          if (this.$store.state.idData.state === 'RESERVED') {
            this.$store.commit('updateState', { isReserved: true });
            this.$store.commit('updateState', { isArecord: false });
          } else if (this.$store.state.idData.state === 'REJECTED') {
            this.$store.commit('updateState', { isRejected: true });
            this.$store.commit('updateState', { isArecord: false });
          }

          this.handleIsIdOrRecordFound(true);
        }
      } catch (error) {
        if (!this.$store.state.isPublished) this.$store.commit('updateState', { isPublished: false });
        if (!this.$store.state.isReserved) this.$store.commit('updateState', { isReserved: false });
        if (!this.$store.state.isRejected) this.$store.commit('updateState', { isRejected: false });

        const regex = /4\d{2}/g; // 4xx error, e.g.: 400, 404, etc
        if (error.toString().match(regex) === null) {
          this.handleServerError();
        } else {
          const errorToStr = error.toString();
          this.getIdStatusCode = errorToStr.match(regex) ? regex.exec(errorToStr)[0] : 500;
          // this.setLoadingState(false);
          if (errorToStr.match(regex)) {
            this.$store.commit('updateState', { isArecord: false });
            this.handleIsIdOrRecordFound(false);
          } else {
            this.handleServerError();
          }
        }
      }
    },
    async getRecordData() {
      const getRecordUrl = `https://cveawg-test.mitre.org/api/cve/${this.$store.state.cveId}`;
      const recordData = await axios.get(getRecordUrl);

      if (!this.isJson(recordData.data)) {
        this.$store.commit('updateState', { isPublished: false });
        this.$store.commit('updateState', { isReserved: false });
        this.$store.commit('updateState', { isRejected: false });
        this.handleServerError();
      } else if (recordData?.data?.error) {
        this.$store.commit('updateState', { isPublished: false });
        this.$store.commit('updateState', { isReserved: false });
        this.$store.commit('updateState', { isRejected: false });

        if (recordData.data.error === 'CVE_RECORD_DNE') {
          this.$store.commit('updateState', { isIdOrRecordFound: false });
          this.$store.commit('updateState', { isArecord: true });
        } else {
          this.handleServerError();
        }
      } else {
        this.$store.commit('updateState', { isArecord: true });
        this.$store.commit('updateState', { recordData: recordData.data });
        if (this.$store.state.recordData.cveMetadata.state === 'PUBLISHED') {
          this.$store.commit('updateState', { isPublished: true });
        } else if (this.$store.state.recordData.cveMetadata.state === 'RESERVED') {
          this.$store.commit('updateState', { isReserved: true });
        } else if (this.$store.state.recordData.cveMetadata.state === 'REJECTED') {
          this.$store.commit('updateState', { isRejected: true });
        }
        this.handleIsIdOrRecordFound(true);
        // this.setLoadingState(false);
      }
    },
    formatCveRecord() {
      const keyFields = [
        ['CVE_data_meta', 'UPDATED'],
        ['CVE_data_meta', 'ID'],
        ['CVE_data_meta', 'STATE'],
        ['description', 'description_data'],
        ['problemtype', 'problemtype_data'],
        ['references', 'reference_data'],
        ['affects'],
      ];

      const record = {};
      let i = 0;

      for (i; i < keyFields.length; i += 1) {
        if (Object.prototype.hasOwnProperty.call(this.$store.state.recordData, keyFields[i][0])) {
          if (keyFields[i][0] === 'affects') {
            record.affects = this.getAffectsValues();
          } else {
            const [keyLabel, obj] = this.getNested(keyFields[i]);
            const metaType = ['description_data', 'problemtype_data'];
            const index = metaType.indexOf(keyFields[i][1]);
            if (typeof obj !== 'undefined' && index > -1) {
              const data = [];
              if (obj.length > 0) {
                if (keyLabel === 'description_data') {
                  obj.forEach((descriptionObj) => {
                    if (Object.prototype.hasOwnProperty.call(descriptionObj, 'value') && descriptionObj.value !== 'n/a') {
                      data.push(descriptionObj.value.split(/\n/));
                    } else {
                      data.push('');
                    }
                  });
                }

                if (keyLabel === 'problemtype_data') {
                  obj.forEach((problemtypeData) => {
                    problemtypeData.description.forEach((problem) => {
                      if (Object.prototype.hasOwnProperty.call(problem, 'value') && problem.value !== 'n/a') {
                        data.push(problem.value.split(/\n/));
                      } else {
                        data.push('');
                      }
                    });
                  });
                }

                if (keyLabel === 'reference_data') {
                  obj.forEach((reference) => {
                    const ref = {
                      url: undefined,
                      name: undefined,
                    };
                    if (Object.prototype.hasOwnProperty.call(reference, 'url')) {
                      ref.url = reference.value;
                      if (Object.prototype.hasOwnProperty.call(reference, 'name')) {
                        if (reference.name !== 'n/a' || reference.name !== '') ref.name = reference.name;
                      }
                    }

                    data.push(ref);
                  });
                }
              }
              record[keyLabel] = data;
            } else {
              record[keyLabel] = obj;
            }
          }
        } else {
          record[keyFields[i]] = undefined;
        }
      }
      this.$store.commit('updateState', { recordData: record });
    },
    getNested(nestedKeysList) {
      let obj = this.$store.state.recordData;
      let i = 0;

      for (i; i < nestedKeysList.length; i += 1) {
        if (!obj || !Object.prototype.hasOwnProperty.call(obj, nestedKeysList[i])) {
          return [nestedKeysList[i], undefined];
        }
        obj = obj[nestedKeysList[i]];
      }
      return [nestedKeysList[i - 1], obj];
    },
    getAffectsValues() {
      const affects = {};

      const vendorData = this.getNested(['affects', 'vendor', 'vendor_data'])[1];
      if (typeof vendorData === 'undefined') {
        return undefined;
      }

      affects.vendors = vendorData.map((vendorObj) => {
        const vendorAndProductInfo = {};
        if (Object.prototype.hasOwnProperty.call(vendorObj, 'product')) {
          if (Object.prototype.hasOwnProperty.call(vendorObj, 'vendor_name')) vendorAndProductInfo.vendor_name = vendorObj.vendor_name;
          const productData = Object.prototype.hasOwnProperty.call(vendorObj.product, 'product_data');
          if (productData) {
            vendorAndProductInfo.products = vendorObj.product.product_data.map((vendor) => {
              const vendorProductData = {};
              if (Object.prototype.hasOwnProperty.call(vendor, 'product_name')) vendorProductData.product_name = vendor.product_name;
              const versionObj = [];
              const versionAffectedDefinition = {
                '=': 'affects version_value',
                '<': 'affects versions prior to version_value',
                '>': 'affects versions later than version_value',
                '<=': 'affects version_value and prior versions',
                '>=': 'affects version_value and later versions',
                '!': 'doesn\'t affect version_value',
                '!<': 'doesn\'t affect versions prior to version_value',
                '!>': 'doesn\'t affect versions later than version_value',
                '!<=': 'doesn\'t affect version_value and prior versions',
                '!>=': 'doesn\'t affect version_value and later versions',
                '?': 'status of version_value is unknown',
                '?<': 'status of versions prior to version_value is unknown',
                '?>': 'status of versions later than version_value is unknown',
                '?<=': 'status of version_value and prior versions is unknown',
                '?>=': 'status of version_value and later versions is unknown',
              };
              if (Object.prototype.hasOwnProperty.call(vendor, 'version')) {
                if (Object.prototype.hasOwnProperty.call(vendor.version, 'version_data')) {
                  vendor.version.version_data.forEach((versionData) => {
                    const versionValueAffected = { version_value: '', version_affected: '', definition: '' };
                    if (Object.prototype.hasOwnProperty.call(versionData, 'version_value')) {
                      versionValueAffected.version_value = versionData.version_value;
                    }
                    if (Object.prototype.hasOwnProperty.call(versionData, 'version_affected')) {
                      versionValueAffected.version_affected = versionData.version_affected;
                      if (versionAffectedDefinition[versionData.version_affected]) {
                        versionValueAffected.definition = versionAffectedDefinition[versionData.version_affected]
                          .replace('version_value', versionValueAffected.version_value);
                      }
                    }
                    if (versionValueAffected.version_value !== '') versionObj.push(versionValueAffected);
                  });

                  vendorProductData.vendor_version = versionObj;
                }
              }
              return vendorProductData;
            });
          }
        }

        return vendorAndProductInfo;
      });
      return affects;
    },
    handleServerError() {
      this.$store.commit('updateState', { serverError: true });
      this.setLoadingState(false);
      this.$store.commit('updateState', { isArecord: undefined });
    },
    handleIsIdOrRecordFound(newState) {
      this.$store.commit('updateState', { serverError: false });
      this.$store.commit('updateState', { isIdOrRecordFound: newState });
      this.setLoadingState(false);
    },
    setLoadingState(newState) {
      this.$store.commit('updateState', { showHelpText: newState });
      this.$store.commit('updateState', { isSearching: newState });
      this.disabled = false;
    },
    onKeyUpEnter() {
      this.validateCveId();

      if (!this.disabled) this.startLookup();
    },
    isJson(value) {
      if (typeof value === 'object') {
        return true;
      }
      return false;
    },
  },
});
</script>

<style scoped lang="scss">
@import '../assets/style/globals.scss';

.disabled {
  opacity: 0.7 !important;
  background-color: #3d4551;
  color: white;
  border-color: #dbdbdb;
  box-shadow: none;
}

.notification {
  margin: 0 0 2px 0 !important;
  padding: 2px 10px 2px 10px  !important;
}

@media screen and (min-width: $desktop) {
  .cve-id-input {
    width: 400px;
  }
}
</style>

 <template>
  <div class="content">
    <nav class="level mb-0">
      <div class="level-left">
        <div class="level-item">
          <h1 class="title">{{this.$store.state.cveId}}</h1>
        </div>
        <div v-if="cveFieldList.state.length > 0" class="level-item">
          <span class="tag is-info is-light has-text-weight-bold cve-state-tag">{{cveFieldList.state}}</span>
        </div>
      </div>
      <div class="level-right ml-1">
        <div class="level-item">
          <a id="cve-view-json" :href="`https://cveawg-test.mitre.org/api/cve/${this.$store.state.cveId}`" target="_blank">
            View JSON
          </a>
        </div>
      </div>
    </nav>
    <div class="content"><span v-if="cveFieldList.title.length > 0" class="has-text-grey-dark is-size-6">{{cveFieldList.title}}</span></div>
    <div role="information" class="notification is-info is-light p-3">
      <div class="is-flex" style="justify-content: center;">
          <section class="cve-accordian" style="flex: 0 0 100%;">
              <div class="message" style="background: transparent !important;">
                <div class="message-header p-0"
                  style="background: transparent !important;border-bottom: unset !important">
                  <span class="icon-text">
                    <span class="icon">
                      <p id="infoIconVariousFormts" class="is-hidden">information</p>
                      <font-awesome-icon style="flex: 0 0 40px;" size="1x"  icon="info-circle" role="alert"
                        aria-labelledby="alertIconVariousFormts" aria-hidden="false" />
                    </span>
                    <span class="mr-1">Important CVE JSON 5 Information</span>
                  </span>
                  <button class="button message-header-button" style="background: transparent !important;"
                    @click="isMessageExpanded = !isMessageExpanded">
                    <span class="icon is-small">
                      <p id="expandCollapseAltText" class="is-hidden">
                        {{isMessageExpanded ? 'expand': 'collapse'}}
                      </p>
                      <font-awesome-icon :icon="isMessageExpanded ? 'minus' : 'plus'"
                        aria-hidden="false" focusable="true" aria-labelledby="expandCollapseAltText"/>
                    </span>
                  </button>
                </div>
              </div>
          </section>
      </div>
      <div v-if="isMessageExpanded" class="p-4 has-text-justified">
        <p>
          <router-link to='/ResourcesSupport/Glossary?activeTerm=glossaryRecord'>CVE Records</router-link>
          on this CVE.ORG website are displayed in
          <router-link to='/AllResources/CveServices#cve-json-5'>CVE JSON 5.0</router-link>.
          Downloads in this format are available on the <router-link to='/Downloads'>Downloads</router-link>
          page. Learn more about
          <router-link to='/AllResources/CveServices#cve-json-5'>CVE JSON 5.0 here</router-link>.
        </p>
      </div>
    </div>
    <div id="cve-cna-container" class="content">
      <p v-if="cveFieldList.assigner.length > 0" class="mb-0">
        <span class="has-text-weight-bold">Assigner: </span>
        <span style="text-transform: capitalize;">{{cveFieldList.assigner}}</span>
      </p>
      <div id="cve-record-dates-and-tags-container" class="has-text-grey-dark has-text-left">
        <span class="mr-2">
          <span  v-if="cveFieldList.datePublishedCveMetadata.length > 0">
            <span class="has-text-weight-semibold">Published: </span>
            <time>{{cveFieldList.datePublishedCveMetadata}}</time>
          </span>
          <span :class="cveFieldList.datePublishedCveMetadata.length > 0 ? 'ml-2' : ''" v-if="cveFieldList.dateUpdatedCveMetadata.length > 0">
            <span class="has-text-weight-semibold">Updated: </span>
            <time>{{cveFieldList.dateUpdatedCveMetadata}}</time>
          </span>
        </span>
        <span id="cve-tags" v-if="cveFieldList.tags.length > 0">
          <span class="mr-2">|</span>
          <span class="tag is-info is-light cve-tag" v-for="tag in cveFieldList.tags" :key="tag">{{tag}}</span>
        </span>
      </div>
    </div>
    <div id="cve-desciption" class="content has-text-justified">
      <p v-for="description in cveFieldList.descriptions" :key="description">{{description}}</p>
    </div>
    <div id="cve-product-status-container" class="content">
      <h2 class="title is-size-5">Product Status</h2>
      <div role="information" class="notification is-info is-light p-3">
        <div class="is-flex" style="justify-content: center;">
            <section class="cve-accordian" style="flex: 0 0 100%;">
                <div class="message" style="background: transparent !important;">
                  <div class="message-header p-0"
                    style="background: transparent !important;border-bottom: unset !important">
                    <span class="icon-text">
                      <span class="icon">
                        <p id="infoIconVariousFormts" class="is-hidden">information</p>
                        <font-awesome-icon style="flex: 0 0 40px;" size="1x"  icon="info-circle" role="alert"
                          aria-labelledby="alertIconVariousFormts" aria-hidden="false" />
                      </span>
                      <p class="mr-1">Learn About the Versions Section</p>
                    </span>
                    <button class="button message-header-button" style="background: transparent !important;"
                      @click="isHelpTestShown = !isHelpTestShown">
                        <span class="icon is-small">
                          <p id="expandCollapseVersionsAltText" class="is-hidden">
                            {{isHelpTestShown ? 'expand': 'collapse'}}
                          </p>
                          <font-awesome-icon :icon="isHelpTestShown ? 'minus' : 'plus'"
                            aria-hidden="false" focusable="true" aria-labelledby="expandCollapseVersionsAltText"/>
                        </span>
                    </button>
                  </div>
                </div>
            </section>
        </div>
        <div v-if="isHelpTestShown" class="p-4 has-text-justified">
          <p>
            The <span class="has-text-weight-bold">Versions</span> section may contain a <span class="has-text-weight-bold">Default Status</span>
            field and one or more of these version status categories:
          </p>
          <div class="columns">
              <div v-for="status in ['Affected', 'Unaffected', 'Unknown']" :key="status.key"
                class="column cve-top-margin cve-versions-column">
                <div class="menu has-text-centered" style="border: unset !important">
                  <span class="has-text-weight-bold">{{status}}</span> Versions List
                </div>
              </div>
            </div>
          <p>
            Each category contains a list with the following format:
            <ul>
              <li>
                Top-level
                <a href="https://github.com/CVEProject/cve-schema/blob/master/schema/v5.0/docs/versions.md#versions-and-version-ranges"
                target="_blank">version/version range</a> is marked with a solid circle bullet.
                <ul>
                  <li>
                    <a href="https://github.com/CVEProject/cve-schema/blob/master/schema/v5.0/docs/versions.md#version-status-changes"
                    target="_blank">Version changes</a> are marked with an outlined circle bullet.
                  </li>
                </ul>
              </li>
            </ul>
          </p>
          <p><span class="cve-version">Purple text</span> denotes the version provided by the user inputting the CVE JSON 5.0 data.</p>
         <!-- <h4 class="title">Version Details</h4> -->
          <p>
            In the following section you will find the descriptions, format information and examples for the following fields: default status,
            top-level version/version range and version changes.
          </p>
          <p class="is-size-5">Default Status</p>
          <p>
            This status applies to all versions not explicitly mentioned elsewhere.
            <a href="https://github.com/CVEProject/cve-schema/blob/master/schema/v5.0/docs/versions.md#version-status-decisions" target="_blank">
            Learn more.</a>
          </p>
          <p class="is-size-5">Top-level Version/Version Range</p>
          <p>The single version being described, or the version range. By convention, 0 typically denotes the earliest possible version.</p>
          <p class="is-size-6 has-text-weight-bold">Formats:</p>
          <ul>
            <li>
              &lt;status: affected, unaffected, unknown&gt; at <span class="cve-version">&lt;version&gt;</span>
            </li>
            <li>
              &lt;status: affected, unaffected, unknown&gt; from <span class="cve-version">&lt;start version&gt;</span> &lt;at, before, through&gt;
              <span class="cve-version">&lt;end version&gt;</span>
            </li>
          </ul>
          <p class="is-size-6 has-text-weight-bold">Examples:</p>
          <ul>
            <li>
              <span class="has-text-weight-bold">unknown at <span class="cve-version">5.0</span></span>
              <span class="is-italic"> (unknown version is 5.0)</span>
            </li>
            <li>
              <span class="has-text-weight-bold">
                affected from <span class="cve-version">5.0</span> before <span class="cve-version">6.0</span></span>
              <span class="is-italic"> (affected version doesn’t include 6.0)</span>
            </li>
            <li>
              <span class="has-text-weight-bold">
                unaffected from <span class="cve-version">5.0</span> through <span class="cve-version">6.0</span>
              </span>
              <span class="is-italic"> (unaffected version range includes 5.0 and 6.0)</span>
            </li>
          </ul>
          <p class="is-size-5">Version changes</p>
          <p>
            A list of status changes that take place during a range. The list should be sorted in (linearly or topologically) increasing order by the
            'at' field, according to the <span class="is-italic">versionType</span>, but the user must not assume this. Instead, users must re-sort
            the list themselves before using it.
          </p>
          <p class="is-size-6 has-text-weight-bold">Format:</p>
          <p>&lt;status: affected, unaffected, unknown&gt; from <span class="cve-version">&lt;version&gt;</span>
          <p class="is-size-6 has-text-weight-bold">Examples:</p>
          <ul>
            <li>
              <span class="has-text-weight-bold">affected from <span class="cve-version">1.0</span> before <span class="cve-version">4.0</span></span>
              <span class="is-italic"> (affected version doesn't include 4.0)</span>
              <ul>
                <li>
                  <span class="has-text-weight-bold">unaffected from <span class="cve-version">1.0</span></span>
                  <span class="is-italic"> (version affected changed and it is unaffected from 1.0 to 2.0 — the next range right below)</span>
                </li>
                <li>
                  <span class="has-text-weight-bold">affected from <span class="cve-version">2.0</span></span>
                  <span class="is-italic"> (version remain affected from 2.0 to before 4.0, not including 4.0)</span>
                </li>
              </ul>
            </li>
            <li>
              For another linear version example view the
              <a href="https://github.com/CVEProject/cve-schema/blob/master/schema/v5.0/docs/versions.md#version-status-changes" target="_blank">
                Version Status Changes
              </a> section
            </li>
            <li>
              For a non-linear version example view the
              <a href="https://github.com/CVEProject/cve-schema/blob/master/schema/v5.0/docs/versions.md#version-status-changes" target="_blank">
                Source Control Versions
              </a> section
            </li>
          </ul>
        </div>
      </div>
      <div v-if="cveFieldList.showProductStatus"><p class="is-italic cve-help-text">Information not provided</p></div>
      <div v-else>
        <div class="columns mb-4" v-for="(product, index) in cveFieldList.productsStatus" :key="`${index}-${product.vendor}`">
          <div  id="cve-vendor-product-platforms" class="column cve-row cve-light-gray-bg"
            :class="(Object.keys(product.versionsColumns.twoDTable).length > 0) ? 'is-3' : ''">
            <p class="has-text-weight-bold mb-1">Vendor</p>
            <span id="cve-vendor" v-if="product.vendor.length > 0">{{product.vendor}}</span>
            <p class="has-text-weight-bold mb-1 pt-3">Product</p>
            <span id="cve-product" v-if="product.product.length > 0">{{product.product}}</span>
            <div id="cve-platforms" class="pt-3" v-if="product.platforms.length > 0">
              <p class="has-text-weight-bold mb-1">Platforms</p>
              <p>{{product.platforms.join(', ')}}</p>
            </div>
          </div>
          <div class="column cve-left-margin cve-top-margin cve-light-gray-bg">
            <p class="has-text-weight-bold mb-1">Versions</p>
            <p v-if="product.defaultStatus" class="cve-help-text is-italic">
              <span class="has-text-weight-bold">Default Status: </span>{{product.defaultStatus}}
            </p>
            <p v-else class="cve-help-text is-italic"><span class="has-text-weight-bold">Default Status: </span>unknown</p>
            <div class="columns mr-1 mb-1 cve-versions-columns">
              <!-- Loop through the column headers so the affected/unaffected/unknown columns will display in alphabetical order -->
              <div v-for="header in product.versionsColumns.headers" :key="header.key"
                class="column pt-0 pr-0 pl-1 cve-left-margin cve-top-margin cve-versions-column">
                <div class="menu p-0" style="border: unset !important">
                  <ul class="menu-list mt-1" v-for="row in product.versionsColumns.twoDTable[header]" :key="row.key">
                    <li>
                      <span v-if="row.versionsChanges.length > 0">
                        <p>
                          {{row.parentVersionStatus}}
                          <span v-for="word in row.parentVersionRange" :key="word.key" :class="isAversion(word) ? 'cve-version' : ''">{{word}} </span>
                        </p>
                        <ul style="border-left: none !important;">
                          <li v-for="change in row.versionsChanges" :key="change.key">
                            <span v-for="word in change" :key="word.key" :class="isAversion(word) ? 'cve-version' : ''">{{word}} </span>
                          </li>
                        </ul>
                      </span>
                      <span v-else>
                        <span v-if="row.parentVersionRange.length > 0">{{row.parentVersionStatus}}
                          <span v-for="word in row.parentVersionRange" :key="word.key" :class="isAversion(word) ? 'cve-version' : ''">{{word}} </span>
                        </span>
                        <span v-else>
                          {{row.parentVersionStatus}} {{row.parentVersionRange}}
                        </span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="cve-credits" class="content" v-if="cveFieldList.credits.length > 0">
      <h2 class="title is-size-5">Credits</h2>
      <ul>
        <li v-for="(credit, index) in cveFieldList.credits" :key="`${credit}-${index}`">
          {{credit.value}}
          <span class="tag ml-2" v-if="typeof credit.type !== 'undefined' && credit.type.length > 0">{{credit.type}}</span>
        </li>
      </ul>
    </div>
    <div id="cve-references" class="content" v-if="cveFieldList.references.length > 0">
      <h2 class="title is-size-5">References</h2>
      <ul>
        <li v-for="(reference, index) in cveFieldList.references" :key="`link-${index}`" class="cve-word-wrap">
          <span class="icon-text">
            <a :href="reference.url" target="_blank">
              {{ (typeof reference.name !== 'undefined' && reference.name.length > 0) ? `${reference.hostname}: ${reference.name}` : reference.url }}
              <span class="icon cve-icon-xxs">
                <p id="enewsletter" class="is-hidden">external site</p>
                <font-awesome-icon icon="external-link-alt" aria-labelledby="enewsletter"></font-awesome-icon>
              </span>
            </a>
          </span>
          <span v-for="tag in reference.tags" :key="tag">
            <span class="tag ml-2" v-if="tag.length > 0">{{tag}}</span>
          </span>
        </li>
      </ul>
    </div>
    <div id="cve-nvd-link">
      <span>View additional information about
        <a :href="`${NVDBaseUrl}${this.$store.state.cveId}`" target='_blank'>
          {{ this.$store.state.recordData.cveMetadata.cveId }}
          <span class='icon cve-icon-xxs'>
            <p id='nvd' class='is-hidden'>external site</p>
            <font-awesome-icon icon='external-link-alt' aria-labelledby='nvd'></font-awesome-icon>
          </span>
        </a>
        on NVD.
      </span>
      <p class='cve-help-text'>(Note: The NVD is not operated by the CVE Program)</p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash';
import axios from 'axios';

export default ({
  name: 'PublishedRecord',
  props: {
    NVDBaseUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isMessageExpanded: false,
      isHelpTestShown: false,
      cveRecordFields: ['ID', 'CNA', 'Credits', 'Description', 'References', 'State', 'Tags', 'Title', 'VendorsProductsVersions',
        'RecordPublishedDate', 'RecordUpdatedDate'],
      cveFieldList: {
        cveId: '',
        credits: [],
        descriptions: [],
        productsStatus: [],
        title: '',
        state: '',
        assigner: '',
        dateUpdatedCveMetadata: '',
        datePublishedCveMetadata: '',
        references: [],
        tags: [],
      },
      originalRecordData: this.$store.state.recordData || {},
    };
  },
  methods: {
    initializeFields() {
      this.cveRecordFields.forEach((field) => {
        this.getContentForField(field);
      });
    },
    getCNA() {
      const assignerShortName = this.originalRecordData?.cveMetadata?.assignerShortName;
      const partnerUUID = this.originalRecordData?.cveMetadata?.assignerOrgId;

      if (this.hasData(assignerShortName)) {
        const url = `${this.$store.state.API_BASE}cve-partner-name-map.json`;
        axios
          .get(url, { timeout: 30000 })
          .then((response) => {
            const shortLongNameMap = response.data;
            if (shortLongNameMap?.[partnerUUID]) {
              this.cveFieldList.assigner = shortLongNameMap?.[partnerUUID];
            }
          })
          .finally(() => {
            if (this.cveFieldList.assigner.length === 0) {
              this.cveFieldList.assigner = assignerShortName.replace('_', ' ');
            }
          });
      }
    },
    getCredits() {
      // schema: https://github.com/CVEProject/cve-schema/blob/master/schema/v5.0/CVE_JSON_5.0_schema.json#L971-L1015
      const value = this.originalRecordData.containers?.cna?.credits;
      if (this.hasData(value)) {
        value.forEach((credit) => {
          if (this.isEnglishLanguage(credit.lang)) {
            this.cveFieldList.credits.push(credit);
          }
        });
      }
    },
    getRecordPublishedDate() {
      const value = this.originalRecordData.cveMetadata?.datePublished;
      if (this.hasData(value)) {
        this.cveFieldList.datePublishedCveMetadata = this.getDate(value);
      }
    },
    getRecordUpdatedDate() {
      const value = this.originalRecordData.cveMetadata?.dateUpdated;
      if (this.hasData(value)) {
        this.cveFieldList.dateUpdatedCveMetadata = this.getDate(value);
      }
    },
    getCVEid() {
      const value = this.originalRecordData.cveMetadata?.cveId;
      if (this.hasData(value)) {
        this.cveFieldList.cveId = value;
      } else {
        this.cveFieldList.cveId = this.$store.state.recordData.cveMetadata.cveId; // user-provided CVE ID
      }
    },
    getDescription() {
      const value = this.originalRecordData.containers?.cna?.descriptions;
      if (this.hasData(value)) {
        value.forEach((desc) => {
          if (this.isEnglishLanguage(desc.lang)) {
            this.cveFieldList.descriptions.push(desc.value);
          }
        });
      }
    },
    getReferences() {
      const value = this.originalRecordData.containers?.cna?.references;
      if (this.hasData(value)) {
        const filteredReferences = [];
        const regex = /^x_refsource/;

        value.forEach((reference) => {
          let newReference = {};
          const filteredTags = [];

          if (reference?.tags) {
            reference.tags.forEach((tag) => {
              if (!regex.test(tag)) filteredTags.push(tag);
            });
          }
          newReference = _.cloneDeep(reference);
          newReference.tags = filteredTags;
          if (newReference?.name && newReference.name.length > 0) newReference.hostname = (new URL(newReference.url)).hostname.replace('www.', '');
          filteredReferences.push(newReference);
        });

        Vue.set(this.cveFieldList, 'references', filteredReferences);
      }
    },
    getState() {
      const value = this.originalRecordData.cveMetadata?.state;
      if (this.hasData(value)) {
        this.cveFieldList.state = value;
      }
    },
    getTags() {
      const value = this.originalRecordData.containers?.cna?.tags;
      if (this.hasData(value)) {
        Vue.set(this.cveFieldList, 'tags', value);
      }
    },
    getTitle() {
      const value = this.originalRecordData.containers?.cna?.title;
      if (this.hasData(value)) {
        this.cveFieldList.title = value;
      }
    },
    // getProductsStatus() is adapted from https://github.com/Vulnogram/seaview/blob/main/script.js#L140-L253
    getProductsStatus() {
      const affectedList = this.originalRecordData.containers?.cna?.affected;
      if (this.hasData(affectedList)) {
        const prodStatusTemplate = {
          vendor: '',
          product: '',
          platforms: [],
          defaultStatus: '',
          versionsColumns: [],
        };

        /**
          Building the Product Status table each product in containers.cna.affected[]:
          - column 1: vendor, product, platforms
          - column 2: affected/unaffected/unknown list(s)/subcomlumn(s)
        */
        affectedList.forEach((affectedObj) => {
          const prodStatus = _.cloneDeep(prodStatusTemplate);
          // Building column 1
          if (this.hasData(affectedObj?.vendor)) prodStatus.vendor = affectedObj.vendor;
          if (this.hasData(affectedObj?.product)) prodStatus.product = affectedObj.product;
          if (this.hasData(affectedObj?.platforms)) prodStatus.platforms = _.cloneDeep(affectedObj.platforms);

          // Building column 2: an affected/unaffected/unknown subcolumn(s) for each product version in containers.cna.affected[i].versions[].
          const versionsColumns = [];
          // https://github.com/Vulnogram/seaview/blob/main/script.js#L175-L216
          if (this.hasData(affectedObj?.versions)) {
            const tableRowTemplate = {
              versionRange: {
                parentVersion: [],
                parentVersionRange: [],
                parentVersionStatus: '',
                versionsChanges: [],
              },
            };

            affectedObj.versions.forEach((versionObj) => {
              const tableRow = _.cloneDeep(tableRowTemplate);
              const versionsAndChangesTemplate = {
                versions: [],
                changes: [],
                changeColorList: [],
              };
              const productVersion = `${versionObj?.version || '(no version provided)'}`;
              const versionStatus = versionObj?.status || 'noVersionStatus'; // affected, unaffected, OR unknown

              if (this.hasData(versionObj?.changes)) {
                let range = [];

                if (productVersion !== 'unspecified' && productVersion !== 0) {
                  range.push('from');
                  range.push(productVersion);
                }

                if (versionObj?.lessThan) {
                  let rangeEnd = ['before', `${versionObj?.lessThan || '(no lessThan version provided)'}`];
                  if (this.isUnspecifiedOrWildCard(versionObj, 'lessThan')) rangeEnd = [];
                  if ((rangeEnd.length > 0) && (versionObj.lessThan !== productVersion)) {
                    range = range.concat(rangeEnd);
                  }
                } else if (versionObj?.lessThanOrEqual) {
                  let rangeEnd = ['through', `${versionObj?.lessThanOrEqual || '(no lessThan version provided)'}`];
                  if (this.isUnspecifiedOrWildCard(versionObj, 'lessThanOrEqual')) rangeEnd = '';
                  if ((rangeEnd.length > 0) && (versionObj.lessThanOrEqual !== productVersion)) {
                    range = range.concat(rangeEnd);
                  }
                } else {
                  range = [productVersion];
                }

                // save the version changes
                const versionsAndChangesCopy = _.cloneDeep(versionsAndChangesTemplate);

                // Build a temp 'changes' object by going through container.cna.affected[x].versions[x].changes[]
                //   and categorize by 'status' (affected, unaffected, or unknown)
                //   which can be different from the main 'status' (container.cna.affected[x].versions[x].status).
                versionObj.changes.forEach((change) => {
                  versionsAndChangesCopy.changeColorList.push([change.status, 'from', change.at]);
                });

                tableRow.versionRange.parentVersionStatus = versionStatus;
                tableRow.versionRange.parentVersion = ['from', productVersion];
                tableRow.versionRange.parentVersionRange = range;
                tableRow.versionRange.versionsChanges = versionsAndChangesCopy.changeColorList;
              } else {
                let rangeStart = [];
                if (productVersion !== 'unspecified' && productVersion !== '*') rangeStart = ['from', productVersion];

                tableRow.versionRange.parentVersion = [productVersion];
                if (this.hasData(versionObj?.lessThan)) {
                  let range = [];
                  let rangeEnd = ['before', `${versionObj?.lessThan || '(no version.lessThan provided)'}`];
                  if (this.isUnspecifiedOrWildCard(versionObj, 'lessThan')) rangeEnd = [];
                  if (rangeEnd.length > 0) {
                    range = range.concat(rangeStart, rangeEnd);
                  } else {
                    range = rangeStart;
                  }
                  tableRow.versionRange.parentVersionStatus = versionStatus;
                  tableRow.versionRange.parentVersionRange = range;
                } else if (this.hasData(versionObj?.lessThanOrEqual)) {
                  let range = [];
                  let rangeEnd = ['through', `${versionObj?.lessThanOrEqual || '(no version.lessThan provided)'}`];
                  if (this.isUnspecifiedOrWildCard(versionObj, 'lessThanOrEqual')) rangeEnd = [];
                  range = range.concat(rangeStart, rangeEnd);
                  tableRow.versionRange.parentVersionStatus = versionStatus;
                  tableRow.versionRange.parentVersionRange = range;
                } else {
                  tableRow.versionRange.parentVersionStatus = versionStatus;
                  tableRow.versionRange.parentVersion = ['at', productVersion];
                  tableRow.versionRange.parentVersionRange = ['at', productVersion];
                }
              }
              versionsColumns.push(tableRow.versionRange);
            });

            if (this.hasData(affectedObj?.defaultStatus)) {
              prodStatus.defaultStatus = `${affectedObj.defaultStatus}`;
            }
          } else {
            prodStatus.defaultStatus = this.hasData(affectedObj?.defaultStatus) ? `All versions are ${affectedObj.defaultStatus}` : '';
          }
          prodStatus.versionsColumns = this.create2DTable(versionsColumns);
          this.cveFieldList.productsStatus.push(_.cloneDeep(prodStatus));
          this.cveFieldList.showProductStatus = !this.isVendorProductVersionDefaultStatusNa(
            prodStatus.vendor, prodStatus.product, versionsColumns, prodStatus.defaultStatus);
        });
      }
    },
    getContentForField(field) {
      switch (field) {
        case 'CNA':
          this.getCNA();
          break;
        case 'Credits':
          this.getCredits();
          break;
        case 'Description':
          this.getDescription();
          break;
        case 'ID':
          this.getCVEid();
          break;
        case 'RecordPublishedDate':
          this.getRecordPublishedDate();
          break;
        case 'RecordUpdatedDate':
          this.getRecordUpdatedDate();
          break;
        case 'References':
          this.getReferences();
          break;
        case 'State':
          this.getState();
          break;
        case 'Tags':
          this.getTags();
          break;
        case 'Title':
          this.getTitle();
          break;
        case 'VendorsProductsVersions':
          this.getProductsStatus();
          break;
        default:
          break;
      }
    },
    hasData(value) {
      if (typeof value !== 'undefined' && value.length > 0) {
        return true;
      }

      return false;
    },
    isEnglishLanguage(lang) {
      const regex = /^(en)/;
      const isEnglishLanguage = regex.test(lang);

      return isEnglishLanguage;
    },
    isUnspecifiedOrWildCard(field, key) {
      return (field[key] === 'unspecified' || field[key] === '*');
    },
    getDate(dateTime) {
      const [date] = dateTime.split('T');
      return date;
    },
    toggleRecord() {
      this.$store.commit('updateState', { showJsonRecord: !this.$store.state.showJsonRecord });
    },
    create2DTable(affectedUnaffectedUnknownTable) {
      const tableWithHeaders = {
        headers: [],
        twoDTable: {},
      };

      // Build table headers: affected, and/or unaffected, and/or unknown
      affectedUnaffectedUnknownTable.forEach((row) => {
        if (tableWithHeaders.headers.indexOf(row.parentVersionStatus) < 0) tableWithHeaders.headers.push(row.parentVersionStatus);
      });
      tableWithHeaders.headers.sort();

      // Build 2D table w/ 'version'&'changes' or '' for table cell w/ no value
      affectedUnaffectedUnknownTable.forEach((row) => {
        // for each row fill in 'version'&'changes' or ''
        if (Object.prototype.hasOwnProperty.call(tableWithHeaders.twoDTable, row.parentVersionStatus)) {
          tableWithHeaders.twoDTable[row.parentVersionStatus].push(row);
        } else {
          tableWithHeaders.twoDTable[row.parentVersionStatus] = [row];
        }
      });
      return tableWithHeaders;
    },
    isAversion(version) {
      const notAversion = ['affected', 'unaffected', 'unknown', 'from', 'before', 'through', 'at'];

      if (notAversion.indexOf(version) > -1) return false;
      return true;
    },
    isVendorProductVersionDefaultStatusNa(vendor, product, affectedUnaffectedUnknownTable, defaultStatus) {
      const isVendorNa = /n\/a/.test(vendor.toLowerCase());
      const isProductNa = /n\/a/.test(product.toLowerCase());
      const isDefaultStausNa = defaultStatus.length === 0;
      let isVersionNa = false;

      if (affectedUnaffectedUnknownTable.length === 1) {
        isVersionNa = /n\/a/.test(affectedUnaffectedUnknownTable[0].parentVersionRange.toString().toLowerCase());
      }

      if (isVendorNa && isProductNa && isVersionNa && isDefaultStausNa) return false;
      return true;
    },
  },
  beforeMount() {
    this.initializeFields();
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/style/globals.scss';
@import '@/assets/style/cveRecord.scss';
@import '../../assets/style/elements/cveTableStacked.scss';

.cve-light-gray-bg {
  background-color: #FFF;
  border-radius: $cve-border-radius;
  border: $white-ter solid 1px;
}

.columns {
  margin-top: 0rem !important;
}

.columns:not(:last-child) {
    margin-bottom: 0.5rem !important;
}

.cve-row {
  margin-left: unset !important;
  margin-right: unset !important;
}

.cve-versions-columns {
  padding-left: 4px !important;
}
.cve-versions-column:not(:last-child) {
  @include from($desktop) {
    border-right: $theme-color-base-light solid 2px;
  }

  @include until($tablet) {
    border-bottom: $theme-color-base-light solid 2px;
  }
}

td {
  width: 33% !important;
}

.cve-light-gray-table-header {
  text-transform: capitalize;
  background-color: $theme-color-base !important;
  border: 1px solid #F0F0F0 !important;
}

.cve-border-light-gray {
  border: 1px solid #F0F0F0;
}

.cve-version {
  color: $theme-color-violet-vivid-60;
  font-weight: 700;
}

.menu-list {
  margin-left: 1.5rem !important;
  margin-right: 0.25rem !important;
}

.cve-tag {
  background-color: $theme-color-violet-vivid-60 !important;
  color: white !important;
  font-weight: 600;
}

.cve-state-tag {
  background-color: $theme-color-blue-warm-20 !important;
  color: $theme-color-base-darkest !important;
}
</style>
